import axios from "axios";
import { useCallback, useEffect, useState } from "react"
import Select from 'react-select';
export default function LineaEstadoAsignadoIzq(expediente) {
    const [actualizar, setActualizar] = useState({ estado: null, asignadoA: null })
    const [asignados, setAsignados] = useState([])
    const [obsGral, setObsGral] = useState(expediente.expediente.notas)
    useEffect(() => {
        axios.get("https://api.tuveunchoque.com.ar/api/gerencia/asignados").then((res) => {
            setAsignados(res.data);
        });
    }, []);
    const estadoInput = [
        {label: 'VACIAR', value: null},
        { label: "SOLICITUD DE FECHA", value: 1 },
        { label: "FECHA ASIGNADA", value: 2 },
        { label: "NEGOCIACION", value: 3 },
        { label: "REVISIONES LEGALES", value: 4 },
        { label: "ARMADO DERIVACION", value: 5 },
        { label: "ESPERA CONFIRMACION", value: 6 },
        { label: "ACEPTACION MEDIACION", value: 7 },
        { label: "LEGALES", value: 8 },
    ];
    const mediacionInput = asignados.filter((a) => a.mediacion === 1).map((a) => a.nombre)
    const actualizarEstadoAsignadoEnMediacion = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/gerencia/actualizarEstadoAsignadoEnMediacion`,
            {
                estado: actualizar.estado !== null ? actualizar.estado : expediente.expediente.etapaMediacion,
                asignado: actualizar.asignadoA !== null ? actualizar.asignadoA : expediente.expediente.asignadoA ,
                id: expediente.expediente.id,
                nuevaAsignacion: actualizar.asignadoA !== null ? expediente.expediente.asignadoA !== actualizar.asignadoA ? 1 : 0 : expediente.expediente.nuevaAsignacion,
                obsGral: obsGral
            }
        ).then((response) => {
            if (response) {
                setTimeout(async () => {
                    window.location.reload()
                }, 100);
            }
        })
    }, [actualizar, expediente, obsGral]);
    return (
        <>
            {expediente ? <section className="d-flex ">
                <div className="col-4">
                    <div className="center w-100">
                        <Select className="w-100 me-2"
                            options={estadoInput.map(estado => ({ value: estado.value, label: estado.label }))}
                            placeholder={"Estado"}
                            onChange={(newValue) => setActualizar((prevState) => ({
                                ...prevState,
                                estado: newValue.value,
                            }))}
                        />
                        <Select className="w-100 ms-2 me-2"
                            placeholder={"Asignado"}
                            options={mediacionInput.sort((a, b) => a.localeCompare(b)).map(asignado => ({ value: asignado, label: asignado }))}
                            onChange={(newValue) => setActualizar((prevState) => ({
                                ...prevState,
                                asignadoA: newValue.value,
                            }))}
                        />
                    </div>
                </div>
                <div className="col-8 d-flex">
                    <textarea value={obsGral} className="w-75 form-control form-control-sm" style={{height: '38px'}}  onChange={(e) => setObsGral(e.target.value)}></textarea>
                    <button className="btn btn-primary btn-sm ms-2" onClick={(e) => actualizarEstadoAsignadoEnMediacion(e)}>Actualizar</button>
                </div>
            </section> : <></>}
        </>
    )
}