import { useState } from "react";
import "../index.css";
import { useCookies } from "react-cookie";
import SeccionCliente from "../SeccionCliente/SeccionCliente";
import SeccionCobro from "../SeccionCobro/SeccionCobro";
import SeccionExpediente from "../SeccionExpediente/SeccionExpediente";
import SeccionRequerido from "../SeccionRequerido/SeccionRequerido";
import "./informacionExpediente.css";
import "react-datepicker/dist/react-datepicker.css";
import { useMediaQuery } from "react-responsive";
export default function InformacionExpediente({ expediente, otrosExp, otrosExpReq }) {

  // VALORES GLOBALES
  const [active, setActive] = useState(1);
  const SetView = (active) => {
    setActive(active);
  };
  const [cookies] = useCookies(["user"]);
  // FUNCION CONDICIONAL PESTANIAS
  const ActiveView = () => {
    switch (active) {
      case 1:
        return <SeccionExpediente expediente={expediente} otrosExp={otrosExp} otrosExpReq={otrosExpReq} />;
      case 2:
        return <SeccionCliente expediente={expediente} />;
      case 3:
        return <SeccionCobro expediente={expediente} />;
      case 4:
        return <SeccionRequerido expediente={expediente} />;
      default:
        return <SeccionExpediente expediente={expediente} otrosExp={otrosExp} otrosExpReq={otrosExpReq} />;
    }
  };

  const isMobile375 = useMediaQuery({ query: "(max-width: 375px)" });
  const isMobile412 = useMediaQuery({
    query: "(min-width: 376px) and (max-width: 412px)",
  });
  const isMobile480 = useMediaQuery({ query: "(min-width: 413px) and (max-width: 480px)", });
  const isTablet768 = useMediaQuery({ query: '(min-width: 480px) and (max-width: 768px)' });

  const getContainerClass = (index) => {
    if (active === index) {
      return isMobile375
        ? "botonActivoPlanillaExp375"
        : isMobile412
          ? "botonActivoPlanillaExp412"
          : isMobile480
            ? "botonActivoPlanillaExp480"
            : isTablet768 ? "botonActivoPlanillaExp768"
              : "botonActivoPlanillaExp";
    } else {
      return isMobile375
        ? "botonInactivoPlanillaExp375"
        : isMobile412
          ? "botonInactivoPlanillaExp412"
          : isMobile480
            ? "botonInactivoPlanillaExp480"
            : isTablet768 ? "botonInactivoPlanillaExp768"
              : "botonInactivoPlanillaExp";
    }
  };
  const getButtonClass = (index) => {
    if (active === index) {
      return isMobile375
        ? "btnCambioPlanilla375"
        : isMobile412
          ? "btnCambioPlanilla412"
          : isMobile480
            ? "btnCambioPlanilla480"
            : isTablet768 ? "btnCambioPlanilla768"
              : "btnCambioPlanilla";
    } else {
      return isMobile375
        ? "btnCambioPlanillaInactivo375"
        : isMobile412
          ? "btnCambioPlanillaInactivo412"
          : isMobile480
            ? "btnCambioPlanillaInactivo480"
            : isTablet768 ? "btnCambioPlanillaInactivo768"
              : "btnCambioPlanillaInactivo";
    }
  };
  return (
    <div className={isMobile375 ? "infoExpContainerAnclado375" : isMobile412 ? "infoExpContainerAnclado412" : isMobile480 ? "infoExpContainerAnclado480" : isTablet768 ? "infoExpContainerAnclado768" : "infoExpContainerAnclado"}>
      {(cookies.user.cargo !== "PAS" &&
        cookies.user.cargo !== 'GRUPO' &&
        cookies.user.cargo !== 'ORGANIZACION') ? (
        <div className="d-flex m-0 p-0">
          {[1, 2, 3, 4].map((index) => (
            <div key={index} className={getContainerClass(index)}>
              <button
                className={getButtonClass(index)}
                onClick={() => SetView(index)}
              >
                {index === 1
                  ? "EXPEDIENTE"
                  : index === 2
                    ? "CLIENTE"
                    : index === 3
                      ? "COBRO"
                      : "REQUERIDO"}
              </button>
            </div>
          ))}
        </div>
      ) : (
        <div className="d-flex m-0 p-0">
          {[1, 2, 4].map((index) => (
            <div
              key={index}
              className={getContainerClass(index)}
            >
              <button
                className={getButtonClass(index)}
                onClick={() => SetView(index)}
              >
                {index === 1
                  ? "EXPEDIENTE"
                  : index === 2
                    ? "CLIENTE"
                    : "REQUERIDO"}
              </button>
            </div>
          ))}
        </div>
      )}
      {ActiveView()}
    </div>
  );
}
