import { useCallback, useState } from 'react';
import '../index.css'
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { useMediaQuery } from 'react-responsive'
export default function RegistroSiniestralidadCrearGerencia({ otrosExp, otrosExpReq, otrosExpReqCliente ,otrosExpReqReq, fechaStro, dominio, domReq }) {
    const [cookies, setCookie, removeCookie] = useCookies(['user']);

    const isMobile375 = useMediaQuery({ query: '(max-width: 375px)' });
    const isMobile412 = useMediaQuery({ query: '(max-width: 412px)' });
    const isMobile480 = useMediaQuery({ query: '(max-width: 480px)' });
    
    

    return (
        <>
            {isMobile375 ?
                <div className='sectionAmpliaciones375'>
                    <div className="divTituloAmpliaciones375 d-flex justify-content-between align-items-center pe-3 ps-3">
                        <h3 className='registroSiniestralidadTitulo375 m-0'>REGISTRO DE SINIESTRALIDAD</h3>
                        <div className='d-flex '>
                            <h3 className='registroSiniestralidadTitulo375 m-0 pe-1'>- LEÍDO</h3>
                        </div>
                    </div>
                    <div style={{ maxHeight: '200px', overflowY: 'auto', }}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Nro</th>
                                    <th>Patente</th>
                                    <th>F.C Estado</th>
                                    <th>Cia</th>
                                    <th>Nombre</th>
                                    <th>Estado</th>
                                    <th>Patente Requerido</th>
                                </tr>
                            </thead>
                            <tbody>
                                {otrosExp?.map((e) => (
                                    <tr key={e.id}>
                                        <th scope="row">
                                            <a href={`https://sistema.tuveunchoque.com.ar/#/expediente/${e.nro}`} target="_blank" rel="noopener noreferrer">{e.nro}</a>
                                        </th>
                                        <td className="bg-primary bg-opacity-25">{e.dominioCliente}</td>
                                        <td>{e.fechaCambioEstado}</td>
                                        <td>{e.compania}</td>
                                        <td>{e.nombre}</td>
                                        <td>{e.estado}</td>
                                        <td>{e.patenteRequerido}</td>
                                    </tr>
                                ))}
                                {otrosExpReq?.map((e) => (
                                    <tr key={e.id}>
                                        <th scope="row">
                                            <a href={`https://sistema.tuveunchoque.com.ar/#/expediente/${e.nro}`} target="_blank" rel="noopener noreferrer">{e.nro}</a>
                                        </th>
                                        <td>{e.dominioCliente}</td>
                                        <td>{e.fechaCambioEstado}</td>
                                        <td>{e.compania}</td>
                                        <td>{e.nombre}</td>
                                        <td>{e.estado}</td>
                                        <td className="bg-primary bg-opacity-25">{e.patenteRequerido}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                :
                <div className='sectionAmpliaciones'>
                    <div className="divTituloAmpliaciones d-flex justify-content-between align-items-center pe-5 ps-5">
                        <h3 className='registroSiniestralidadTitulo m-0'>REGISTRO DE SINIESTRALIDAD</h3>
                    </div>
                    <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Nro</th>
                                    <th>Patente</th>
                                    <th>F. Stro</th>
                                    <th>Cia</th>
                                    <th>Nombre</th>
                                    <th>Estado</th>
                                    <th>Patente Requerido</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dominio && otrosExp?.map((e) => (
                                    <tr key={e.id}>
                                        <th scope="row">
                                            <a href={`https://sistema.tuveunchoque.com.ar/#/expediente/${e.nro}`} target="_blank" rel="noopener noreferrer">{e.nro}</a>
                                        </th>
                                        <td className="bg-primary bg-opacity-25">{e.dominioCliente}</td>
                                        <td className={fechaStro === e.fechaStro ? 'bg-danger text-white' : null}>{e.fechaStro}</td>
                                        <td>{e.compania}</td>
                                        <td>{e.nombre}</td>
                                        <td>{e.estado}</td>
                                        <td>{e.patenteRequerido}</td>
                                    </tr>
                                ))}
                                {dominio && otrosExpReq?.map((e) => (
                                    <tr key={e.id}>
                                        <th scope="row">
                                            <a href={`https://sistema.tuveunchoque.com.ar/#/expediente/${e.nro}`} target="_blank" rel="noopener noreferrer">{e.nro}</a>
                                        </th>
                                        <td>{e.dominioCliente}</td>
                                        <td>{e.fechaStro}</td>
                                        <td>{e.compania}</td>
                                        <td>{e.nombre}</td>
                                        <td>{e.estado}</td>
                                        <td className="bg-primary bg-opacity-25">{e.patenteRequerido}</td>
                                    </tr>
                                ))}
                                {domReq && otrosExpReqCliente?.map((e) => (
                                    <tr key={e.id}>
                                        <th scope="row">
                                            <a href={`https://sistema.tuveunchoque.com.ar/#/expediente/${e.nro}`} target="_blank" rel="noopener noreferrer">{e.nro}</a>
                                        </th>
                                        <td style={{backgroundColor: '#F99946'}}>{e.dominioCliente}</td>
                                        <td>{e.fechaStro}</td>
                                        <td>{e.compania}</td>
                                        <td>{e.nombre}</td>
                                        <td>{e.estado}</td>
                                        <td>{e.patenteRequerido}</td>
                                    </tr>
                                ))}
                                {domReq && otrosExpReqReq?.map((e) => (
                                    <tr key={e.id}>
                                        <th scope="row">
                                            <a href={`https://sistema.tuveunchoque.com.ar/#/expediente/${e.nro}`} target="_blank" rel="noopener noreferrer">{e.nro}</a>
                                        </th>
                                        <td >{e.dominioCliente}</td>
                                        <td>{e.fechaStro}</td>
                                        <td>{e.compania}</td>
                                        <td>{e.nombre}</td>
                                        <td>{e.estado}</td>
                                        <td style={{backgroundColor: '#F99946'}}>{e.patenteRequerido}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>}
        </>
    )
}