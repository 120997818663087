import {useContext } from "react";
import SeccionClienteEditar from "./SeccionClienteEditar";
import SeccionClienteLeer from "./SeccionClienteLeer";
import { editarContext } from "../../../context/Editar";
export default function SeccionCliente ({expediente,}) {
    const {editar} = useContext(editarContext)
    return (
        <div className="">
            {editar ?
                <SeccionClienteEditar expediente={expediente} />
                :
                <SeccionClienteLeer expediente={expediente}/>}
        </div>
    )
}