import axios from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import './index.css'
import ExpedienteNoMediacion from "./ExpedienteNoMediacion";
import ExpedienteMediacion from "./ExpedienteMediacion";
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
export default function ExpedienteContainer() {
    const navigate = useNavigate()
    const ab = localStorage.getItem('user');
    if (ab === null) {
        navigate('/login')
    }
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const id = useParams().id
    useEffect(() => { document.title = `Exp ${id}` }, [])
    const [expediente, setExpediente] = useState([])
    const [otrosExp, setOtrosExp] = useState([])
    const [otrosExpReq, setOtrosExpReq] = useState([])
    const [otrosExpReqCliente, setOtrosExpReqCliente] = useState([])
    const [otrosExpReqReq, setOtrosExpReqReq] = useState([])
    useEffect(() => {
        axios.get(`https://api.tuveunchoque.com.ar/api/gerencia/expedientes/${id}`).then(async (res) => {
            setExpediente(res.data);
            await axios.get(`https://api.tuveunchoque.com.ar/api/otrosExp/otrasPatentesCliente/${res.data.dominioCliente}`).then((res) => {
                setOtrosExp(res.data)
            })
            await axios.get(`https://api.tuveunchoque.com.ar/api/otrosExp/otrasPatentesReq/${res.data.dominioCliente}`).then((res) => {
                setOtrosExpReq(res.data)
            })
            await axios.get(`https://api.tuveunchoque.com.ar/api/otrosExp/otrasPatentesReqCliente/${res.data.patenteRequerido}`).then((res) => {
                setOtrosExpReqCliente(res.data)
            })
            await axios.get(`https://api.tuveunchoque.com.ar/api/otrosExp/otrasPatentesReqReq/${res.data.patenteRequerido}`).then((res) => {
                setOtrosExpReqReq(res.data)
            })
        });
    }, [id]);
    function tabla() {
        if (expediente.nro && cookies.user.cargo === 'AGENTE' && expediente.asignadoA !== cookies.user.nombre) {
            setTimeout(() => {
                navigate('/expedientes')
            }, 100);
        }
    }
    function verificarCondicionYRedirigir(cookies, expediente) {
        if (expediente.productorAsociado) {
            if (cookies.user.cargo === 'PAS') {
                if (cookies.user.nombre.toUpperCase() !== expediente.productorAsociado.toUpperCase()) {
                    navigate('/expedientes'); 
                }
            } else if (cookies.user.cargo === 'ORGANIZACION') {
                const productoresArray = cookies.user.productores ? cookies.user.productores.split(',').map(prod => prod.trim().toUpperCase()) : [];
                if (!productoresArray.includes(expediente.productorAsociado.toUpperCase())) {
                    navigate('/expedientes'); 
                }
            }
        }
    }
    useEffect(() => {
        verificarCondicionYRedirigir(cookies, expediente);
    }, [cookies, expediente]);
    return (
        <>
            {cookies.user.cargo === 'AGENTE' && expediente.asignadoA !== cookies.user.nombre ?
                <div className="center">
                    <h2>El Expediente {expediente.nro} no ha sido asignado al usuario </h2>
                    <h4>Sera redirigido a la tabla principal</h4>
                    {tabla()}
                </div>
                :
                <div>
                    {expediente.estado ?
                        <div>
                            {expediente.iconoMediacion === 0 ? <ExpedienteNoMediacion expediente={expediente} otrosExp={otrosExp} otrosExpReq={otrosExpReq} otrosExpReqCliente={otrosExpReqCliente} otrosExpReqReq={otrosExpReqReq}/> : <ExpedienteMediacion expediente={expediente} otrosExp={otrosExp} otrosExpReq={otrosExpReq} otrosExpReqCliente={otrosExpReqCliente} otrosExpReqReq={otrosExpReqReq}/>}
                        </div> : <div></div>}
                </div>}
        </>
    )
}

