import axios from "axios";
import { useCallback, useState, useEffect } from "react";
import "./index.css";
import Comp from "../components/Ajustes/Comp";
import Pas from "../components/Ajustes/Pas";
import Niveles from "../components/Ajustes/Niveles";
import User from "../components/Ajustes/User";
import Asignados from "../components/Ajustes/Asignados";
import { useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import ExcelAjustes from "./ExcelAjustes";
import UserPas from "../components/Ajustes/UserPas";
import { MultiSelect } from "react-multi-select-component";
export default function Ajustes() {
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const navigate = useNavigate()
    const a = localStorage.getItem('user');
    if (a === null) {
        navigate('/login')
    }
    const [expDescarga, setExpDescarga] = useState([])
    useEffect(() => {
        axios.get("https://api.tuveunchoque.com.ar/api/gerencia/expDescargaAjustes").then((res) => {
            setExpDescarga(res.data);
        });
    }, []);
    const [productores, setProductores] = useState([]);
    useEffect(() => {
        axios.get("https://api.tuveunchoque.com.ar/api/nuevosUsuarios/pasParaAjustes").then((res) => {
            setProductores(res.data);
        });
    }, []);
    const [companias, setCompanias] = useState([]);
    useEffect(() => {
        axios.get("https://api.tuveunchoque.com.ar/api/gerencia/companias").then((res) => {
            setCompanias(res.data);
        });
    }, []);
    const [niveles, setNiveles] = useState([]);
    useEffect(() => {
        axios.get("https://api.tuveunchoque.com.ar/api/ajustes/niveles").then((res) => {
            setNiveles(res.data);
        });
    }, []);
    const [users, setUsers] = useState([])
    useEffect(() => {
        axios.get("https://api.tuveunchoque.com.ar/api/user").then((res) => {
            setUsers(res.data);
        });
    }, []);
    const [asignados, setAsignados] = useState([])
    useEffect(() => {
        axios.get("https://api.tuveunchoque.com.ar/api/gerencia/asignados").then((res) => {
            setAsignados(res.data);
        });
    }, []);
    const [pasViejo, setPasViejo] = useState([])
    useEffect(() => {
        axios.get("https://api.tuveunchoque.com.ar/api/gerencia/pas").then((res) => {
            setPasViejo(res.data);
        });
    }, []);
    const [mas, setMas] = useState({ comp: false, pas: false })
    const [masa, setMasa] = useState({ ger: false, emp: false, pas: false })
    const [comp, setComp] = useState({ comp: '', res: null, cob: null })
    const [pas, setPas] = useState({ nombre: '', email: '' })
    const [ger, setGer] = useState({ nombre: '', password: '' })
    const [emp, setEmp] = useState({ nombre: '', password: '' })
    const [pa, setPa] = useState({ nombre: '', password: '' })
    const compNew = useCallback(async (e) => {
        setMas({ comp: true, pas: false })
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/ajustes/compania/new`,
            {
                comp: comp.comp,
                res: comp.res,
                cob: comp.cob
            }
        ).then((response) => {
            if (response) {
                setTimeout(() => {
                    window.location.reload()
                }, 10);
            }
        })
    }, [comp]);
    const pasNew = useCallback(async (e) => {
        setMas({ comp: false, pas: true })
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/ajustes/pas/new`,
            {
                nombre: pas.nombre,
                email: pas.email
            }
        ).then((response) => {
            if (response) {
                setTimeout(() => {
                    window.location.reload()
                }, 10);
            }
        })
    }, [pas]);
    const gerNew = useCallback(async (e) => {
        setMasa({ ger: true, emp: false, pas: false })
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/ajustes/ger/new`,
            {
                nombre: ger.nombre,
                password: ger.password
            }
        ).then((response) => {
            if (response) {
                setTimeout(() => {
                    window.location.reload()
                }, 10);
            }
        })
    }, [ger]);
    const empNew = useCallback(async (e) => {
        setMasa({ ger: false, emp: true, pas: false })
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/ajustes/emp/new`,
            {
                nombre: emp.nombre,
                password: emp.password
            }
        ).then((response) => {
            if (response) {
                setTimeout(() => {
                    window.location.reload()
                }, 10);
            }
        })
    }, [emp]);
    const paNew = useCallback(async (e) => {
        setMasa({ ger: false, emp: false, pas: true })
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/ajustes/pa/new`,
            {
                nombre: pa.nombre,
                password: pa.password
            }
        ).then((response) => {
            if (response) {
                setTimeout(() => {
                    window.location.reload()
                }, 10);
            }
        })
    }, [pa]);
    const [mensajeDifusion, setMensajeDifusion] = useState({ cuerpo: '', asunto: '' })
    const enviarDifusion = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mail/mensajeDifusion`,
            {
                cuerpo: mensajeDifusion.cuerpo,
                asunto: mensajeDifusion.asunto
            }
        ).then((response) => {
            if (response) {
                setTimeout(async () => {
                    window.location.reload()
                }, 100);
            }
        })
    }, [mensajeDifusion]);
    const [usuarioPasNuevo, setUsuarioPasNuevo] = useState({ nombre: '', nombre4: '', email: '', password: '', cargo: '', crear: '', news: '', hnrios: '' })
    const [nuevoUserPas, setNuevoUserPas] = useState(false)
    const userPasNew = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/nuevosUsuarios/nuevoPas`,
            {
                nombre: usuarioPasNuevo.nombre,
                nombreUsuario: usuarioPasNuevo.nombre4,
                email: usuarioPasNuevo.email,
                password: usuarioPasNuevo.password,
                cargo: usuarioPasNuevo.cargo,
                crear: usuarioPasNuevo.crear,
                news: usuarioPasNuevo.news,
                hnrios: usuarioPasNuevo.hnrios
            }
        ).then((response) => {
            if (response) {
                setTimeout(() => {
                    window.location.reload()
                }, 100);
            }
        })
    }, [usuarioPasNuevo]);



    let updatedPas = productores
    const proInput = productores.map((p) => {
        return { value: p.nombre, label: p.nombre }
    })
    const asocInput = productores.map((p) => {
        return { value: p.id, label: p.nombre }
    })
    const cargo = [{ value: 'PAS', label: 'PAS' }, { value: 'ORGANIZACION', label: 'ORGANIZACION' }, { value: 'GRUPO', label: 'GRUPO' }]
    const hnrios = [{ value: 0, label: 0 }, { value: 5, label: 5 }, { value: 10, label: 10 }, { value: 15, label: 15 }, { value: 20, label: 20 },]
    const [info, setInfo] = useState({
        nombre: [],
        cargo: [],
        hnrios: [],
        hnriosAgr: [],
        asoc: []
    });
    const conFiltro = () => {
        if (info.nombre.length > 0) {
            updatedPas = updatedPas.filter((pas) => {
                return info.nombre.some((ele) => {
                    return ele.value === pas.nombre
                })
            })
        }
        if (info.cargo.length > 0) {
            updatedPas = updatedPas.filter((pas) => {
                return info.cargo.some((ele) => {
                    return ele.value === pas.cargo
                })
            })
        }
        if (info.asoc.length > 0) {
            updatedPas = updatedPas.filter((pas) => {
                const asocIds = pas.asoc.split(',').map(id => id.trim()).filter(id => id);
                return info.asoc.some((ele) => {
                    return asocIds.includes(String(ele.value));
                });
            })
        }
        if (info.hnrios.length > 0) {
            updatedPas = updatedPas.filter((pas) => {
                return info.hnrios.some((ele) => {
                    return ele.value === pas.honorariosPas
                })
            })
        }
        if (info.hnriosAgr.length > 0) {
            updatedPas = updatedPas.filter((pas) => {
                return info.hnriosAgr.some((ele) => {
                    return ele.value === pas.honorariosAgregados
                })
            })
        }
    };
    conFiltro();
    return (
        <main className="pb-5 pe-3 ps-3">
            <section className="sectionExpedientesGral mainAjustes">
                <div className="row">
                    <div className={cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' ? 'col' : 'd-none'}>
                        <div className="">
                            <div className="d-flex justify-content-between">
                                <h5 className="center letra">Usuarios Productores</h5>
                                <div className="row" style={{ width: '60%', gap: '20px' }}>
                                    <div className="col-2">
                                        <h6 className="subtituloDelFiltro m-0">Nombre</h6>
                                        <MultiSelect
                                            className="w-100"
                                            options={proInput.sort((a, b) => a.label.localeCompare(b.label))}
                                            value={info.nombre}
                                            onChange={(e) => {
                                                setInfo((prevState) => ({
                                                    ...prevState,
                                                    nombre: e
                                                }))
                                            }}
                                        />
                                    </div>
                                    <div className="col-2">
                                        <h6 className="subtituloDelFiltro m-0">Grupo</h6>
                                        <MultiSelect
                                            className="w-100"
                                            options={cargo}
                                            value={info.cargo}
                                            onChange={(e) => {
                                                setInfo((prevState) => ({
                                                    ...prevState,
                                                    cargo: e
                                                }))
                                            }}
                                        />
                                    </div>
                                    <div className="col-2">
                                        <h6 className="subtituloDelFiltro m-0">Asociaciones</h6>
                                        <MultiSelect
                                            className="w-100"
                                            options={asocInput.sort((a, b) => a.label.localeCompare(b.label))}
                                            value={info.asoc}
                                            onChange={(e) => {
                                                setInfo((prevState) => ({
                                                    ...prevState,
                                                    asoc: e
                                                }))
                                            }}
                                        />
                                    </div>
                                    <div className="col-2">
                                        <h6 className="subtituloDelFiltro m-0">Hnrios</h6>
                                        <MultiSelect
                                            className="w-100"
                                            options={hnrios}
                                            value={info.hnrios}
                                            onChange={(e) => {
                                                setInfo((prevState) => ({
                                                    ...prevState,
                                                    hnrios: e
                                                }))
                                            }}
                                        />
                                    </div>
                                    <div className="col-2">
                                        <h6 className="subtituloDelFiltro m-0">Hnrios Agr</h6>
                                        <MultiSelect
                                            className="w-100"
                                            options={hnrios}
                                            value={info.hnriosAgr}
                                            onChange={(e) => {
                                                setInfo((prevState) => ({
                                                    ...prevState,
                                                    hnriosAgr: e
                                                }))
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive mt-4 table-container" style={{ maxHeight: '600px' }}>
                                <table className="table">
                                    <thead>
                                        <tr style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: 'white' }}>
                                            <th>Nombre</th>
                                            <th>Usuario</th>
                                            <th>Email</th>
                                            <th>Password</th>
                                            <th>Cargo</th>
                                            <th style={{ maxWidth: '100px' }}>Crear</th>
                                            <th style={{ maxWidth: '100px' }}>News</th>
                                            <th style={{ maxWidth: '100px' }}>Hnrios</th>
                                            <th style={{ maxWidth: '100px' }}>Hnrios Agr</th>
                                            <th style={{ maxWidth: '300px' }}>Asociaciones</th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {updatedPas.filter(u => u.id !== 0).sort((a, b) => a.nombre.localeCompare(b.nombre)).map(u => (
                                            <UserPas key={u.id} user={u} productores={productores} />
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {nuevoUserPas ?
                            <div className="d-flex mt-4">
                                <form className="table-responsive" onSubmit={userPasNew}>
                                    <table className="table table-borderless align-middle">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        placeholder="Nombre"
                                                        required
                                                        value={usuarioPasNuevo.nombre}
                                                        onChange={(e) =>
                                                            setUsuarioPasNuevo((prevState) => ({
                                                                ...prevState,
                                                                nombre: e.target.value,
                                                            }))
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        placeholder="Nombre2"
                                                        value={usuarioPasNuevo.nombre4}
                                                        onChange={(e) =>
                                                            setUsuarioPasNuevo((prevState) => ({
                                                                ...prevState,
                                                                nombre4: e.target.value,
                                                            }))
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="email"
                                                        className="form-control form-control-sm"
                                                        placeholder="Email"
                                                        value={usuarioPasNuevo.email}
                                                        onChange={(e) =>
                                                            setUsuarioPasNuevo((prevState) => ({
                                                                ...prevState,
                                                                email: e.target.value,
                                                            }))
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        placeholder="Password"
                                                        required
                                                        value={usuarioPasNuevo.password}
                                                        onChange={(e) =>
                                                            setUsuarioPasNuevo((prevState) => ({
                                                                ...prevState,
                                                                password: e.target.value,
                                                            }))
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    <select
                                                        className="form-select form-select-sm"
                                                        required
                                                        onChange={(e) =>
                                                            setUsuarioPasNuevo((prevState) => ({
                                                                ...prevState,
                                                                cargo: e.target.value,
                                                            }))
                                                        }
                                                        value={usuarioPasNuevo.cargo}
                                                    >
                                                        <option value="" disabled>Cargo</option>
                                                        <option value="PAS">PAS</option>
                                                        <option value="ORGANIZACION">ORGANIZACION</option>
                                                        <option value="GRUPO">GRUPO</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <select
                                                        className="form-select form-select-sm"
                                                        required
                                                        onChange={(e) =>
                                                            setUsuarioPasNuevo((prevState) => ({
                                                                ...prevState,
                                                                crear: e.target.value,
                                                            }))
                                                        }
                                                        value={usuarioPasNuevo.crear}
                                                    >
                                                        <option value="" disabled>Crear Exp</option>
                                                        <option value="1">SI</option>
                                                        <option value="0">NO</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <select
                                                        className="form-select form-select-sm"
                                                        required
                                                        onChange={(e) =>
                                                            setUsuarioPasNuevo((prevState) => ({
                                                                ...prevState,
                                                                news: e.target.value,
                                                            }))
                                                        }
                                                        value={usuarioPasNuevo.news}
                                                    >
                                                        <option disabled value={''}>News</option>
                                                        <option value="1">SI</option>
                                                        <option value="0">NO</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        className="form-control form-control-sm"
                                                        required
                                                        placeholder="Hnrios"
                                                        value={usuarioPasNuevo.hnrios}
                                                        onChange={(e) =>
                                                            setUsuarioPasNuevo((prevState) => ({
                                                                ...prevState,
                                                                hnrios: e.target.value,
                                                            }))
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    <button className="btn btn-secondary btn-sm" onClick={(e) => setNuevoUserPas(false)}>Cancelar</button>
                                                </td>
                                                <td>
                                                    <button className="btn btn-primary btn-sm" type="submit">Agregar</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </form>

                            </div>
                            : <div></div>
                        }
                        {nuevoUserPas ?
                            <></> :
                            <div className="center mt-4">
                                <button onClick={(e) => setNuevoUserPas(true)} className={cookies.user.cargo === 'GERENCIA' ? 'btn btn-primary' : 'd-none'}>Agregar Usuario Productor</button>
                            </div>}
                    </div>
                </div>
                <div style={{ marginTop: '200px' }}>
                    <div className="">
                        <h5 className="center letra">Asignados</h5>
                        <table className="mt-4 table ">
                            <thead>
                                <tr className="">
                                    <th></th>
                                    <th className="">Recep</th>
                                    <th>Sin Den</th>
                                    <th>Armado Preliminar</th>
                                    <th>Reclamo Presentado</th>
                                    <th>Aceptacion</th>
                                    <th>Proc Pago</th>
                                    <th>Cobrado</th>
                                    <th>Mediacion</th>
                                    <th>Cerrado</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody className="list text-nowrap">
                                {asignados.sort((a, b) => a.nombre.localeCompare(b.nombre)).map((asignado) => { return (<Asignados asignado={asignado} key={asignado.id} />) })}
                            </tbody>
                        </table>
                    </div>
                    <div className="d-block w-75 m-auto mt-5">
                        <h5 className="center letra ">Mensaje Difusion</h5>
                        <label class="mb-1 d-block mt-3">Asunto</label>
                        <input type="text" class="form-control d-block"
                            onChange={(e) =>
                                setMensajeDifusion((prevState) => ({
                                    ...prevState,
                                    asunto: e.target.value,
                                }))} />
                        <label class="mt-3 mb-1">Cuerpo</label>
                        <textarea class="form-control" style={{ height: '200px' }}
                            onChange={(e) =>
                                setMensajeDifusion((prevState) => ({
                                    ...prevState,
                                    cuerpo: e.target.value,
                                }))} />
                        <div className="center mt-3"><button className="btn btn-primary btn-sm" onClick={(e) => enviarDifusion(e)}>Enviar</button></div>
                    </div>
                    {expDescarga.length > 0 ? <div className="center mt-5"><ExcelAjustes data={expDescarga} /></div> : <></>}
                </div>
                <div style={{ marginTop: '200px' }}>
                    <h5 className="center letra">Compañias</h5>
                    <div className="table-responsive mt-4 table-container" style={{ maxHeight: '600px' }}>
                        <table className="table" >
                            <thead>
                                <tr style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: 'white' }}>
                                    <th className=""></th>
                                    <th className="">Resolucion</th>
                                    <th className="">Cobro</th>
                                    <th>RECEPCIONADO</th>
                                    <th>SIN DENUNCIA</th>
                                    <th>ARMADO PRELIMINAR</th>
                                    <th>RECLAMO PRESENTADO</th>
                                    <th>ACEPTACION</th>
                                    <th>EN PROC PAGO</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody className="list text-nowrap">
                                {companias.sort((a, b) => a.compania.localeCompare(b.compania)).map((comp) => { return (<Comp comp={comp} key={comp.id}></Comp>) })}
                            </tbody>
                        </table>
                    </div>
                    {mas.comp ?
                        <div className="d-flex">
                            <form action="" className="d-flex">
                                <input type="text" className='form-control me-1 ms-1' value={comp.comp} placeholder="Compañia" onChange={(e) =>
                                    setComp((prevState) => ({
                                        ...prevState,
                                        comp: e.target.value,
                                    }))} />
                                <input type="number" className="form-control me-1 ms-1" placeholder="Plazo de Resolucion" value={comp.res} onChange={(e) =>
                                    setComp((prevState) => ({
                                        ...prevState,
                                        res: e.target.value,
                                    }))} />
                                <input type="number" className="form-control me-1 ms-1" placeholder="Plazo de Cobro" value={comp.cob} onChange={(e) =>
                                    setComp((prevState) => ({
                                        ...prevState,
                                        cob: e.target.value,
                                    }))} />
                            </form>
                        </div>
                        : <div></div>
                    }
                    {mas.comp ?
                        <div className="center d-flex mt-3">
                            <button className="btn btn-secondary" onClick={(e) => setMas({ comp: false, pas: false })}>Cancelar</button>
                            <button className="btn btn-primary ms-3" onClick={compNew}>Agregar</button>
                        </div> :
                        <div className="center">
                            <button onClick={(e) => setMas({ comp: true, pas: false })} className={cookies.user.cargo === 'GERENCIA' ? 'btn btn-primary' : 'd-none'}>Agregar Compañia</button>
                        </div>}
                </div>

                <div className="row" style={{ marginTop: '200px' }}>
                    <div className="col">
                        <div className="mt-3">
                            <h5 className="center letra">Productores</h5>
                            <div className="table-responsive table-container" style={{ maxHeight: '600px' }}>
                                <table className="mt-4 table">
                                    <thead>
                                        <tr style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: 'white' }}>
                                            <th className="">Nombre</th>
                                            <th>Mail</th>
                                        </tr>
                                    </thead>
                                    <tbody className="list text-nowrap">
                                        {pasViejo.sort((a, b) => a.nombre.localeCompare(b.nombre)).map((pas) => { return (<Pas pas={pas} key={pas.id}></Pas>) })}
                                    </tbody>
                                </table>
                            </div>
                            {mas.pas ?
                                <div className="ps-5">
                                    <form action="" className="d-flex">
                                        <input type="text" className='form-control me-1 ms-1' value={pas.nombre} placeholder="Productor" onChange={(e) =>
                                            setPas((prevState) => ({
                                                ...prevState,
                                                nombre: e.target.value,
                                            }))} />
                                        <input type="text" className='form-control me-1 ms-1' value={pas.email} placeholder="Email" onChange={(e) =>
                                            setPas((prevState) => ({
                                                ...prevState,
                                                email: e.target.value,
                                            }))} />
                                    </form>
                                </div>
                                : <div></div>
                            }
                            {mas.pas ?
                                <div className="center d-flex mt-3">
                                    <button className="btn btn-secondary" onClick={(e) => setMas({ comp: false, pas: false })}>Cancelar</button>
                                    <button className="btn btn-primary ms-3" onClick={pasNew}>Agregar</button>
                                </div> :
                                <div className="center">
                                    <button onClick={(e) => setMas({ comp: false, pas: true })} className={cookies.user.cargo === 'GERENCIA' ? 'btn btn-primary' : 'd-none'}>Agregar Productor</button>
                                </div>}
                        </div>
                    </div>
                    <div className="col">
                        <div className="mt-3">
                            <h5 className="center letra">Plazos para Niveles</h5>
                            <div className="table-responsive table-container" style={{ maxHeight: '600px' }}>
                                <table className="table">
                                    <thead>
                                        <tr style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: 'white' }}>
                                            <th className="">Estado</th>
                                            <th className="">Nivel</th>
                                            <th className="">Plazo</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody className="list text-nowrap">
                                        {niveles.map((niv) => { return (<Niveles niv={niv} key={niv.id}></Niveles>) })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ marginTop: '200px' }}>
                    <div className={cookies.user.cargo === 'GERENCIA' ? 'col' : 'd-none'}>
                        <div className="mt-3">
                            <h5 className="center letra">Gerencia</h5>
                            <div className="table-responsive mt-4 table-container" style={{ maxHeight: '600px' }}>
                                <table className="table">
                                    <thead>
                                        <tr style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: 'white' }}>
                                            <th>Nombre</th>
                                            <th>Contraseña</th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody className="list text-nowrap">
                                        {users.sort((a, b) => a.nombre.localeCompare(b.nombre)).filter(u => u.cargo === 'GERENCIA').map((u) => { return (<User user={u} key={u.id}></User>) })}
                                    </tbody>
                                </table>
                            </div>
                            {masa.ger ?
                                <div className="ps-3">
                                    <form action="" className="d-flex">
                                        <input type="text" className='form-control me-1 ms-1' value={ger.nombre} placeholder="Nombre" onChange={(e) =>
                                            setGer((prevState) => ({
                                                ...prevState,
                                                nombre: e.target.value,
                                            }))} />
                                        <input type="text" className='form-control me-1 ms-1' value={ger.password} placeholder="Contraseña" onChange={(e) =>
                                            setGer((prevState) => ({
                                                ...prevState,
                                                password: e.target.value,
                                            }))} />
                                    </form>
                                </div>
                                : <div></div>
                            }
                            {masa.ger ?
                                <div className="center d-flex mt-3">
                                    <button className="btn btn-secondary" onClick={(e) => setMasa({ ger: false, emp: false, pas: false })}>Cancelar</button>
                                    <button className="btn btn-primary ms-3" onClick={gerNew}>Agregar</button>
                                </div> :
                                <div className="center">
                                    <button onClick={(e) => setMasa({ ger: true, emp: false, pas: false })} className="btn btn-primary">Agregar Gerente</button>
                                </div>}
                        </div>
                    </div>
                    <div className={cookies.user.cargo === 'GERENCIA' ? 'col' : 'd-none'}>
                        <div className="mt-3">
                            <h5 className="center letra">Empleados</h5>
                            <div className="table-responsive mt-4 table-container" style={{ maxHeight: '600px' }}>
                                <table className="table">
                                    <thead>
                                        <tr style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: 'white' }}>
                                            <th className="">Nombre</th>
                                            <th>Contraseña</th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody className="list text-nowrap">
                                        {users.sort((a, b) => a.nombre.localeCompare(b.nombre)).filter(u => u.cargo === 'EMPLEADO').map((u) => { return (<User user={u} key={u.id}></User>) })}
                                    </tbody>
                                </table>
                            </div>
                            {masa.emp ?
                                <div className="ps-3">
                                    <form className="d-flex">
                                        <input type="text" className='form-control me-1 ms-1' value={emp.nombre} placeholder="Nombre" onChange={(e) =>
                                            setEmp((prevState) => ({
                                                ...prevState,
                                                nombre: e.target.value,
                                            }))} />
                                        <input type="text" className='form-control me-1 ms-1' value={emp.password} placeholder="Contraseña" onChange={(e) =>
                                            setEmp((prevState) => ({
                                                ...prevState,
                                                password: e.target.value,
                                            }))} />
                                    </form>
                                </div>
                                : <div></div>
                            }
                            {masa.emp ?
                                <div className="center d-flex mt-3">
                                    <button className="btn btn-secondary" onClick={(e) => setMasa({ ger: false, emp: false, pas: false })}>Cancelar</button>
                                    <button className="btn btn-primary ms-3" onClick={empNew}>Agregar</button>
                                </div> :
                                <div className="center">
                                    <button onClick={(e) => setMasa({ ger: false, emp: true, pas: false })} className="btn btn-primary">Agregar Empleado</button>
                                </div>}
                        </div>
                    </div>
                    <div className="col">
                        <div className="mt-3">
                            <h5 className="center letra">PAS</h5>
                            <div className="table-responsive mt-4 table-container" style={{ maxHeight: '600px' }}>
                                <table className="table">
                                    <thead>
                                        <tr style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: 'white' }}>
                                            <th>Nombre</th>
                                            <th>Contraseña</th>
                                            <th>Crear Exp</th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody className="list text-nowrap">
                                        {users.sort((a, b) => a.nombre.localeCompare(b.nombre)).filter(u => u.cargo === 'PAS').map((u) => { return (<User user={u} key={u.id}></User>) })}
                                    </tbody>
                                </table>
                            </div>
                            {masa.pas ?
                                <div className="ps-3">
                                    <form className="d-flex">
                                        <input type="text" className='form-control me-1 ms-1' value={pa.nombre} placeholder="Nombre" onChange={(e) =>
                                            setPa((prevState) => ({
                                                ...prevState,
                                                nombre: e.target.value,
                                            }))} />
                                        <input type="text" className='form-control me-1 ms-1' value={pa.password} placeholder="Contraseña" onChange={(e) =>
                                            setPa((prevState) => ({
                                                ...prevState,
                                                password: e.target.value,
                                            }))} />
                                    </form>
                                </div>
                                : <div></div>
                            }
                            {masa.pas ?
                                <div className="center d-flex mt-3">
                                    <button className="btn btn-secondary" onClick={(e) => setMasa({ ger: false, emp: false, pas: false })}>Cancelar</button>
                                    <button className="btn btn-primary ms-3" onClick={paNew}>Agregar</button>
                                </div> :
                                <div className="center">
                                    <button onClick={(e) => setMasa({ ger: false, emp: false, pas: true })} className={cookies.user.cargo === 'GERENCIA' ? 'btn btn-primARY' : 'd-none'}>Agregar Productor</button>
                                </div>}
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

