import './App.css';
import { HashRouter, Routes, Route } from 'react-router-dom'
import Header from './components/Header/Header';
import Expedientes from './pages/Expedientes';
import { EditarContextProvider } from './context/Editar';
import { CheckContextProvider } from './context/Check';
import { TicketsContextProvider } from './context/Tickets';
import { VencimientoContextProvider } from './context/Vencimiento';
import { ExpedienteActContextProvider } from './context/ExpedienteAct';
import AgregarExpediente from './pages/AgregarExpediente';
import AgregarCobro from './pages/AgregarCobro';
import Login from './pages/Login';
import { useCookies } from 'react-cookie';
import ExpedientesGerencia from './pages/ExpedientesGerencia';
import Ajustes from './pages/Ajustes';
import ExpedientesPas from './pages/ExpedientesPas';
import Gestion from './pages/Gestion';
import Novedades from './pages/Novedades';
import AgregarExpPas from './pages/AgregasExpPas';
import Estadisticas from './pages/Estadisticas';
import ExpedienteContainer from './pages/ExpedienteContainer';
import { VistaMediacionContextProvider } from './context/Mediacion';
import ExpedientesMediador from './pages/ExpedientesMediador';
import ExpedientesOrganizaciones from './pages/ExpedientesOrganizaciones';
import RutaAmpliacionPas from './pages/RutaAmpliacionPas';
import TablaMediacion from './pages/TablaMediacion';
import { CheckMediacionContextProvider } from './context/CheckMediacion';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Files from './pages/Files';
import File from './pages/File';
import SesionOut from './pages/SesionOut';
import ExpedientesAgente from './pages/ExpedientesAgente';
import LoginCliente from './pages/LoginCliente';
import ExpedienteCliente from './pages/ExpedienteCliente';
function App() {
  const [cookies, setCookie, removeCookie] = useCookies(['user']);
  const user = localStorage.getItem('user')
  const a = localStorage.getItem('dia');
  const runFunction = async () => {
    if (user !== null && a !== new Date().toLocaleDateString('en-GB')) {
      removeCookie('contadorExp')
      removeCookie('contadorExpMediacion')
      removeCookie('exp')
      removeCookie('expMediacion')
      removeCookie('gestion')
      removeCookie('tabla')
      removeCookie('tablaGerencia')
      removeCookie('ticket')
      removeCookie('user')
      localStorage.removeItem('user');
      window.location.href = '/#/login';
    }
  };
  const hora = localStorage.getItem('hora')
  const [time, setTime] = useState(new Date());
  useEffect(() => {
    const timerId = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => clearInterval(timerId);
  }, []);
  const formatTime = (date) => {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };
  const getTimeDifferenceInSeconds = (timeString1, timeString2) => {
    const [hours1, minutes1, seconds1] = timeString1.split(':').map(Number);
    const [hours2, minutes2, seconds2] = timeString2.split(':').map(Number);
    const date1 = new Date();
    date1.setHours(hours1, minutes1, seconds1, 0);
    const date2 = new Date();
    date2.setHours(hours2, minutes2, seconds2, 0);
    const differenceInMilliseconds = Math.abs(date1 - date2);
    return Math.floor(differenceInMilliseconds / 1000);
  };
  useEffect(() => {
    if (cookies.user && user !== null && getTimeDifferenceInSeconds(formatTime(time), hora) > 2700) {
      if (cookies.user.nombre !== 'JUAN' && cookies.user.nombre !== 'FRANCO' && cookies.user.nombre !== 'DANIELA') {
        axios.post('https://api.tuveunchoque.com.ar/api/registroDeUso/update', { user: cookies.user.nombre, segundos: getTimeDifferenceInSeconds(formatTime(time), hora) })
        axios.post('https://api.tuveunchoque.com.ar/api/registroDeUso/cerrar1', { user: cookies.user.nombre })
        localStorage.setItem('hora', formatTime(new Date()))
        removeCookie('contadorExp')
        removeCookie('contadorExpMediacion')
        removeCookie('exp')
        removeCookie('expMediacion')
        removeCookie('gestion')
        removeCookie('tabla')
        removeCookie('tablaGerencia')
        removeCookie('ticket')
        removeCookie('user')
        localStorage.removeItem('user');
        window.location.reload()
      }
    }
  }, [time])
  runFunction()
  return (
    <HashRouter>
      <VistaMediacionContextProvider>
        <ExpedienteActContextProvider>
          <VencimientoContextProvider>
            <TicketsContextProvider>
              <EditarContextProvider>
                <CheckMediacionContextProvider>
                  <CheckContextProvider>
                    <Header />
                    <Routes>
                      <Route exact path='/login' element={<Login />} />
                      <Route exact path='/cliente/login' element={<LoginCliente />} />
                      <Route exact path='cliente/exp' element={<ExpedienteCliente/>}/>
                      {user !== null ? <Route exact path='/mediacion' element={<TablaMediacion />} /> : <Route exact path='/mediacion' element={<SesionOut />} />}
                      {user !== null ? <Route exact path='/estadisticas' element={<Estadisticas />} /> : <Route exact path='/estadisticas' element={<SesionOut />} />}
                      {user !== null ? <Route exact path='/nuevoExpPas' element={<AgregarExpPas />} /> : <Route exact path='/nuevoExpPas' element={<SesionOut />} />}
                      {user !== null ? <Route exact path='/ajustes' element={<Ajustes />} /> : <Route exact path='/ajustes' element={<SesionOut />} />}
                      {user !== null ? <Route exact path='/gestion' element={<Gestion />} /> : <Route exact path='/gestion' element={<SesionOut />} />}
                      {user !== null ? <Route exact path='/novedades' element={<Novedades />} /> : <Route exact path='/novedades' element={<SesionOut />} />}
                      {cookies.user && user !== null ? <Route exact path='/expedientes' element={cookies.user.cargo === 'GERENCIA' ? <ExpedientesGerencia /> : (cookies.user.cargo === 'EMPLEADO' ? <Expedientes /> : (cookies.user.cargo === 'PAS' ? <ExpedientesPas /> : (cookies.user.cargo === 'MEDIADOR' ? <ExpedientesMediador /> : ((cookies.user.cargo === "ORGANIZACION" || cookies.user.cargo === "GRUPO") ? <ExpedientesOrganizaciones /> : (cookies.user.cargo === 'AGENTE' ? <ExpedientesAgente /> : <></>)))))} /> : <Route exact path='/expedientes' element={<SesionOut />} />}
                      {user !== null ? <Route exact path='/expediente/:id' element={<ExpedienteContainer />} /> : <Route exact path='/expediente/:id' element={<SesionOut />} />}
                      <Route exact path='/api/:id/:hash' element={<RutaAmpliacionPas />} />
                      {user !== null ? <Route exact path='/expediente/cobro/nuevo' element={<AgregarCobro />} /> : <Route exact path='/expediente/cobro/nuevo' element={<SesionOut />} />}
                      {cookies.user && user !== null ? <Route exact path='/expediente/nuevo' element={<AgregarExpediente />} /> : <Route exact path='/expediente/nuevo' element={<SesionOut />} />}
                      {user !== null ? <Route exact path='/files' element={<Files />} /> : <Route exact path='/files' element={<SesionOut />} />}
                      {user !== null ? <Route exact path='files/:id' element={<File />} /> : <Route exact path='/files/:id' element={<SesionOut />} />}
                    </Routes>
                  </CheckContextProvider>
                </CheckMediacionContextProvider>
              </EditarContextProvider>
            </TicketsContextProvider>
          </VencimientoContextProvider>
        </ExpedienteActContextProvider>
      </VistaMediacionContextProvider>
    </HashRouter>
  );
}

export default App;
