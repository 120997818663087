import Estado from './Estado';
import Nivel from './Nivel';
import { Link } from "react-router-dom"
import fresh from '../../images/fresh.png'
import callbell from '../../images/callbell.jpeg'
import sharepoint from '../../images/sharepoint.jpeg'
import sharepointRojo from '../../images/shareRojo.png'
import './expedientesTablas.css'
import axios from 'axios';
import { useCallback, useState, useEffect } from 'react';
import { useCookies } from "react-cookie";
import EstadoMediacionTabla from './EstadoMediacionTabla';
export default function ExpedienteTablaMediacion({ expediente, }) {
    const logo = { height: '27px', width: '27px', background: 'transparent 0% 0% no-repeat padding-box', opacity: 1 }
    const link = { textDecoration: 'none' }
    const btn = { border: 'none', background: 'none' }
    const [check, setChecked] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(["expMediacion"], ['expActual']);
    function handleChange(e) {
        setChecked(e.target.checked);
        if (!check) {
            const a = cookies.expMediacion.includes(expediente.nro);
            if (!a) {
                cookies.expMediacion.push(expediente.nro);
            }
        } else if (check) {
            const a = cookies.expMediacion.indexOf(expediente.nro);
            const b = cookies.expMediacion.includes(expediente.nro);
            if (b) {
                cookies.expMediacion.splice(a, 1);
            }
        }
        setCookie("expMediacion", cookies.expMediacion);
    }
    const pasCargo = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(`https://api.tuveunchoque.com.ar/api/mail/ampliacionCargaPas`, {
            id: expediente.id,
            carga: 0
        }).then((response) => {
            if (response) {
                setTimeout(async () => {
                    window.location.reload()
                }, 200);
            }
        })
    }, [expediente,])
    const hora = localStorage.getItem('hora');
    const [time, setTime] = useState(new Date());
    useEffect(() => {
        const timerId = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => clearInterval(timerId);
    }, []);
    const formatTime = (date) => {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };
    const getTimeDifferenceInSeconds = (timeString1, timeString2) => {
        const [hours1, minutes1, seconds1] = timeString1.split(':').map(Number);
        const [hours2, minutes2, seconds2] = timeString2.split(':').map(Number);
        const date1 = new Date();
        date1.setHours(hours1, minutes1, seconds1, 0);
        const date2 = new Date();
        date2.setHours(hours2, minutes2, seconds2, 0);
        const differenceInMilliseconds = Math.abs(date1 - date2);
        return Math.floor(differenceInMilliseconds / 1000);
    };
    const registro = async (e) => {
        await axios.post('https://api.tuveunchoque.com.ar/api/registroDeUso/update', { user: cookies.user.nombre, segundos: getTimeDifferenceInSeconds(formatTime(time), hora) })
        setCookie('expActual', expediente.nro)
        localStorage.setItem('hora', formatTime(time))
        window.location.reload()
    }
    return (
        <>
            {window.innerWidth > 1920 ?
                <div className='containerTablaExpedientes row mb-1 ps-3 pe-2'>
                    {expediente.prioridad === 'ALTA' ?
                        <div className="d-flex col center align-items-center">
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    onChange={handleChange}
                                    checked={cookies.expMediacion.includes(expediente.nro)}
                                />
                            </div>
                            <div class="tituloExpedienteTabla1 text-danger p-0">
                                <Link onClick={(e) => registro(e)} to={`/expediente/${expediente.nro}`} style={link} className='text-danger'>{expediente.nro}</Link>
                            </div>
                        </div> :
                        <div className='d-flex col align-items-center'>
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    onChange={handleChange}
                                    checked={cookies.expMediacion.includes(expediente.nro)}
                                />
                            </div>
                            <div class="tituloExpedienteTabla1 p-0">
                                <Link onClick={(e) => registro(e)} to={`/expediente/${expediente.nro}`} style={link}>{expediente.nro}</Link>
                            </div></div>}
                    <div class="tituloExpedienteTabla2 col p-0">{expediente.dominioCliente}</div>
                    <div class="tituloExpedienteTabla3 col p-0">{expediente.productorAsociado}</div>
                    <div class="tituloExpedienteTabla4 col p-0">{expediente.fechaCambioEstado?.split("-").reverse().join("-")}</div>
                    <div class="tituloExpedienteTabla5 col p-0">{expediente.compania}</div>
                    <div class="tituloExpedienteTabla6 col-3 p-0">{expediente.nombre}</div>
                    <div class="tituloExpedienteTabla7 col p-0">{expediente.fechaVencimiento?.split("-").reverse().join("-")}</div>
                    <div className='col-2 p-0 tituloExpedienteTabla8'>
                        <div className='d-flex align-items-center'>
                            {expediente.ticket ? <img src={fresh} style={logo} className='me-1'></img> : <div style={logo} className='me-1'></div>}
                            {expediente.callbell ? <img src={callbell} style={logo} className='me-1'></img> : <div style={logo} className='me-1'></div>}
                            {expediente.sharepoint ? <Link to={`${expediente.sharepoint}`} target="_blank" rel="noopener noreferrer"><img src={sharepoint} style={logo} className='me-1'></img></Link> : <div style={logo} className='me-1'></div>}
                            {expediente.pasCargoDocumentacion ? <button style={btn} onClick={pasCargo}><img src={sharepointRojo} style={logo} className='me-1'></img> </button> : <div style={logo} className='me-1'></div>}
                            <Nivel nivel={expediente.nivel} />
                            <div className="center ms-1 mt-1 text-center">
                                <h6 className={expediente.etapaMediacion === 1 ? 'estadoATabla center' : (expediente.etapaMediacion === 2 ? 'estadoBTabla center' : (expediente.etapaMediacion === 3 ? 'estadoCTabla center' : (expediente.etapaMediacion === 4 ? 'estadoDTabla center' : (expediente.etapaMediacion === 5 ? 'estadoETabla center' : (expediente.etapaMediacion === 6 ? 'estadoFTabla center' : (expediente.etapaMediacion === 7 ? 'estadoGTabla center' : 'estadoH2Tabla center'))))))}>{expediente.etapaMediacion === 1 ? 'SOLICITUD DE FECHA' : (expediente.etapaMediacion === 2 ? 'FECHA ASIGNADA' : (expediente.etapaMediacion === 3 ? 'NEGOCIACION' : (expediente.etapaMediacion === 4 ? 'REVISIONES LEGALES' : (expediente.etapaMediacion === 5 ? 'ARMADO DERIVACION' : (expediente.etapaMediacion === 6 ? 'ESPERA CONFIRMACION' : (expediente.etapaMediacion === 7 ? 'ACEPTACION MEDIACION' : 'Sin Etapa'))))))}</h6>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className='containerTablaExpedientes row mb-1 ps- pe-2'>
                    {expediente.prioridad === 'ALTA' ?
                        <div className="d-flex col center align-items-center">
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    onChange={handleChange}
                                    checked={cookies.expMediacion.includes(expediente.nro)}
                                />
                            </div>
                            <div class="tituloExpedienteTabla11366 text-danger p-0">
                                <Link onClick={(e) => registro(e)} to={`/expediente/${expediente.nro}`} style={link} className='text-danger'>{expediente.nro}</Link>
                            </div>
                        </div> :
                        <div className='d-flex col align-items-center'>
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    onChange={handleChange}
                                    checked={cookies.expMediacion.includes(expediente.nro)}
                                />
                            </div>
                            <div class="tituloExpedienteTabla11366 p-0">
                                <Link onClick={(e) => registro(e)} to={`/expediente/${expediente.nro}`} style={link}>{expediente.nro}</Link>
                            </div></div>}
                    <div class="tituloExpedienteTabla21366 col p-0">{expediente.dominioCliente}</div>
                    <div class="tituloExpedienteTabla31366 col p-0">{expediente.productorAsociado}</div>
                    <div class="tituloExpedienteTabla41366 col p-0">{expediente.fechaCambioEstado?.split("-").reverse().join("-")}</div>
                    <div class="tituloExpedienteTabla51366 col p-0">{expediente.compania}</div>
                    <div class="tituloExpedienteTabla61366 col-3 p-0">{expediente.nombre}</div>
                    <div class="tituloExpedienteTabla71366 col p-0">{expediente.fechaVencimiento?.split("-").reverse().join("-")}</div>
                    <div className='col-2 p-0 tituloExpedienteTabla81366'>
                        <div className='d-flex align-items-center'>
                            {expediente.ticket ? <img src={fresh} style={logo} className='me-1'></img> : <div style={logo} className='me-1'></div>}
                            {expediente.callbell ? <img src={callbell} style={logo} className='me-1'></img> : <div style={logo} className='me-1'></div>}
                            {expediente.sharepoint ? <Link to={`${expediente.sharepoint}`} target="_blank" rel="noopener noreferrer"><img src={sharepoint} style={logo} className='me-1'></img></Link> : <div style={logo} className='me-1'></div>}
                            {expediente.pasCargoDocumentacion ? <button style={btn} onClick={pasCargo}><img src={sharepointRojo} style={logo} className='me-1'></img> </button> : <div style={logo} className='me-1'></div>}
                            <Nivel nivel={expediente.nivel} />
                            <div className="center ms-1 mt-1 text-center">
                                <EstadoMediacionTabla estado={expediente.etapaMediacion} />
                            </div>
                        </div>
                    </div>
                </div>}
        </>

    )
}