import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import "react-circular-progressbar/dist/styles.css";
export default function NivelTicket({ nivel }) {
    let color = 'transparent'
    let porcentaje = '0'
    if (Number(nivel) === 1) {
        color = '#4CAF50'
        porcentaje = 100
    } else if (Number(nivel) === 2) {
        color = '#FFC107'
        porcentaje = 100
    } else if (Number(nivel) === 3) {
        color = '#F44336'
        porcentaje = 100
    }
    const style = {height: '22px', width: '22px', boxShadow: '0 1px 1px rgba(0, 0, 0, 0.2)', borderRadius: '50%',}
    return (
        <div style={style}>
        <CircularProgressbar value={porcentaje} circleRatio={1} styles={buildStyles({ rotation: 0.7, pathColor: `${color}` })} />
    </div>
    )
}