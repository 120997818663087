import { useCallback, useEffect, useState } from "react";
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { MultiSelect } from "react-multi-select-component";

export default function UserPas({ user, productores }) {
    const [cookies] = useCookies(['user']);
    const [edit, setEdit] = useState(false);
    const [com, setCom] = useState({
        nombre: user.nombre,
        nombre3: user.nombre3,
        email: user.email4,
        password: user.password,
        cargo: user.cargo,
        crearExpediente: user.crearExpediente,
        hash: user.hash,
        newsletter: user.newsletter,
        asoc: user.asoc,
        honorariosPas: user.honorariosPas,
        honorariosAgregados: user.honorariosAgregados,
    });
    const initialSelected = user.asoc.split(',').map(id => id.trim()).filter(id => id).map(id => {
        const producer = productores.find(prod => prod.id == id && (prod.cargo === 'PAS' || prod.cargo === 'ORGANIZACION'));
        return producer ? { label: producer.nombre, value: producer.id } : null;
    }).filter(item => item !== null);
    const [selectedIds, setSelectedIds] = useState(initialSelected);
    const handleMultiSelectChange = (selected) => {
        setSelectedIds(selected); // Actualiza el estado con las opciones seleccionadas
    };
    const compUpdate = useCallback(async () => {
        await axios.post(
            `https://api.tuveunchoque.com.ar/api/nuevosUsuarios/actualizarUsuarioPas`,
            {
                id: user.id,
                nombre: com.nombre,
                nombre3: com.nombre3,
                email4: com.email,
                password: com.password,
                cargo: com.cargo,
                crear: com.crearExpediente,
                news: com.newsletter,
                hnrios: com.honorariosPas,
                asoc: selectedIds.map(option => option.value).join(',')
            }
        ).then((response) => {
            if (response) {
                setTimeout(() => {
                    window.location.reload();
                }, 10);
            }
        });
    }, [user, selectedIds, com]);
    return (
        <tr className='letra'>
            <td className="align-middle">
                {!edit ? (
                    <h6>{user.nombre}</h6>
                ) : (
                    <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder={user.nombre}
                        onChange={(e) =>
                            setCom((prevState) => ({
                                ...prevState,
                                nombre: e.target.value,
                            }))
                        }
                        value={com.nombre}
                    />
                )}
            </td>
            <td className="align-middle">
                {!edit ? (
                    <h6>{user.nombre3}</h6>
                ) : (
                    <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder={user.nombre3}
                        onChange={(e) =>
                            setCom((prevState) => ({
                                ...prevState,
                                nombre3: e.target.value,
                            }))
                        }
                        value={com.nombre3}
                    />
                )}
            </td>
            <td className="align-middle">
                {!edit ? (
                    <h6>{user.email4}</h6>
                ) : (
                    <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder={user.email4}
                        onChange={(e) =>
                            setCom((prevState) => ({
                                ...prevState,
                                email: e.target.value,
                            }))
                        }
                        value={com.email}
                    />
                )}
            </td>
            <td className="align-middle">
                {!edit ? (
                    <h6>{user.password}</h6>
                ) : (
                    <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder={user.password}
                        onChange={(e) =>
                            setCom((prevState) => ({
                                ...prevState,
                                password: e.target.value,
                            }))
                        }
                        value={com.password}
                    />
                )}
            </td>
            <td className="align-middle">
                {!edit ? (
                    <h6>{user.cargo}</h6>
                ) : (
                    <select
                        className="form-select"
                        onChange={(e) =>
                            setCom((prevState) => ({
                                ...prevState,
                                cargo: e.target.value,
                            }))
                        }
                        value={com.cargo}
                    >
                        <option value='PAS'>PAS</option>
                        <option value='ORGANIZACION'>ORGANIZACION</option>
                        <option value="GRUPO">GRUPO</option>
                    </select>
                )}
            </td>
            <td className="align-middle" style={{ maxWidth: '100px' }}>
                {!edit ? (
                    <h6>{user.crearExpediente ? 'SI' : 'NO'}</h6>
                ) : (
                    <select
                        className="form-select"
                        onChange={(e) =>
                            setCom((prevState) => ({
                                ...prevState,
                                crearExpediente: e.target.value,
                            }))
                        }
                        value={com.crearExpediente}
                    >
                        <option value={1}>SI</option>
                        <option value={0}>NO</option>
                    </select>
                )}
            </td>
            <td className="align-middle" style={{ maxWidth: '100px' }}>
                {!edit ? (
                    <h6>{user.newsletter ? 'SI' : 'NO'}</h6>
                ) : (
                    <select
                        className="form-select"
                        onChange={(e) =>
                            setCom((prevState) => ({
                                ...prevState,
                                newsletter: e.target.value,
                            }))
                        }
                        value={com.newsletter}
                    >
                        <option value={1}>SI</option>
                        <option value={0}>NO</option>
                    </select>
                )}
            </td>
            <td className="align-middle" style={{ maxWidth: '100px' }}>
                {!edit ? (
                    <h6>{user.honorariosPas}</h6>
                ) : (
                    <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder={user.honorariosPas}
                        onChange={(e) =>
                            setCom((prevState) => ({
                                ...prevState,
                                honorariosPas: e.target.value,
                            }))
                        }
                        value={com.honorariosPas}
                    />
                )}
            </td>
            <td className="align-middle" style={{ maxWidth: '100px' }}>
                <h6>{user.honorariosAgregados}</h6>
            </td>
            <td className="align-middle" style={{ maxWidth: '300px' }}>
                {!edit ? (
                    <h6>{user.asoc.split(',').map(id => {
                        const foundOption = productores.find(option => option.id === Number(id.trim()));
                        return foundOption ? foundOption.nombre : id.trim();
                    }).join(', ')}</h6>
                ) : (
                    user.cargo === 'PAS' ? (
                        <></>
                    ) : user.cargo === 'ORGANIZACION' ? (
                        <MultiSelect
                            options={productores
                                .filter((prod) => prod.cargo === 'PAS')
                                .map((prod) => ({
                                    label: prod.nombre,
                                    value: prod.id,
                                }))}
                            value={selectedIds} 
                            onChange={handleMultiSelectChange}
                            labelledBy="Selecciona Productores" 
                        />
                    ) : user.cargo === 'GRUPO' ? (
                        <MultiSelect
                            options={productores.filter((prod) => prod.cargo === 'PAS' || prod.cargo === 'ORGANIZACION').map((prod) => ({
                                label: prod.nombre,
                                value: prod.id,
                            }))}
                            value={selectedIds} 
                            onChange={handleMultiSelectChange} 
                            labelledBy="Selecciona Productores" 
                        />
                    ) : null
                )}

            </td>
            <td className="align-middle">
                {edit && (
                    <button
                        className="btn btn-primary btn-sm me-2"
                        onClick={compUpdate}
                    >
                        Actualizar
                    </button>
                )}
            </td>
            <td className="align-middle">
                {!edit ? (
                    <button
                        style={{ border: 'none', background: 'none' }}
                        onClick={() => setEdit(true)}
                        className={cookies.user.cargo === 'GERENCIA' ? '' : 'd-none'}
                    >
                        <i className="bi bi-pencil-square"></i>
                    </button>
                ) : (
                    <button
                        style={{ border: 'none', background: 'none' }}
                        onClick={() => setEdit(false)}
                    >
                        <i className="bi bi-x-circle-fill"></i>
                    </button>
                )}
            </td>
            
        </tr>
    );
}
