import '../ExpedienteMediacion/mediacion.css'
export default function EstadoMediacionTabla({ estado }) {
    let clas = ''
    let clas1366 = ''
    if(estado === 1 || estado === 'RECEPCIONADO') {
        clas = 'estadoATabla center'
        clas1366 = 'estadoATabla1366 center'
    }
    else if (estado === 2 || estado === 'SIN DENUNCIA') {
        clas = 'estadoBTabla center'
        clas1366 = 'estadoBTabla1366 center'
    } 
    else if (estado === 3 || estado === 'ARMADO PRELIMINAR') {
        clas = 'estadoCTabla center'
        clas1366 = 'estadoCTabla1366 center'
    } 
    else if (estado === 4 || estado === 'RECLAMO PRESENTADO') {
        clas = 'estadoDTabla center'
        clas1366 = 'estadoDTabla1366 center'
    } 
    else if (estado === 5 || estado === 'ACEPTACION') {
        clas = 'estadoETabla center'
        clas1366 = 'estadoETabla1366 center'
    } 
    else if (estado === 6 || estado === 'EN PROCESO DE PAGO') {
        clas = 'estadoFTabla center'
        clas1366 = 'estadoFTabla1366 center'
    }
    else if (estado === 7 || estado === 'COBRADO') {
        clas = 'estadoGTabla center'
        clas1366 = 'estadoGTabla1366 center'
    }
    else if (estado === 8 || estado === 'COBRADO') {
        clas = 'estadoHTabla center'
        clas1366 = 'estadoHTabla1366 center'
    }
    else if (estado === 'CERRADO') {
        clas = 'estadoITabla center'
        clas1366 = 'estadoITabla1366 center'
    }
    return (
        <div className="center text-center ms-1 m-0">
            <h6 className={window.innerWidth > 1920 ? clas : clas1366}>{estado === 1 ? 'Solicitud de Fecha' : (estado === 2 ? 'Fecha Asignada' : (estado === 3 ? 'Negociacion' : (estado === 4 ? 'Revisiones Legales' : (estado === 5 ? 'Armado Derivacion' : (estado === 6 ? 'Espera Confirmacion' : (estado === 7 ? 'Aceptacion' : (estado === 8 ? 'Legales' : estado)))))))}</h6>
        </div>
    )
}