import Estado from './Estado';
import { Link } from "react-router-dom"
import contactopas from '../../images/iconoClienteContactado.png'
import pay from '../../images/pay.png'
import { useCookies } from 'react-cookie';
import sharepoint from "../../images/sharepoint.jpeg";
import cobroPas from "../../images/iconopago.png";
export default function ExpedientePas(expediente) {
    const logo = { height: '27px', width: '27px', background: 'transparent 0% 0% no-repeat padding-box', opacity: 1 }
    const logo2 = { height: '22px', width: '22px', background: 'transparent 0% 0% no-repeat padding-box', opacity: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }
    const link = { textDecoration: 'none' }
    const [cookies, setCookie, removeCookie] = useCookies(["user"]);
    return (
        <div className='containerTablaExpedientes row ps-3 pe-2 mt-1'>
            <div class="tituloExpedienteTabla11366 col p-0"><Link to={`/expediente/${expediente.expediente.nro}`} style={link}>{expediente.expediente.nro}</Link></div>
            {cookies.user.cargo !== 'PAS' ? <div class="tituloExpedienteTabla21366 col p-0">{expediente.expediente.productorAsociado}</div> : null}
            <div class="tituloExpedienteTabla21366 col p-0">{expediente.expediente.dominioCliente}</div>
            <div class="tituloExpedienteTabla41366 col p-0">{expediente.expediente.fechaStro?.split("-").reverse().join("-")}</div>
            <div class="tituloExpedienteTabla51366 col p-0">{expediente.expediente.compania}</div>
            <div class="tituloExpedienteTabla61366 col-3 p-0">{expediente.expediente.nombre}</div>
            <div class="tituloExpedienteTabla41366 text-center col p-0">{expediente.expediente.mesEstimado}</div>
            <div class="col p-0 center"><Estado estado={expediente.expediente.estado} /></div>
            <div className="col p-0 center">
                {expediente.expediente.callbell ?
                    <div className="tooltip-container">
                        <img src={contactopas} style={window.innerWidth > 1920 ? logo : logo2} />
                        <span className="tooltip-text">Cliente Contactado</span>
                    </div> 
                    : <div style={window.innerWidth > 1920 ? logo : logo2} className=''></div>}

                {expediente.expediente.pasCobroHonorarios.trim() === 'SI' ? 
                <div className="tooltip-container ms-3">
                    <img src={cobroPas} style={window.innerWidth > 1920 ? logo : logo2} className=''></img>
                    <span className="tooltip-text">Honorarios Cobrados</span>
                </div> 
                : <div style={window.innerWidth > 1920 ? logo : logo2} className='ms-3'></div>}

                {expediente.expediente.honorarios ? 
                <img src={pay} style={window.innerWidth > 1920 ? logo : logo2} className='ms-3'></img> 
                : <div style={window.innerWidth > 1920 ? logo : logo2} className='ms-3'></div>}

                {expediente.expediente.sharepoint ?
                    <Link to={`${expediente.expediente.sharepoint}`} target="_blank"rel="noopener noreferrer" className='ms-3'>
                        <img src={sharepoint} alt="" style={window.innerWidth > 1920 ? logo : logo2} />
                    </Link> 
                    : <div style={window.innerWidth > 1920 ? logo : logo2} className='ms-3'></div>}

            </div>
        </div>
    )
}