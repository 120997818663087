import {useContext} from "react";
import SeccionRequeridoEditar from "./SeccionRequeridoEditar";
import SeccionRequeridoLeer from "./SeccionRequeridoLeer";
import { editarContext } from "../../../context/Editar";
export default function SeccionRequerido({expediente}) {
    const {editar} = useContext(editarContext)
    return (
        <div className="">
            {editar ?
                <SeccionRequeridoEditar expediente={expediente}/> :
                <SeccionRequeridoLeer expediente={expediente}/>}
        </div>
    )
}