import './linea.css'
import img1 from "../../../images/v1.png";
import img2 from "../../../images/v2.png";
import img3 from "../../../images/v3.png";
import img4 from "../../../images/v4.png";
import img5 from "../../../images/v5.png";
import img6 from "../../../images/v6.png";
import img7 from "../../../images/v7.png";
import img8 from '../../../images/MEDIACION1.png'
import img9 from '../../../images/CERRADO1.png'
export default function LineaProgreso(expediente) {
    const img = {}
    let clas = ''
    const estado = expediente.expediente.estado
    if(estado === 'RECEPCIONADO') {
        clas = img1
    }
    else if (estado === 'SIN DENUNCIA') {
        clas = img2
    } 
    else if (estado === 'ARMADO PRELIMINAR') {
        clas = img3
    } 
    else if (estado === 'RECLAMO PRESENTADO') {
        clas = img4
    } 
    else if (estado === 'ACEPTACION') {
        clas = img5
    } 
    else if (estado === 'EN PROCESO DE PAGO') {
        clas = img6
    }
    else if (estado === 'COBRADO') {
        clas = img7
    }
    else if (estado === 'MEDIACION') {
        clas = img8
    }
    else if (estado === 'CERRADO') {
        clas = img9
    }
    return (
        <div className="lineaProgresoAnclado">
            <img src={clas} alt="estado" style={img} className='img-fluid'/>
        </div>
    )
}