import axios from "axios"
import { useCallback, useEffect, useState } from "react"
import Select from 'react-select';
import { editarContext } from "../../../context/Editar";
import { useContext } from "react";
import { useCookies } from 'react-cookie';
import { vencimientoContext } from "../../../context/Vencimiento";
export default function SeccionRequeridoEditar({ expediente, }) {
    const [companias, setCompanias] = useState([])
    useEffect(() => {
        axios.get('https://api.tuveunchoque.com.ar/api/gerencia/companias').then((res) => {
            setCompanias(res.data);
        });
    }, []);
    const [carga, setCarga] = useState(false)
    const [requeridoData, setRequeridoData] = useState({
        nombreRequerido: expediente.nombreRequerido,
        nombreConductorReq: expediente.nombreConductorRequerido,
        dominioReq: expediente.patenteRequerido,
        marcaReq: expediente.marcaModeloRequerido,
        compReq: expediente.compania,
        nroSeg: expediente.nroSeguimientoCompaniaRequerido,
        nroReclamoReq: expediente.nroReclamoCompaniaRequerido,
        nroReclamoCia: expediente.polizaRequerido,
        telReq: expediente.telefonoContacto,
        dniReq: expediente.dniRequerido,
        callbell: expediente.callbellRequerido,
    })
    const [cookies] = useCookies(['user']);
    const hoy = new Date().toISOString().split('T')[0];
    const { vencimiento } = useContext(vencimientoContext)
    const requeridoDataUpdate = useCallback(async (e) => {
        e.preventDefault()
        await axios.post(`https://api.tuveunchoque.com.ar/api/gerencia/requerido/update`,
            {
                id: expediente.id,
                nombreRequerido: requeridoData.nombreRequerido,
                nombreConductorReq: requeridoData.nombreConductorReq,
                dominioReq: requeridoData.dominioReq,
                marcaReq: requeridoData.marcaReq,
                compReq: requeridoData.compReq,
                nroSeg: requeridoData.nroSeg,
                nroReclamoReq: requeridoData.nroReclamoReq,
                nroReclamoCia: requeridoData.nroReclamoCia,
                telReq: requeridoData.telReq,
                dniReq: requeridoData.dniReq,
                callbell: requeridoData.callbell,
                ultMod: hoy,
                compViejo: expediente.compania,
                estadoViejo: expediente.estado,
                vencimiento: new Date(vencimiento).toLocaleDateString('fr-FR'),
                modificadoPor: cookies.user.nombre,
                esNovedades: expediente.asignadoA !== cookies.user.nombre ? 1 : 0
            }).then((response) => {
                setCarga(true)
                if (response) {
                    setTimeout(async () => {
                        window.location.reload()
                    }, 1000);
                }
            })
    }, [requeridoData, vencimiento, expediente])
    const { setEditarFalse } = useContext(editarContext)
    return (
        <div>
            <form className="mt-1 pe-3 ps-3" onSubmit={requeridoDataUpdate}>
                <div class="mb-2 row">
                    <div className="col">
                        <label class="mb-1 letraPlanillaExpediente">Nombre Requerido</label>
                        <input type="text" class="form-control" value={requeridoData.nombreRequerido}
                            onChange={(e) =>
                                setRequeridoData((prevState) => ({
                                    ...prevState,
                                    nombreRequerido: e.target.value,
                                }))} />
                    </div>
                    <div class="col">
                        <label class="mb-1 letraPlanillaExpediente">Nombre del Conductor Requerido</label>
                        <input type="text" class="form-control" value={requeridoData.nombreConductorReq}
                            onChange={(e) =>
                                setRequeridoData((prevState) => ({
                                    ...prevState,
                                    nombreConductorReq: e.target.value,
                                }))} />
                    </div>
                </div>
                <div class="mb-2 row">
                    <div className="col">
                        <label class="mb-1 letraPlanillaExpediente">Dominio Requerido</label>
                        <input type="text" class="form-control" value={requeridoData.dominioReq}
                            onChange={(e) =>
                                setRequeridoData((prevState) => ({
                                    ...prevState,
                                    dominioReq: e.target.value,
                                }))} />
                    </div>
                    <div class="col">
                        <label class="mb-1 letraPlanillaExpediente">Marca/Modelo Requerido</label>
                        <input type="text" class="form-control" value={requeridoData.marcaReq}
                            onChange={(e) =>
                                setRequeridoData((prevState) => ({
                                    ...prevState,
                                    marcaReq: e.target.value,
                                }))} />
                    </div>
                </div>
                <div class="mb-2 row">
                    <div className="col">
                        <label class="mb-1 letraPlanillaExpediente">Compañia Requerido</label>
                        <Select
                            options={companias.sort((a, b) => a.compania.localeCompare(b.compania)).map(comp => ({ value: comp['ID'], label: comp.compania }))}
                            placeholder={expediente.compania}
                            onChange={(newValue) => setRequeridoData((prevState) => ({
                                ...prevState,
                                compReq: newValue.value,
                            }))}
                        />
                    </div>
                    <div class="col">
                        <label class="mb-1 letraPlanillaExpediente">Chat Callbell Requerido</label>
                        <input type="text" class="form-control" value={requeridoData.callbellRequerido}
                            onChange={(e) =>
                                setRequeridoData((prevState) => ({
                                    ...prevState,
                                    callbell: e.target.value,
                                }))} />
                    </div>
                </div>
                <div class="mb-2 row">
                    <div class="col">
                        <label class="mb-1 letraPlanillaExpediente">Telefono Requerido</label>
                        <input type="text" class="form-control" value={requeridoData.telReq}
                            onChange={(e) =>
                                setRequeridoData((prevState) => ({
                                    ...prevState,
                                    telReq: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1 letraPlanillaExpediente">DNI Requerido</label>
                        <input type="text" class="form-control" value={requeridoData.dniReq}
                            onChange={(e) =>
                                setRequeridoData((prevState) => ({
                                    ...prevState,
                                    dniReq: e.target.value,
                                }))} />
                    </div>
                </div>
                <div class="mb-2 row">
                    <div class="col">
                        <label class="mb-1 letraPlanillaExpediente">Nro de Siniestro Requerido</label>
                        <input type="text" class="form-control" value={requeridoData.nroSeg}
                            onChange={(e) =>
                                setRequeridoData((prevState) => ({
                                    ...prevState,
                                    nroSeg: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1 letraPlanillaExpediente">Nro de Reclamo del Requerido</label>
                        <input type="text" class="form-control" value={requeridoData.nroReclamoReq}
                            onChange={(e) =>
                                setRequeridoData((prevState) => ({
                                    ...prevState,
                                    nroReclamoReq: e.target.value,
                                }))} />
                    </div>
                    <div class="col">
                        <label class="mb-1 letraPlanillaExpediente">Nro de Reclamo Cia Requerido</label>
                        <input type="text" class="form-control" value={requeridoData.nroReclamoCia}
                            onChange={(e) =>
                                setRequeridoData((prevState) => ({
                                    ...prevState,
                                    nroReclamoCia: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="d-flex justify-content-center mt-4 mb-3">
                    <button type="submit" className="btn-primary btn" disabled={!vencimiento ? true : false}>Actualizar</button>
                    <button className="btn btn-secondary btn-sm ms-5" onClick={setEditarFalse}>Cancelar</button>
                </div>
                {!vencimiento ? <div className="center"><p className="text-danger">Se necesita actualizar la fecha de vencimiento</p></div> : <div></div>}
                {carga ?
                    <div className="center">
                        <div class="spinner-border text-primary center" role="status">
                            <span class="visually-hidden center">Loading...</span>
                        </div>
                    </div> : <div></div>}
            </form>
        </div>
    )
}