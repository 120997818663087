import './anclado.css'
import { useMediaQuery } from 'react-responsive'
export default function ComentarioAnclado({ expediente }) {
    const isMobile375 = useMediaQuery({ query: '(max-width: 375px)' });
    const isMobile412 = useMediaQuery({ query: '(min-width: 376px) and (max-width: 412px)' });
    const isMobile480 = useMediaQuery({ query: '(min-width: 413px) and (max-width: 480px)' });
    const isTablet768 = useMediaQuery({ query: '(min-width: 480px) and (max-width: 768px)' });
    return (
        <>


            {
                isMobile375 ?
                    <section className={expediente.anclado ? 'ancladoContainer375' : ''} style={{ marginTop: '2vh', height: '10vh' }}>
                        <p className='textoMensajeAnclado375'>{expediente.anclado}</p>
                    </section>
                    :
                    (isMobile412 ?
                        <section className={expediente.anclado ? 'ancladoContainer375' : ''} style={{ marginTop: '2vh', height: '10vh' }}>
                            <p className='textoMensajeAnclado375'>{expediente.anclado}</p>
                        </section>
                        :
                        (isMobile480 ?
                            <section className={expediente.anclado ? 'ancladoContainer375' : ''} style={{ marginTop: '2vh', height: '10vh' }}>
                                <p className='textoMensajeAnclado375'>{expediente.anclado}</p>
                            </section>
                            :
                            (isTablet768 ?
                                <section className={expediente.anclado ? 'ancladoContainer768' : ''} style={{ marginTop: '2vh', height: '10vh' }}>
                                    <p className='textoMensajeAnclado768'>{expediente.anclado}</p>
                                </section>
                                :
                                <section className='ancladoContainer'>
                                    <p className='textoMensajeAnclado'>{expediente.anclado}</p>
                                    <div className="d-flex justify-content-end">
                                        <p className="textoMensajeAnclado">{`${expediente.ancladoUser} - ${expediente.ancladoFecha}`}</p>
                                    </div>
                                </section>
                            )
                        ))
            }
        </>
    )
}