import axios from "axios";
import { useState, useEffect } from "react";
import "./index.css";
import ExpedienteTicket from "../components/Ticket/ExpedienteTicket";
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { checkContext } from "../context/Check";
import { useContext } from "react";
import { MultiSelect } from "react-multi-select-component";
import ReactPaginate from 'react-paginate';
import Select from 'react-select'
import './paginate.css'
import Excel from "./Excel";
import iconoFiltrar from "../images/iconoFiltrar.png";
import iconoGlobal from "../images/iconoGlobal.png";
export default function Novedades() {
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(['user'], ['expNovedades']);
    const [expedientes, setExpedientes] = useState([]);
    const [productores, setProductores] = useState([]);
    const [companias, setCompanias] = useState([]);
    const [asignado, setAsignado] = useState([]);
    const [preCarga, setPreCarga] = useState(false)
    useEffect(() => {
        if (cookies.user.nombre === 'FRANCO') {
            axios.get("https://api.tuveunchoque.com.ar/api/gerencia/expedientes/ticketsFranco").then((res) => {
                setExpedientes(res.data.reverse());
                setPreCarga(true)
            });
            axios.get("https://api.tuveunchoque.com.ar/api/gerencia/expedientes/tickets").then((res) => {
                setExpedientes(res.data.reverse());
            });
            axios.get("https://api.tuveunchoque.com.ar/api/nuevosUsuarios/pasParaFiltros").then((res) => {
                setProductores(res.data);
            });
            axios.get("https://api.tuveunchoque.com.ar/api/gerencia/companias").then((res) => {
                setCompanias(res.data);
            });
            axios.get("https://api.tuveunchoque.com.ar/api/gerencia/asignado").then((res) => {
                setAsignado(res.data);
            });
        } else {
            axios.get("https://api.tuveunchoque.com.ar/api/gerencia/expedientes/tickets").then((res) => {
                setExpedientes(res.data.reverse());
                setPreCarga(true)
            });
            axios.get("https://api.tuveunchoque.com.ar/api/nuevosUsuarios/pasParaFiltros").then((res) => {
                setProductores(res.data);
            });
            axios.get("https://api.tuveunchoque.com.ar/api/gerencia/companias").then((res) => {
                setCompanias(res.data);
            });
            axios.get("https://api.tuveunchoque.com.ar/api/gerencia/asignado").then((res) => {
                setAsignado(res.data);
            });
        }
    }, []);
    const prioridadInput = [{ label: "ALTA", value: "ALTA" }, { label: "MEDIA", value: "MEDIA" }, { label: "BAJA", value: "BAJA" }]
    const estadoInput = [
        { label: "RECEPCIONADO", value: "RECEPCIONADO" },
        { label: "SIN DENUNCIA", value: "SIN DENUNCIA" },
        { label: "ARMADO PRELIMINAR", value: "ARMADO PRELIMINAR" },
        { label: "RECLAMO PRESENTADO", value: "RECLAMO PRESENTADO" },
        { label: "ACEPTACION", value: "ACEPTACION" },
        { label: "EN PROCESO DE PAGO", value: "EN PROCESO DE PAGO" },
        { label: "COBRADO", value: "COBRADO" },
        { label: "MEDIACION", value: "MEDIACION" },
        { label: "CERRADO", value: "CERRADO" },
    ];
    const nivelInput = [{ label: "0", value: "0" }, { label: "1", value: "1" }, { label: "2", value: "2" }, { label: "3", value: "3" }];
    const fechaInput = [{ label: "HOY", value: "HOY" }, { label: "MAÑANA", value: "MAÑANA" }, { label: "VENCIDOS", value: "VENCIDOS" }];
    const asignadoInput = [
        { label: "AGOSTINA", value: "AGOSTINA" },
        { label: "CATALINA", value: "CATALINA" },
        { label: "DANIELA", value: "DANIELA" },
        { label: "FRANCO", value: "FRANCO" },
        { label: "JUAN", value: "JUAN" },
        { label: "SOFIA", value: "SOFIA" },
        { label: "MICAELA", value: "MICAELA" },
        { label: 'SANDRA', value: 'SANDRA' },
        { label: "FEDERICO", value: "FEDERICO" },
        { label: "FACUNDO", value: 'FACUNDO' },
        { label: "LUCAS", value: 'LUCAS' },
        { label: "GONZALO", value: 'GONZALO' },
        { label: "", value: null },
    ];
    const compInput = companias.map((c) => {
        return { value: c.compania, label: c.compania }
    })
    const proInput = productores.map((p) => {
        return { value: p.nombre, label: p.nombre }
    })
    const [infoTicket, setInfoTicket, removeInfoTicket] = useCookies(['ticket']);
    const [info, setInfo] = useState({ info: infoTicket.ticket.info, pas: infoTicket.ticket.pas, comp: infoTicket.ticket.comp, estado: infoTicket.ticket.estado, nivel: infoTicket.ticket.nivel, fecha: infoTicket.ticket.fecha, asignado: infoTicket.ticket.asignado, prioridad: infoTicket.ticket.prioridad, plazo: infoTicket.ticket.plazo, expFrom: infoTicket.ticket.expFrom, expTo: infoTicket.ticket.expTo });
    const vencenHoy = expedientes?.filter(e => e.asignadoA === cookies.user?.nombre && (new Date(moment(e.fechaVencimiento, "DD/MM/YYYY").toDate()).toDateString() === new Date().toDateString())).length
    const vencenManiana = expedientes?.filter(e => e.asignadoA === cookies.user?.nombre && (new Date(moment(e.fechaVencimiento, "DD/MM/YYYY").toDate()).toLocaleDateString() === new Date(+new Date().setHours(0, 0, 0, 0) + 86400000).toLocaleDateString())).length
    const vencidos = expedientes?.filter(e => e.asignadoA === cookies.user?.nombre && (Date.parse(moment(e.fechaVencimiento, "DD/MM/YYYY").toDate()) < new Date().setHours(0, 0, 0, 0))).length
    let updatedList = expedientes
    const [plazo, setPlazo] = useState({ plazo: 5 })
    const [portal, setPortal] = useState(false)
    const [filtro, setFiltro] = useState(false)
    const conFiltro = () => {
        if (filtro === true) {
            updatedList = updatedList.filter(e => e.nuevaAsignacion === 1)
        } else {
            updatedList = updatedList.filter(e => e.nuevaAsignacion === 0 && e.esNovedades === 1)
        }
        if (infoTicket.ticket.info) {
            updatedList = updatedList.filter((expediente) =>
                JSON.stringify(expediente.nro).includes(infoTicket.ticket.info) ||
                JSON.stringify(expediente.polizaRequerido).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').includes(infoTicket.ticket.info) ||
                JSON.stringify(expediente.nroReclamoCompaniaRequerido).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').includes(infoTicket.ticket.info) ||
                JSON.stringify(expediente.polizaRequerido).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').substring(-4).includes(infoTicket.ticket.info) ||
                JSON.stringify(expediente.nroReclamoCompaniaRequerido).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').substring(-4).includes(infoTicket.ticket.info) ||
                JSON.stringify(expediente.dominioCliente).toLowerCase().includes(JSON.stringify(infoTicket.ticket.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')) ||
                JSON.stringify(expediente.productorAsociado).toLowerCase().includes(JSON.stringify(infoTicket.ticket.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')) ||
                JSON.stringify(expediente.nombre).toLowerCase().includes(JSON.stringify(infoTicket.ticket.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')) ||
                JSON.stringify(expediente.compania).toLowerCase().includes(JSON.stringify(infoTicket.ticket.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')) ||
                JSON.stringify(expediente.patenteRequerido).toLowerCase().includes(JSON.stringify(infoTicket.ticket.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')) ||
                JSON.stringify(expediente.nroSeguimientoCompaniaRequerido).toLowerCase().includes(JSON.stringify(infoTicket.ticket.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''))
            );
        }
        if (infoTicket.ticket.prioridad.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return infoTicket.ticket.prioridad.some((ele) => {
                    return ele.value === expediente.prioridad
                })
            })
        }
        if (infoTicket.ticket.pas.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return infoTicket.ticket.pas.some((ele) => {
                    return ele.value === expediente.productorAsociado
                })
            })
        }
        if (infoTicket.ticket.comp.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return infoTicket.ticket.comp.some((ele) => {
                    return ele.value === expediente.compania
                })
            })
        }
        if (infoTicket.ticket.estado.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return infoTicket.ticket.estado.some((ele) => {
                    return ele.value === expediente.estado
                })
            })
        }
        if (infoTicket.ticket.expFrom !== null) {
            updatedList = updatedList.filter((expediente) => {
                return expediente.nro >= infoTicket.ticket.expFrom
            })
        }
        if (infoTicket.ticket.expTo !== null) {
            updatedList = updatedList.filter((expediente) => {
                return expediente.nro <= infoTicket.ticket.expTo
            })
        }
        if (infoTicket.ticket.asignado.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return infoTicket.ticket.asignado.some((ele) => {
                    return ele.value === expediente.asignadoA
                })
            })
        } else if (infoTicket.ticket.asignado.length === 0 && !infoTicket.ticket.info) {
            updatedList = updatedList.filter(e => e.asignadoA === cookies.user.nombre)
        }
        if (infoTicket.ticket.nivel.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return infoTicket.ticket.nivel.some((ele) => {
                    return ele.value == expediente.nivel
                })
            })
        }
        //if (plazo.plazo > 0) {
        //updatedList = updatedList.filter(e => Date.parse(new Date(moment(e.ultimaMod, "DD/MM/YYYY").toDate())) > Date.parse(new Date(moment().subtract((Number(plazo.plazo) + 1), 'days'))))
        //}
        if (portal && !infoTicket.ticket.info) {
            updatedList = updatedList.filter(e => e.estado !== 'COBRADO' && e.estado !== "CERRADO")
        }
        if (!portal && !infoTicket.ticket.info) {
            updatedList = updatedList.filter(e => e.estado !== 'COBRADO' && e.estado !== "CERRADO")
        }
        if (infoTicket.ticket.fecha.some((ele) => {
            return ele.value === 'HOY'
        })) { updatedList = updatedList.filter(e => new Date(moment(e.fechaVencimiento, "DD/MM/YYYY").toDate()).toDateString() === new Date().toDateString()) }
        if (infoTicket.ticket.fecha.some((ele) => {
            return ele.value === 'MAÑANA'
        })) { updatedList = updatedList.filter(e => new Date(moment(e.fechaVencimiento, "DD/MM/YYYY").toDate()).toLocaleDateString() === new Date(+new Date().setHours(0, 0, 0, 0) + 86400000).toLocaleDateString()) }
        if (infoTicket.ticket.fecha.some((ele) => {
            return ele.value === 'VENCIDOS'
        })) { updatedList = updatedList.filter(e => e.estado !== 'COBRADO' && e.estado !== 'CERRADO' && Number(Date.parse(moment(e.fechaVencimiento, "DD/MM/YYYY").toDate())) <= Number(Date.parse(moment().subtract(1, 'days').toDate()))) }
    }
    conFiltro()
    const cook = () => {
        if (!cookies.user) {
            navigate('/login')
        } else if (cookies.user.cargo === 'PAS') {
            navigate('/expedientes')
        }
    }
    cook()
    const vaciar = async () => {
        setInfo({ info: '', pas: [], comp: [], estado: [], nivel: [], fecha: [], asignado: [], plazo: 5, prioridad: [], expFrom: null, expTo: null });
        setCookie('ticket', JSON.stringify(info), { path: '/' })
    }
    const { checka, setChecka } = useContext(checkContext)
    function handleChange(e) {
        setChecka(e.target.checked);
        if (!checka) {
            for (const exp of updatedList) {
                const a = cookies.expNovedades.includes(exp.nro)
                if (!a) { cookies.expNovedades.push(exp.nro) }
            }
        } else if (checka) {
            for (const exp of updatedList) {
                const a = cookies.expNovedades.includes(exp.nro)
                if (a) { cookies.expNovedades.splice(a, 1) }
            }
            cookies.expNovedades.shift()
        }
        setCookie('expNovedades', cookies.expNovedades)
    }
    const filtrar = async () => {
        setCookie('ticket', JSON.stringify(info), { path: '/' })
    }
    let itemsPerPage = 10
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    const pageCount = Math.ceil(updatedList.length / itemsPerPage);  // contador de exp para hacer el paginador
    const [currentPage, setCurrentPage] = useState(0);
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % updatedList.length;
        setItemOffset(newOffset);
        setCurrentPage(event.selected);
    }
    useEffect(() => {
        setItemOffset(0);
        setCurrentPage(0);
    }, [updatedList.filter(e => e.esNovedades === 1).length]);
    const ordenarInput = [{ label: '', value: 0 }, { label: 'NRO ↑', value: 'NRO ↑' }, { label: 'NRO ↓', value: 'NRO ↓' }, { label: 'PATENTE ↑', value: 'PATENTE ↑' }, { label: 'PATENTE ↓ZA', value: 'PATENTE ↓ZA' }, { label: 'PAS ↑', value: 'PAS ↑' }, { label: 'PAS ↓ZA', value: 'PAS ↓ZA' }, { label: 'COMPAÑIA ↑', value: 'COMPAÑIA ↑' }, { label: 'COMPAÑIA ↓ZA', value: 'COMPAÑIA ↓ZA' }, { label: 'CLIENTE ↑', value: 'CLIENTE ↑' }, { label: 'CLIENTE ↓ZA', value: 'CLIENTE ↓ZA' }, { label: 'STRO ↑', value: 'STRO ↑' }, { label: 'STRO ↓ZA', value: 'STRO ↓ZA' }, { label: 'VTO ↑', value: 'VTO ↑' }, { label: 'VTO ↓ZA', value: 'VTO ↓ZA' }, { label: 'ULT MOD ↑', value: 'ULT MOD ↑' }, { label: 'ULT MOD ↓ZA', value: 'ULT MOD ↓ZA' }]
    const [sortA, setSortA] = useState({ sort: 'ULT MOD ↑' })
    const sort = () => {
        if (sortA.sort === 'NRO ↑') {
            updatedList.sort(function (a, b) {
                return b.nro - a.nro;
            })
        }
        if (sortA.sort === 'NRO ↓') {
            updatedList.sort(function (a, b) {
                return a.nro - b.nro;
            })
        }
        if (sortA.sort === 'PATENTE ↑') {
            updatedList.sort(function (a, b) {
                { if (a.dominioCliente && b.dominioCliente) return JSON.stringify(a.dominioCliente).localeCompare(JSON.stringify(b.dominioCliente)) }
            })
        }
        if (sortA.sort === 'PATENTE ↓ZA') {
            updatedList.sort(function (a, b) {
                { if (a.dominioCliente && b.dominioCliente) return JSON.stringify(b.dominioCliente).localeCompare(JSON.stringify(a.dominioCliente)) }
            })
        }
        if (sortA.sort === 'PAS ↑') {
            updatedList.sort(function (a, b) {
                const nameA = JSON.stringify(a.productorAsociado).toLowerCase()
                const nameB = JSON.stringify(b.productorAsociado).toLowerCase()
                if (nameA < nameB)
                    return -1;
                if (nameA > nameB)
                    return 1;
            })
        }
        if (sortA.sort === 'PAS ↓ZA') {
            updatedList.sort(function (a, b) {
                const nameA = JSON.stringify(a.productorAsociado).toLowerCase()
                const nameB = JSON.stringify(b.productorAsociado).toLowerCase()
                if (nameB < nameA)
                    return -1;
                if (nameB > nameA)
                    return 1;
            })
        }
        if (sortA.sort === 'COMPAÑIA ↑') {
            updatedList.sort(function (a, b) {
                const nameA = JSON.stringify(a.compania).toLowerCase()
                const nameB = JSON.stringify(b.compania).toLowerCase()
                if (nameA < nameB && a.compania)
                    return -1;
                if (nameA > nameB && a.compania)
                    return 1;
            })
        }
        if (sortA.sort === 'COMPAÑIA ↓ZA') {
            updatedList.sort(function (a, b) {
                const nameA = JSON.stringify(a.compania).toLowerCase()
                const nameB = JSON.stringify(b.compania).toLowerCase()
                if (nameB < nameA && a.compania)
                    return -1;
                if (nameB > nameA && b.compania)
                    return 1;
            })
        }
        if (sortA.sort === 'CLIENTE ↑') {
            updatedList.sort(function (a, b) {
                const nameA = JSON.stringify(a.nombre).toLowerCase()
                const nameB = JSON.stringify(b.nombre).toLowerCase()
                if (nameA < nameB && a.nombre)
                    return -1;
                if (nameA > nameB && a.nombre)
                    return 1;
            })
        }
        if (sortA.sort === 'CLIENTE ↓ZA') {
            updatedList.sort(function (a, b) {
                const nameA = JSON.stringify(a.nombre).toLowerCase()
                const nameB = JSON.stringify(b.nombre).toLowerCase()
                if (nameB < nameA && a.nombre)
                    return -1;
                if (nameB > nameA && b.nombre)
                    return 1;
            })
        }
        if (sortA.sort === 'VTO ↑') {
            updatedList.sort(function (a, b) {
                return new Date(b.fechaVencimiento) - new Date(a.fechaVencimiento);
            })
        }
        if (sortA.sort === 'VTO ↓ZA') {
            updatedList.sort(function (a, b) {
                return new Date(a.fechaVencimiento) - new Date(b.fechaVencimiento);
            })
        }
        if (sortA.sort === 'VTO ↑') {
            updatedList.sort(function (a, b) {
                return new Date((moment(b.fechaVencimiento, "DD/MM/YYYY").toDate())) - new Date((moment(a.fechaVencimiento, "DD/MM/YYYY").toDate()));
            })
        }
        if (sortA.sort === 'VTO ↓ZA') {
            updatedList.sort(function (a, b) {
                return new Date((moment(a.fechaVencimiento, "DD/MM/YYYY").toDate())) - new Date((moment(b.fechaVencimiento, "DD/MM/YYYY").toDate()));
            })
        }
        if (sortA.sort === 'ULT MOD ↑') {
            updatedList.sort(function (a, b) {
                return new Date(b.ultimaMod) - new Date(a.ultimaMod);
            })
        }
        if (sortA.sort === 'ULT MOD ↓ZA') {
            updatedList.sort(function (a, b) {
                return new Date(a.ultimaMod) - new Date(b.ultimaMod);
            })
        }
        if (sortA.sort === 'ULT MOD ↑') {
            updatedList.sort(function (a, b) {
                return new Date((moment(b.ultimaMod, "DD/MM/YYYY").toDate())) - new Date((moment(a.ultimaMod, "DD/MM/YYYY").toDate()));
            })
        }
        if (sortA.sort === 'ULT MOD ↓ZA') {
            updatedList.sort(function (a, b) {
                return new Date((moment(a.ultimaMod, "DD/MM/YYYY").toDate())) - new Date((moment(b.ultimaMod, "DD/MM/YYYY").toDate()));
            })
        }
        if (sortA.sort === 'STRO ↑') {
            updatedList.sort(function (a, b) {
                return new Date(b.fechaStro) - new Date(a.fechaStro);
            })
        }
        if (sortA.stro === 'STRO ↓ZA') {
            updatedList.sort(function (a, b) {
                return new Date(a.fechaStro) - new Date(b.fechaStro);
            })
        }
        if (sortA.sort === 0) {
            updatedList.sort((a, b) => b)
        }
    }
    sort()
    const asa = { paddingLeft: '56px' }
    const portala = { background: '#F6F9FB 0% 0% no-repeat padding-box', height: '956px', boxShadow: '-3px 3px 6px #0000001A', borderRadius: '22px 0px 0px 22px' }
    const img = { heigth: '44px', width: '44px', display: 'block' }
    const btn = { border: 'none', background: 'transparent', fontSize: '12px', color: '#0089FF', fontWeight: '100' }
    const title = { fontSize: '30px', fontWeight: 'bold', color: '#0F89E7' }
    const subtitle = { fontSize: '23px', fontWeight: '300', color: '#0E8497', fontStyle: 'italic' }
    const title2 = { fontSize: '22px', fontWeight: 'bold', color: '#0F89E7' }
    const subtitle2 = { fontSize: '18px', fontWeight: '300', color: '#0E8497', fontStyle: 'italic' }
    function compararPrioridad(a, b) {
        if (a.prioridad === "ALTA" && b.prioridad !== "ALTA") {
            return -1; // a debe ir antes que b
        } else if (a.prioridad !== "ALTA" && b.prioridad === "ALTA") {
            return 1; // b debe ir antes que a
        } else {
            return 0; // no se cambian de posición
        }
    }
    return (
        <>
            {window.innerWidth > 1920 ?
                <main className="mainTicket pb-5">
                    <div className="d-flex justify-content-between ps-5 pe-3">
                        <div className="d-flex">
                            <h2 style={title}>NOVEDADES</h2>
                            <h3 style={subtitle} className="center">- ORDEN: ÚLTIMA MODIFICACIÓN EN LOS ÚLTIMOS 5 DIAS</h3>
                        </div>
                        <div className="center">
                            {!filtro ? <button className="btn btn-primary" onClick={() => setFiltro(true)}>FILTRAR NUEVOS ASIGNADOS</button> : <button className="btn btn-danger" onClick={() => setFiltro(false)}>QUITAR NUEVOS ASIGNADOS</button>}
                        </div>
                    </div>
                    {cookies.user ? <section className="row sectionExpedientes2 letra">
                        <div class="form-check" style={asa}>
                            <input class="form-check-input" type="checkbox" value={checka} onChange={handleChange} />
                            <p className="m-0">Seleccionar todos</p>
                        </div>
                        {cookies.user?.cargo !== "PAS" ?
                            <div className="col-10">
                                {preCarga === true ?
                                    (updatedList.length > 0 ?
                                        <div>
                                            {(infoTicket.ticket.pas.length === 0 &&
                                                infoTicket.ticket.asignado.length === 0 &&
                                                infoTicket.ticket.comp.length === 0 &&
                                                infoTicket.ticket.estado.length === 0 &&
                                                infoTicket.ticket.fecha.length === 0 &&
                                                !infoTicket.ticket.info &&
                                                infoTicket.ticket.nivel.length === 0 &&
                                                infoTicket.ticket.prioridad.length === 0) ?
                                                updatedList.sort(compararPrioridad).slice(itemOffset, Number(itemOffset + 10)).map((expediente) => {
                                                    return <ExpedienteTicket expediente={expediente} key={expediente.id} />;
                                                }) : updatedList.slice(itemOffset, Number(itemOffset + 10)).map((expediente) => {
                                                    return <ExpedienteTicket expediente={expediente} key={expediente.id} />;
                                                })}
                                        </div> :
                                        <div className="center mt-5"><h5 className="center mt-5">Sin Resultados</h5></div>
                                    ) :
                                    <div className="center mt-5 pt-5">
                                        <div class="spinner-border text-primary center" role="status"></div>
                                    </div>}
                                <div className="center mt-5">
                                    <Excel data={updatedList} />
                                </div>
                            </div> : <div></div>}
                        {cookies.user?.cargo !== "PAS" ? <div className="col-2">
                            <div style={portala}>
                                <div className="">
                                    <div className="d-flex pt-3 ms-3">
                                        <div className="">
                                            <button onClick={(e) => filtrar()} style={btn}><img src={iconoFiltrar} alt="filtrar" style={img} />FILTRAR</button>
                                        </div>
                                        <div className="">
                                            <button onClick={(e) => vaciar()} style={btn}><img src={iconoGlobal} alt="vaciar" style={img}></img>VACIAR</button>
                                        </div>
                                    </div>
                                </div>
                                <form className="me-3 ms-3 mt-3" onKeyDown={(e) => { if (e.key === 'Enter') { filtrar() } }}>
                                    <div className="">
                                        <input placeholder={infoTicket.ticket.info ? infoTicket.ticket.info : 'BUSQUEDA GENERAL'} value={info.info}
                                            type="text" class="inputsDelFiltro"
                                            onChange={(e) =>
                                                setInfo((prevState) => ({
                                                    ...prevState,
                                                    info: e.target.value,
                                                }))
                                            }
                                        />
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-6">
                                            <h6 className='subtituloDelFiltro'>Asignado</h6>
                                            <MultiSelect
                                                className=""
                                                options={asignadoInput.sort((a, b) => a.label.localeCompare(b.label))}
                                                value={info.asignado}
                                                onChange={(e) => {
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        asignado: e
                                                    }))
                                                }}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <h6 className='subtituloDelFiltro'>Prioridad</h6>
                                            <MultiSelect
                                                className=""
                                                options={prioridadInput}
                                                value={info.prioridad}
                                                onChange={(e) => {
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        prioridad: e
                                                    }))
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-6">
                                            <h6 className='subtituloDelFiltro'>Estado</h6>
                                            <MultiSelect
                                                className=""
                                                options={estadoInput}
                                                value={info.estado}
                                                onChange={(e) => {
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        estado: e
                                                    }))
                                                }}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <h6 className='subtituloDelFiltro'>Nivel</h6>
                                            <MultiSelect
                                                className=""
                                                options={nivelInput}
                                                value={info.nivel}
                                                onChange={(e) => {
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        nivel: e
                                                    }))
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-6">
                                            <h6 className='subtituloDelFiltro'>Compañia</h6>
                                            <MultiSelect
                                                className=""
                                                options={compInput.sort((a, b) => a.label.localeCompare(b.label))}
                                                value={info.comp}
                                                onChange={(e) => {
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        comp: e
                                                    }))
                                                }}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <h6 className='subtituloDelFiltro'>Productor</h6>
                                            <MultiSelect
                                                className=""
                                                options={proInput.sort((a, b) => a.label.localeCompare(b.label))}
                                                value={info.pas}
                                                onChange={(e) => {
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        pas: e
                                                    }))
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-6">
                                            <h6 className='subtituloDelFiltro'>Vencimiento</h6>
                                            <MultiSelect
                                                className=""
                                                options={fechaInput}
                                                value={info.fecha}
                                                onChange={(e) => {
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        fecha: e
                                                    }))
                                                }}
                                            />
                                        </div>
                                        <div className="col-6">

                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <h6 className="subtituloDelFiltro m-0">Nro</h6>
                                        <div className="row">
                                            <div className="col-6 pe-1">
                                                <input type="number" class="inputsDelFiltro" value={info.expFrom} placeholder={infoTicket.ticket.expFrom}
                                                    onChange={(e) =>
                                                        setInfo((prevState) => ({
                                                            ...prevState,
                                                            expFrom: e.target.value,
                                                        }))} />
                                            </div>
                                            <div className="col-6 ps-1">
                                                <input type="number" class="inputsDelFiltro" value={info.expTo} placeholder={infoTicket.ticket.expTo}
                                                    onChange={(e) =>
                                                        setInfo((prevState) => ({
                                                            ...prevState,
                                                            expTo: e.target.value,
                                                        }))} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col">
                                            <h6 className='subtituloDelFiltro'>Ordenar Por</h6>
                                            <Select
                                                placeholder={sortA.sort ? sortA.sort : 'Ordenar Por'}
                                                className="react-select-container"
                                                options={ordenarInput}
                                                onChange={(newValue) => setSortA((prevState) => ({
                                                    sort: newValue.value,
                                                }))}
                                            />
                                        </div>
                                    </div>
                                </form>
                                <div className="mt-4 text-secondary">
                                    <h6 className={portal ? 'text-white center mb-3' : 'center mb-3'}>VENCIMIENTO EXP: {cookies?.user.nombre}</h6>
                                    <h6 className={portal ? 'text-white center mb-3' : 'center mb-3'}>HOY: {vencenHoy} MAÑANA: {vencenManiana} VENCIDOS: {vencidos}</h6>
                                </div>
                                <div className="mt-4 center">
                                    <ReactPaginate
                                        className='center p-0'
                                        forcePage={currentPage}
                                        activeClassName={'item active '}
                                        breakClassName={'item break-me '}
                                        breakLabel={'...'}
                                        marginPagesDisplayed={1}
                                        onPageChange={handlePageClick}
                                        pageClassName={'item pagination-page '}
                                        pageRangeDisplayed={5}
                                        pageCount={pageCount}
                                    />
                                </div>
                                <h6 className={portal === false ? 'center' : 'center text-white'}>Cantidad de expedientes: {updatedList.length}</h6>
                            </div>
                        </div> : <div></div>}
                    </section> : <h4 className="p-5">Se necesita iniciar sesion</h4>
                    }
                </main >
                :
                <main className="mainTicket pb-5">
                    <div className="d-flex justify-content-between ps-5 pe-3">
                        <div className="d-flex">
                            <h3 style={title2}>NOVEDADES </h3>
                            <h3 style={subtitle2} className="center">- ORDEN: ÚLTIMA MODIFICACIÓN EN LOS ÚLTIMOS 5 DIAS</h3>
                        </div>
                        <div className="center">
                            {!filtro ? <button className="btn btn-primary btn-sm" onClick={() => setFiltro(true)}>FILTRAR NUEVOS ASIGNADOS</button> : <button className="btn btn-danger btn-sm" onClick={() => setFiltro(false)}>QUITAR NUEVOS ASIGNADOS</button>}
                        </div>
                    </div>
                    {cookies.user ? <section className="row sectionExpedientes2 letra">
                        <div class="form-check " style={asa}>
                            <input class="form-check-input" type="checkbox" value={checka} onChange={handleChange} />
                            <p className="m-0">Seleccionar todos</p>
                        </div>
                        {cookies.user?.cargo !== "PAS" ?
                            <div className="col-9">
                                {preCarga === true ?
                                    (updatedList.length > 0 ?
                                        <div>
                                            {(infoTicket.ticket.pas.length === 0 &&
                                                infoTicket.ticket.asignado.length === 0 &&
                                                infoTicket.ticket.comp.length === 0 &&
                                                infoTicket.ticket.estado.length === 0 &&
                                                infoTicket.ticket.fecha.length === 0 &&
                                                !infoTicket.ticket.info &&
                                                infoTicket.ticket.nivel.length === 0 &&
                                                infoTicket.ticket.prioridad.length === 0) ?
                                                updatedList.sort(compararPrioridad).slice(itemOffset, Number(itemOffset + 10)).map((expediente) => {
                                                    return <ExpedienteTicket expediente={expediente} key={expediente.id} />;
                                                }) : updatedList.slice(itemOffset, Number(itemOffset + 10)).map((expediente) => {
                                                    return <ExpedienteTicket expediente={expediente} key={expediente.id} />;
                                                })}
                                        </div> :
                                        <div className="center mt-5"><h5 className="center mt-5">Sin Resultados</h5></div>
                                    ) :
                                    <div className="center mt-5 pt-5">
                                        <div class="spinner-border text-primary center" role="status"></div>
                                    </div>}
                                <div className="center mt-5">
                                    <Excel data={updatedList} />
                                </div>
                            </div> : <div></div>}
                        {cookies.user?.cargo !== "PAS" ? <div className="col-3">
                            <div style={portala}>
                                <div className="">
                                    <div className="d-flex pt-3 ms-3">
                                        <div className="">
                                            <button onClick={(e) => filtrar()} style={btn}><img src={iconoFiltrar} alt="filtrar" style={img} />FILTRAR</button>
                                        </div>
                                        <div className="">
                                            <button onClick={(e) => vaciar()} style={btn}><img src={iconoGlobal} alt="vaciar" style={img}></img>VACIAR</button>
                                        </div>
                                    </div>
                                </div>
                                <form className="me-3 ms-3 mt-3" onKeyDown={(e) => { if (e.key === 'Enter') { filtrar() } }}>
                                    <div className="">
                                        <input placeholder={infoTicket.ticket.info ? infoTicket.ticket.info : 'BUSQUEDA GENERAL'} value={info.info}
                                            type="text" class="inputsDelFiltro"
                                            onChange={(e) =>
                                                setInfo((prevState) => ({
                                                    ...prevState,
                                                    info: e.target.value,
                                                }))
                                            }
                                        />
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-6">
                                            <h6 className='subtituloDelFiltro'>Asignado</h6>
                                            <MultiSelect
                                                className=""
                                                options={asignadoInput.sort((a, b) => a.label.localeCompare(b.label))}
                                                value={info.asignado}
                                                onChange={(e) => {
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        asignado: e
                                                    }))
                                                }}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <h6 className='subtituloDelFiltro'>Prioridad</h6>
                                            <MultiSelect
                                                className=""
                                                options={prioridadInput}
                                                value={info.prioridad}
                                                onChange={(e) => {
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        prioridad: e
                                                    }))
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-6">
                                            <h6 className='subtituloDelFiltro'>Estado</h6>
                                            <MultiSelect
                                                className=""
                                                options={estadoInput}
                                                value={info.estado}
                                                onChange={(e) => {
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        estado: e
                                                    }))
                                                }}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <h6 className='subtituloDelFiltro'>Nivel</h6>
                                            <MultiSelect
                                                className=""
                                                options={nivelInput}
                                                value={info.nivel}
                                                onChange={(e) => {
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        nivel: e
                                                    }))
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-6">
                                            <h6 className='subtituloDelFiltro'>Compañia</h6>
                                            <MultiSelect
                                                className=""
                                                options={compInput.sort((a, b) => a.label.localeCompare(b.label))}
                                                value={info.comp}
                                                onChange={(e) => {
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        comp: e
                                                    }))
                                                }}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <h6 className='subtituloDelFiltro'>Productor</h6>
                                            <MultiSelect
                                                className=""
                                                options={proInput.sort((a, b) => a.label.localeCompare(b.label))}
                                                value={info.pas}
                                                onChange={(e) => {
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        pas: e
                                                    }))
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-6">
                                            <h6 className='subtituloDelFiltro'>Vencimiento</h6>
                                            <MultiSelect
                                                className=""
                                                options={fechaInput}
                                                value={info.fecha}
                                                onChange={(e) => {
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        fecha: e
                                                    }))
                                                }}
                                            />
                                        </div>
                                        <div className="col-6">

                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <h6 className="subtituloDelFiltro m-0">Nro</h6>
                                        <div className="row">
                                            <div className="col-6 pe-1">
                                                <input type="number" class="inputsDelFiltro" value={info.expFrom} placeholder={infoTicket.ticket.expFrom}
                                                    onChange={(e) =>
                                                        setInfo((prevState) => ({
                                                            ...prevState,
                                                            expFrom: e.target.value,
                                                        }))} />
                                            </div>
                                            <div className="col-6 ps-1">
                                                <input type="number" class="inputsDelFiltro" value={info.expTo} placeholder={infoTicket.ticket.expTo}
                                                    onChange={(e) =>
                                                        setInfo((prevState) => ({
                                                            ...prevState,
                                                            expTo: e.target.value,
                                                        }))} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col">
                                            <h6 className='subtituloDelFiltro'>Ordenar Por</h6>
                                            <Select
                                                placeholder={sortA.sort ? sortA.sort : 'Ordenar Por'}
                                                className="react-select-container"
                                                options={ordenarInput}
                                                onChange={(newValue) => setSortA((prevState) => ({
                                                    sort: newValue.value,
                                                }))}
                                            />
                                        </div>
                                    </div>
                                </form>
                                <div className="mt-4 text-secondary">
                                    <h6 className={portal ? 'text-white center mb-3' : 'center mb-3'}>VENCIMIENTO EXP: {cookies?.user.nombre}</h6>
                                    <h6 className={portal ? 'text-white center mb-3' : 'center mb-3'}>HOY: {vencenHoy} MAÑANA: {vencenManiana} VENCIDOS: {vencidos}</h6>
                                </div>
                                <div className="mt-4 center">
                                    <ReactPaginate
                                        className='center p-0'
                                        forcePage={currentPage}
                                        activeClassName={'item active '}
                                        breakClassName={'item break-me '}
                                        breakLabel={'...'}
                                        marginPagesDisplayed={1}
                                        onPageChange={handlePageClick}
                                        pageClassName={'item pagination-page '}
                                        pageRangeDisplayed={5}
                                        pageCount={pageCount}
                                    />
                                </div>
                                <h6 className={portal === false ? 'center' : 'center text-white'}>Cantidad de expedientes: {updatedList.length}</h6>
                            </div>
                        </div> : <div></div>}
                    </section> : <h4 className="p-5">Se necesita iniciar sesion</h4>
                    }
                </main >}
        </>
    );
}

// FILTRO DE PLAZO TIEMPO LINEA 574
//<h6 className='subtituloDelFiltro'>Plazo de tiempo</h6>
//<input type="number" className="inputsDelFiltro" placeholder={plazo.plazo} value={plazo.plazo} onChange={(e) =>setPlazo(({plazo: e.target.value,}))} />


// FILTRO PLAZO TIEMPO LINEA 810
//<h6 className='subtituloDelFiltro'>Plazo de tiempo</h6>
//<input type="number" className="inputsDelFiltro" placeholder={plazo.plazo} value={plazo.plazo} onChange={(e) =>setPlazo(({plazo: e.target.value,}))} />