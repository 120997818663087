import { Link } from "react-router-dom";
import "./expedienteTicket.css";
import NivelTicket from "./NivelTicket";
import EstadoTicket from "./EstadoTicket";
import fresh from "../../images/fresh.png";
import callbell from "../../images/callbell.jpeg";
import callbellReq from "../../images/calbellReq.jpeg";
import sharepoint from "../../images/sharepoint.jpeg";
import sharepointRojo from "../../images/shareRojo.png";
import { useCallback, useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { checkContext } from "../../context/Check";
import esrever from "esrever";
import axios from 'axios'
export default function ExpedienteTicket({expediente}) {
    const logo = {
        height: "27px",
        width: "27px",
        background: "transparent 0% 0% no-repeat padding-box",
    };
    const logo2 = {
        height: "27px",
        width: "27px",
        background: "transparent 0% 0% no-repeat padding-box",
    };
    const logo1366 = {
        height: "20px",
        width: "20px",
        background: "transparent 0% 0% no-repeat padding-box",
    };
    const logo21366 = {
        height: "20px",
        width: "20px",
        background: "transparent 0% 0% no-repeat padding-box",
    };
    const link = { textDecoration: "none" };
    const [check, setChecked] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(["exp"], ['expNovedades']);
    const { checka, setChecka } = useContext(checkContext);
    function handleChange(e) {
        setChecked(e.target.checked);
        if (!check) {
            const a = cookies.expNovedades.includes(expediente.nro);
            if (!a) {
                cookies.expNovedades.push(expediente.nro);
            }
        } else if (check) {
            const a = cookies.expNovedades.indexOf(expediente.nro);
            const b = cookies.expNovedades.includes(expediente.nro);
            if (b) {
                cookies.expNovedades.splice(a, 1);
            }
        }
        setCookie("expNovedades", cookies.expNovedades);
    }
    const a = () => {
        setInterval(() => {
            if (cookies.expNovedades.includes(expediente.nro)) {
                setChecked(true);
            } else {
                setChecked(false);
            }
        }, 100);
    };
    a();
    const b = esrever.reverse(expediente.ultimaMod ? expediente.ultimaMod : "").slice(3);
    const c = esrever.reverse(b);
    const btn = { border: "none", background: "none" };
    const trash = { fontSize: "18px" };
    const trash1366 = { fontSize: "14px" };
    const eliminarTicket = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/gerencia/expedientes/updateVisibleTicket`,
            {
                id: expediente.id,
            }
        ).then((response) => {
            if (response) {
                setTimeout(() => {
                    window.location.reload()
                }, 0);
            }
        })
    }, [expediente]);
    const pasCargo = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(`https://api.tuveunchoque.com.ar/api/mail/ampliacionCargaPas`, {
            id: expediente.id,
            carga: 0
        }).then((response) => {
            if (response) {
                setTimeout(async () => {
                    window.location.reload()
                }, 200);
            }
        })
    }, [expediente])
    const hora = localStorage.getItem('hora');
    const [time, setTime] = useState(new Date());
    useEffect(() => {
        const timerId = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => clearInterval(timerId);
    }, []);
    const formatTime = (date) => {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };
    const getTimeDifferenceInSeconds = (timeString1, timeString2) => {
        const [hours1, minutes1, seconds1] = timeString1.split(':').map(Number);
        const [hours2, minutes2, seconds2] = timeString2.split(':').map(Number);
        const date1 = new Date();
        date1.setHours(hours1, minutes1, seconds1, 0);
        const date2 = new Date();
        date2.setHours(hours2, minutes2, seconds2, 0);
        const differenceInMilliseconds = Math.abs(date1 - date2);
        return Math.floor(differenceInMilliseconds / 1000);
    };
    const registro = async (e) => {
        await axios.post('https://api.tuveunchoque.com.ar/api/registroDeUso/update', { user: cookies.user.nombre, segundos: getTimeDifferenceInSeconds(formatTime(time), hora) })
        axios.post(`https://api.tuveunchoque.com.ar/api/gerencia/expedientes/quitarAsignado`,
            {
                id: expediente.id,
                bolean: 0,
            }
        )
        setCookie("expActual", expediente.nro);
        localStorage.setItem('hora', formatTime(time))
        window.location.reload()
    }
    return (
        <>
            {window.innerWidth > 1920 ?
                <div className="containerExpedienteTicket letra">
                    <div className="row">
                        <div className="col mt-2">
                            <div className="d-flex">
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        onChange={handleChange}
                                        checked={cookies.expNovedades.includes(expediente.nro)}
                                    />
                                </div>
                                <Link onClick={(e) => registro(e)} to={`/expediente/${expediente.nro}`} style={link} className="m-0 p-0">
                                    {expediente.prioridad === 'ALTA' ? <h5 class="nro m-0 text-nowrap text-danger">{`Exp ${expediente.nro} -\xa0`}</h5> : <h5 class="nro m-0 text-nowrap">{`Exp ${expediente.nro} -\xa0`}</h5>}
                                </Link>
                                <h6 className="subtitle m-0 p-0">
                                    {expediente.nombre} - {expediente.compania}
                                </h6>
                            </div>
                            <p className="texto mt-1">{`Asignado Por: ${expediente.asignadoPor} - ${expediente.fechaDeAsignado} - VENC: ${expediente.fechaVencimiento?.split("-").reverse().join("-")}`}</p>
                        </div>
                        {expediente.anclado ? (
                            <div className="col">
                                <p className="m-0 p-0 ancladoUserTicket">
                                    {expediente.ancladoUser} escribio:{" "}
                                </p>
                                <p className="ancladoTicket p-0 m-0">
                                    {expediente.anclado}
                                </p>
                            </div>
                        ) : (
                            <div className="col"></div>
                        )}
                        <div className="col mt-2">
                            <div className="d-flex ms-5">
                                {expediente.ticket ? (
                                    <div className="me-3 center">
                                        <Link
                                            to={`https://estudiopyp.freshdesk.com/a/tickets/${expediente.ticket}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img src={fresh} alt="" style={logo} />
                                        </Link>
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                                {expediente.callbell ? (
                                    <div className="me-3 center">
                                        <Link
                                            to={`https://dash.callbell.eu/chat/${expediente.callbell}?filter=all`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img src={callbell} alt="" style={logo2} />
                                        </Link>
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                                {expediente.callbellRequerido ? (
                                    <div className="me-3 center">
                                        <Link
                                            to={`https://dash.callbell.eu/chat/${expediente.callbellRequerido}?filter=all`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img src={callbellReq} alt="" style={logo2} />
                                        </Link>
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                                {expediente.Sharepoint ? (
                                    <div className="me-3 center">
                                        <Link
                                            to={`${expediente.sharepoint}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img src={sharepoint} alt="" style={logo2} />
                                        </Link>
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                                {expediente.pasCargoDocumentacion ? (
                                    <div className="me-3 center">
                                        <button style={btn} onClick={pasCargo}>
                                            <img src={sharepointRojo} alt="" style={logo2} />
                                        </button>
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                                <div className="me-3 center">
                                    <NivelTicket
                                        nivel={expediente.nivel}
                                        key={expediente.id}
                                    />
                                </div>
                                <div className="me-1 center">
                                    <EstadoTicket
                                        estado={expediente.estado}
                                        key={expediente.id}
                                    />
                                </div>
                                <div className="center">
                                    <button style={btn} onClick={eliminarTicket}>
                                        <i class="bi bi-trash text-secondary" style={trash}></i>
                                    </button>
                                </div>
                                {expediente.estadoAtras === 1 ? <div className="ms-1 center">
                                    <div style={btn} id="botonWarning">
                                        <i class="bi bi-exclamation-circle-fill text-warning" style={trash}></i>
                                    </div>
                                </div> : <div></div>}
                            </div>
                            <div className=" d-flex ms-5">
                                <p className="ultMod">Ultima Modificación</p>
                                <p className="hora">{c}</p>
                                <p className="center ms-1">- {expediente.modificadoPor}</p>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="containerExpedienteTicket letra">
                    <div className="row">
                        <div className="col mt-2">
                            <div className="d-flex">
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        onChange={handleChange}
                                        checked={cookies.expNovedades.includes(expediente.nro)}
                                    />
                                </div>
                                <div className="center">
                                    <Link onClick={(e) => registro()} to={`/expediente/${expediente.nro}`} style={link} className="m-0 p-0">
                                        {expediente.prioridad === 'ALTA' ? <h5 class="nro1366 text-danger m-0 text-nowrap">{`Exp ${expediente.nro} -\xa0`}</h5> : <h5 class="nro1366 m-0 text-nowrap">{`Exp ${expediente.nro} -\xa0`}</h5>}
                                    </Link>
                                    <h6 className="subtitle1366 center m-0 p-0 text-nowrap">
                                        {expediente.nombre} - {expediente.compania}
                                    </h6>
                                </div>
                            </div>
                            <p className="texto1366 mt-1 text-nowrap">{`Asignado Por: ${expediente.asignadoPor} - ${expediente.fechaDeAsignado} - VENC: ${expediente.fechaVencimiento?.split("-").reverse().join("-")}`}</p>
                        </div>
                        {expediente.anclado ? (
                            <div className="col mt-2">
                                <p className="m-0 p-0  ancladoUserTicket1366">
                                    {expediente.ancladoUser} escribio:{" "}
                                </p>
                                <p className="ancladoTicket1366 p-0 m-0">
                                    {expediente.anclado}
                                </p>
                            </div>
                        ) : (
                            <div className="col"></div>
                        )}
                        <div className="col mt-2">
                            <div className="d-flex ms-5">
                                {expediente.ticket ? (
                                    <div className="me-3 center">
                                        <Link
                                            to={`https://estudiopyp.freshdesk.com/a/tickets/${expediente.ticket}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img src={fresh} alt="" style={logo1366} />
                                        </Link>
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                                {expediente.callbell ? (
                                    <div className="me-3 center">
                                        <Link
                                            to={`https://dash.callbell.eu/chat/${expediente.callbell}?filter=all`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img src={callbell} alt="" style={logo21366} />
                                        </Link>
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                                {expediente.callbellRequerido ? (
                                    <div className="me-3 center">
                                        <Link
                                            to={`https://dash.callbell.eu/chat/${expediente.callbellRequerido}?filter=all`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img src={callbellReq} alt="" style={logo21366} />
                                        </Link>
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                                {expediente.sharepoint ? (
                                    <div className="me-3 center">
                                        <Link
                                            to={`${expediente.sharepoint}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img src={sharepoint} alt="" style={logo21366} />
                                        </Link>
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                                {expediente.pasCargoDocumentacion ? (
                                    <div className="me-3 center">
                                        <button style={btn} onClick={pasCargo}>
                                            <img src={sharepointRojo} alt="" style={logo21366} />
                                        </button>
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                                <div className="me-3 center">
                                    <NivelTicket
                                        nivel={expediente.nivel}
                                        key={expediente.id}
                                    />
                                </div>
                                <div className="me-1 center">
                                    <EstadoTicket
                                        estado={expediente.estado}
                                        key={expediente.id}
                                    />
                                </div>
                                <div className="center">
                                    <button style={btn} onClick={eliminarTicket}>
                                        <i class="bi bi-trash text-secondary" style={trash1366}></i>
                                    </button>
                                </div>
                                {expediente.estadoAtras === 1 ? <div className="ms-1 center">
                                    <div style={btn} id="botonWarning">
                                        <i class="bi bi-exclamation-circle-fill text-warning" style={trash1366}></i>
                                    </div>
                                </div> : <div></div>}
                            </div>
                            <div className=" d-flex ms-5">
                                <p className="ultMod1366">Ultima Modificación</p>
                                <p className="hora1366">{c}</p>
                                <p className="center ms-1 ultMod1366">- {expediente.modificadoPor}</p>
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    );
}
