import { useCallback, useState, useEffect } from "react";
import axios from 'axios'
import { useCookies } from 'react-cookie';
export default function Asignados(asignado) {
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const [edit, setEdit] = useState(false)
    const [recepcionado, setRecepcionado] = useState(asignado.asignado.recepcionado)
    const [sinDenuncia, setSinDenuncia] = useState(asignado.asignado.sinDenuncia)
    const [envCotizar, setEnvCotizar] = useState(asignado.asignado.armadoPreliminar)
    const [presSinRes, setPresSinRes] = useState(asignado.asignado.reclamoPresentado)
    const [aceptacion, setAceptacion] = useState(asignado.asignado.aceptacion)
    const [procPago, setProcPago] = useState(asignado.asignado.procPago)
    const [cobrado, setCobrado] = useState(asignado.asignado.cobrado)
    const [mediacion, setMediacion] = useState(asignado.asignado.mediacion)
    const [cerrado, setCerrado] = useState(asignado.asignado.cerrado)
    const btn = { border: 'none', background: 'none' }
    function handleChange1(e) {
        if (recepcionado === 0) {
            setRecepcionado(1)
        } else if (recepcionado === 1) {
            setRecepcionado(0)
        }
    }
    function handleChange2(e) {
        if (sinDenuncia === 0) {
            setSinDenuncia(1)
        } else if (sinDenuncia === 1) {
            setSinDenuncia(0)
        }
    }
    function handleChange3(e) {
        if (envCotizar === 0) {
            setEnvCotizar(1)
        } else if (envCotizar === 1) {
            setEnvCotizar(0)
        }
    }
    function handleChange4(e) {
        if (presSinRes === 0) {
            setPresSinRes(1)
        } else if (presSinRes === 1) {
            setPresSinRes(0)
        }
    }
    function handleChange5(e) {
        if (aceptacion === 0) {
            setAceptacion(1)
        } else if (aceptacion === 1) {
            setAceptacion(0)
        }
    }
    function handleChange6(e) {
        if (procPago === 0) {
            setProcPago(1)
        } else if (procPago === 1) {
            setProcPago(0)
        }
    }
    function handleChange7(e) {
        if (cobrado === 0) {
            setCobrado(1)
        } else if (cobrado === 1) {
            setCobrado(0)
        }
    }
    function handleChange8(e) {
        if (mediacion === 0) {
            setMediacion(1)
        } else if (mediacion === 1) {
            setMediacion(0)
        }
    }
    function handleChange9(e) {
        if (cerrado === 0) {
            setCerrado(1)
        } else if (cerrado === 1) {
            setCerrado(0)
        }
    }
    const compUpdate = useCallback(async (e) => {
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/ajustes/asignado/update`,
            {
                id: asignado.asignado.id,
                recepcionado: Number(recepcionado),
                sinDenuncia: Number(sinDenuncia),
                armadoPreliminar: Number(envCotizar),
                reclamoPresentado: Number(presSinRes),
                aceptacion: Number(aceptacion),
                procPago: Number(procPago),
                cobrado: Number(cobrado),
                mediacion: Number(mediacion),
                cerrado: Number(cerrado)
            }
        ).then((response) => {
            if (response) {
                setTimeout(() => {
                    window.location.reload()
                }, 10);
            }
        })
    }, [asignado.asignado, recepcionado, sinDenuncia, envCotizar, presSinRes, aceptacion, procPago, cobrado, mediacion, cerrado]);
    return (
        <tr className='letra'>
            <td>
                <h6>{asignado.asignado?.nombre}</h6>
            </td>
            <td class="align-middle">
                {!edit ? <h6 class="">{asignado.asignado.recepcionado ? 'SI' : 'NO'}</h6> : <input class="form-check-input"
                    type="checkbox"
                    onChange={handleChange1}
                    checked={recepcionado}></input>}
            </td>
            <td class="align-middle">
                {!edit ? <h6 class="">{asignado.asignado.sinDenuncia ? 'SI' : 'NO'}</h6> : <input class="form-check-input"
                    type="checkbox"
                    onChange={handleChange2}
                    checked={sinDenuncia}></input>}
            </td>
            <td class="align-middle">
                {!edit ? <h6 class="">{asignado.asignado.armadoPreliminar ? 'SI' : 'NO'}</h6> : <input class="form-check-input"
                    type="checkbox"
                    onChange={handleChange3}
                    checked={envCotizar}></input>}
            </td>
            <td class="align-middle">
                {!edit ? <h6 class="">{asignado.asignado.reclamoPresentado ? 'SI' : 'NO'}</h6> : <input class="form-check-input"
                    type="checkbox"
                    onChange={handleChange4}
                    checked={presSinRes}></input>}
            </td>
            <td class="align-middle">
                {!edit ? <h6 class="">{asignado.asignado.aceptacion ? 'SI' : 'NO'}</h6> : <input class="form-check-input"
                    type="checkbox"
                    onChange={handleChange5}
                    checked={aceptacion}></input>}
            </td>
            <td class="align-middle">
                {!edit ? <h6 class="">{asignado.asignado.procPago ? 'SI' : 'NO'}</h6> : <input class="form-check-input"
                    type="checkbox"
                    onChange={handleChange6}
                    checked={procPago}></input>}
            </td>
            <td class="align-middle">
                {!edit ? <h6 class="">{asignado.asignado.cobrado ? 'SI' : 'NO'}</h6> : <input class="form-check-input"
                    type="checkbox"
                    onChange={handleChange7}
                    checked={cobrado}></input>}
            </td>
            <td class="align-middle">
                {!edit ? <h6 class="">{asignado.asignado.mediacion ? 'SI' : 'NO'}</h6> : <input class="form-check-input"
                    type="checkbox"
                    onChange={handleChange8}
                    checked={mediacion}></input>}
            </td>
            <td class="align-middle">
                {!edit ? <h6 class="">{asignado.asignado.cerrado ? 'SI' : 'NO'}</h6> : <input class="form-check-input"
                    type="checkbox"
                    onChange={handleChange9}
                    checked={cerrado}></input>}
            </td>
            <td>{!edit ? <div></div> :
                <div className="d-flex">
                    <button className="btn btn-primary btn-sm me-2" onClick={(e) =>compUpdate()}>Actualizar</button>
                </div>}</td>
            <td>
                {!edit ? <button style={btn} onClick={((e) => setEdit(true))} className={cookies.user.cargo === 'GERENCIA' ? '' : 'd-none'}><i class="bi bi-pencil-square"></i></button> :
                    <div className="d-flex">
                        <button style={btn} onClick={((e) => setEdit(false))}><i class="bi bi-x-circle-fill"></i></button>
                    </div>}
            </td>
            
        </tr>
    )
}