import './login.css'
import banner from '../images/bannerLoginCliente.jpeg'
import logo from '../images/logoTuveUnChoque.png'
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
export default function LoginCliente() {
    const navigate = useNavigate()
    const [datos, setDatos] = useState({ patente: '', fechaStro: '' })
    const [notFound, setNotFound] = useState(0)
    function convertirFecha(fecha) {
        const partes = fecha.split('-');
        return `${partes[2]}/${partes[1]}/${partes[0]}`;
    }
    async function obtenerExpediente(e) {
        e.preventDefault()
        setNotFound(0)
        try {
            const response = await axios.get(`https://api.tuveunchoque.com.ar/api/cliente/expedientesCliente?patente=${datos.patente}&fechaStro=${convertirFecha(datos.fechaStro)}`);
            localStorage.setItem('expCliente', JSON.stringify(response.data));
            navigate('/cliente/exp')
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setNotFound(1)
            } else {
                setNotFound(1)
            }
        }
    }

    const isMobile375 = useMediaQuery({ query: '(max-width: 375px)' });
    const isMobile412 = useMediaQuery({ query: '(min-width: 376px) and (max-width: 412px)' });
    const isMobile480 = useMediaQuery({ query: '(min-width: 413px) and (max-width: 480px)' });
    return (
        <>
            {isMobile375 ?
                <main className="mainLogin">
                    <section className='m-auto mt-0'>
                        <img src={banner} alt="" style={{ borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', height: '30vh', width: '100%' }} />
                        <div className="center" style={{ height: '8vh', marginTop: '5vh' }}><img src={logo} alt="" width={'50%'} height={'100%'} /></div>
                        <h3 className='center ingreseSusDatosCliente'>Ingrese sus datos</h3>
                        <form action="" className="center" onSubmit={obtenerExpediente} style={{ height: '40vh', marginTop: '1vh' }}>
                            <div className='text-center' >
                                <label htmlFor="" className="labelLogin375 text-center">PATENTE</label>
                                <input type="text" className="inputPatenteLogin" required value={datos.patente} onChange={(e) =>
                                    setDatos((prevState) => ({
                                        ...prevState,
                                        patente: e.target.value,
                                    }))}
                                />
                                <label className='labelFechaStroLogin text-center'>FECHA DE SINIESTRO</label>
                                <div class="inputDateContainerLogin">
                                    <input type="date" class="inputPatenteLogin" required value={datos.fechaStro} onChange={(e) =>
                                        setDatos((prevState) => ({
                                            ...prevState,
                                            fechaStro: e.target.value,
                                        }))}
                                    />
                                </div>
                                <button type="submit" className='submitLoginNuevo375'>INGRESAR</button>
                                {notFound === 1 ? <p className='text-danger mt-1'>Expediente no encontrado</p> : <></>}
                            </div>
                        </form>
                    </section>
                </main>
                : (isMobile412 ?
                    <main className="mainLogin">
                        <section className='m-auto mt-0'>
                            <img src={banner} alt="" style={{ borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', height: '30vh', width: '100%' }} />
                            <div className="center" style={{ height: '8vh', marginTop: '5vh' }}><img src={logo} alt="" width={'50%'} height={'100%'} /></div>
                            <h3 className='center ingreseSusDatosCliente'>Ingrese sus datos</h3>
                            <form action="" className="center" onSubmit={obtenerExpediente} style={{ height: '40vh', marginTop: '1vh' }}>
                                <div className='text-center' >
                                    <label htmlFor="" className="labelLogin375 text-center">PATENTE</label>
                                    <input type="text" className="inputPatenteLogin" required value={datos.patente} onChange={(e) =>
                                        setDatos((prevState) => ({
                                            ...prevState,
                                            patente: e.target.value,
                                        }))}
                                    />
                                    <label className='labelFechaStroLogin text-center'>FECHA DE SINIESTRO</label>
                                    <div class="inputDateContainerLogin">
                                        <input type="date" class="inputPatenteLogin" required value={datos.fechaStro} onChange={(e) =>
                                            setDatos((prevState) => ({
                                                ...prevState,
                                                fechaStro: e.target.value,
                                            }))}
                                        />
                                    </div>
                                    <button type="submit" className='submitLoginNuevo375'>INGRESAR</button>
                                    {notFound === 1 ? <p className='text-danger mt-1'>Expediente no encontrado</p> : <></>}
                                </div>
                            </form>
                        </section>
                    </main>
                    : (isMobile480 ?
                        <main className="mainLogin">
                            <section className='m-auto mt-0'>
                                <img src={banner} alt="" style={{ borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', height: '30vh', width: '100%' }} />
                                <div className="center" style={{ height: '8vh', marginTop: '5vh' }}><img src={logo} alt="" width={'50%'} height={'100%'} /></div>
                                <h3 className='center ingreseSusDatosCliente'>Ingrese sus datos</h3>
                                <form action="" className="center" onSubmit={obtenerExpediente} style={{ height: '40vh', marginTop: '1vh' }}>
                                    <div className='text-center' >
                                        <label htmlFor="" className="labelLogin375 text-center">PATENTE</label>
                                        <input type="text" className="inputPatenteLogin" required value={datos.patente} onChange={(e) =>
                                            setDatos((prevState) => ({
                                                ...prevState,
                                                patente: e.target.value,
                                            }))}
                                        />
                                        <label className='labelFechaStroLogin text-center'>FECHA DE SINIESTRO</label>
                                        <div class="inputDateContainerLogin">
                                            <input type="date" class="inputPatenteLogin" required value={datos.fechaStro} onChange={(e) =>
                                                setDatos((prevState) => ({
                                                    ...prevState,
                                                    fechaStro: e.target.value,
                                                }))}
                                            />
                                        </div>
                                        <button type="submit" className='submitLoginNuevo375'>INGRESAR</button>
                                        {notFound === 1 ? <p className='text-danger mt-1'>Expediente no encontrado</p> : <></>}
                                    </div>
                                </form>
                            </section>
                        </main>
                        :
                        <main className="mainLoginNuevo">
                            <section className="fotoMainClienteLadoIzqContenedor"><img src={banner} alt="" className='fotoMainClienteLadoIzq' /></section>
                            <section className='m-auto mt-0'>
                                <img src={logo} alt="" width={'400px'} height={'100px'} style={{ marginTop: '80px' }} />
                                <form action="" className="center" onSubmit={obtenerExpediente}>
                                    <div className='text-center'>
                                        <label htmlFor="" className="labelLogin text-center">PATENTE</label>
                                        <input type="text" className="inputPatenteLogin" required value={datos.patente} onChange={(e) =>
                                            setDatos((prevState) => ({
                                                ...prevState,
                                                patente: e.target.value,
                                            }))}
                                        />
                                        <label className='labelFechaStroLogin text-center'>FECHA DE SINIESTRO</label>
                                        <div class="inputDateContainerLogin">
                                            <input type="date" class="inputPatenteLogin" required value={datos.fechaStro} onChange={(e) =>
                                                setDatos((prevState) => ({
                                                    ...prevState,
                                                    fechaStro: e.target.value,
                                                }))}
                                            />
                                        </div>
                                        <button type="submit" className='submitLoginNuevo'>INGRESAR</button>
                                        {notFound === 1 ? <p className='text-danger mt-3'>Expediente no encontrado</p> : <></>}
                                    </div>
                                </form>
                            </section>
                        </main>
                    )
                )}
        </>
    );
}
