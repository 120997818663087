import { useCallback, useContext, useEffect } from "react";
import { useCookies } from 'react-cookie';
import Select from 'react-select';
import { useState } from "react";
import axios from "axios";
import { useMediaQuery } from 'react-responsive'
export default function SeccionExpedienteCliente({ expediente }) {
    const divLeer = { height: "35px", width: "100%", backgroundColor: "white", overflow: 'hidden' };
    const divRed = { height: "35px", width: "100%", background: 'transparent linear-gradient(254deg, #FC8787 0%, #FC4153E6 100%) 0% 0% no-repeat padding-box' }
    const divInfoLeer = { height: "100px", width: "100%", backgroundColor: "white" };
    const [honorarios, setHonorarios] = useState({ honorarios: null })
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const actualizarHonorarios = useCallback(async (e) => {
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/gerencia/expedientes/update/pasCobro`,
            {
                id: expediente.id,
                cobro: honorarios.honorarios
            }
        ).then((response) => {
            if (response) {
                setTimeout(async () => {
                    window.location.reload()
                }, 1000);
            }
        })
    }, [expediente, honorarios]);
    const hora = localStorage.getItem('hora');
    const [time, setTime] = useState(new Date());
    useEffect(() => {
        const timerId = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => clearInterval(timerId);
    }, []);
    const formatTime = (date) => {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };
    const getTimeDifferenceInSeconds = (timeString1, timeString2) => {
        const [hours1, minutes1, seconds1] = timeString1.split(':').map(Number);
        const [hours2, minutes2, seconds2] = timeString2.split(':').map(Number);
        const date1 = new Date();
        date1.setHours(hours1, minutes1, seconds1, 0);
        const date2 = new Date();
        date2.setHours(hours2, minutes2, seconds2, 0);
        const differenceInMilliseconds = Math.abs(date1 - date2);
        return Math.floor(differenceInMilliseconds / 1000);
    };
    const isMobile375 = useMediaQuery({ query: '(max-width: 375px)' });
    const isMobile412 = useMediaQuery({ query: '(min-width: 376px) and (max-width: 412px)' });
    const isMobile480 = useMediaQuery({ query: '(min-width: 413px) and (max-width: 480px)' });
    return (
        <>
            {isMobile375 ?
                <>
                    <>
                        <div class="row m-0 ms-1 me-1">
                            <div className="col p-0 me-1">
                                <label class="mb-1 letraPlanillaExpedienteTexto375">Estado</label>
                                <div type="text" class="recuasroTexto375">
                                    {expediente.estado}
                                </div>
                            </div>
                            <div className="col p-0">
                                <label class="mb-1 letraPlanillaExpedienteTexto375">Capital</label>
                                <div type="text" class="recuasroTexto375">
                                    {expediente.capital}
                                </div>
                            </div>
                        </div>
                        <div class="row m-0 ms-1 me-1">
                            <div className="col p-0 me-1">
                                <label class="mb-1 letraPlanillaExpedienteTexto375">MtoReclamado</label>
                                <div type="text" class="recuasroTexto375">
                                    {expediente.monto}
                                </div>
                            </div>
                            <div class="col p-0">
                                <label class="mb-1 letraPlanillaExpedienteTexto375">Fecha Stro</label>
                                <div type="text" class="recuasroTexto375">
                                    {expediente.fechaStro?.split("-").reverse().join("-")}
                                </div>
                            </div>
                        </div>
                        <div class="row m-0 me-1 ms-1">
                            <div className="col p-0">
                                <label class="mb-1 letraPlanillaExpedienteTexto375">Información Adicional</label>
                                <div type="text" class="recuadroGrandeTexto375">
                                    {expediente.infoAdicional}
                                </div>
                            </div>
                        </div> 
                    </>
                </>
                :
                (isMobile412 ?
                    <>
                        <>
                            <div class="row m-0 ms-1 me-1">
                                <div className="col p-0 me-1">
                                    <label class="mb-1 letraPlanillaExpedienteTexto375">Estado</label>
                                    <div type="text" class="recuasroTexto375">
                                        {expediente.estado}
                                    </div>
                                </div>
                                <div className="col p-0">
                                    <label class="mb-1 letraPlanillaExpedienteTexto375">Capital</label>
                                    <div type="text" class="recuasroTexto375">
                                        {expediente.capital}
                                    </div>
                                </div>
                            </div>
                            <div class="row m-0 ms-1 me-1">
                                <div className="col p-0 me-1">
                                    <label class="mb-1 letraPlanillaExpedienteTexto375">MtoReclamado</label>
                                    <div type="text" class="recuasroTexto375">
                                        {expediente.monto}
                                    </div>
                                </div>
                                <div class="col p-0">
                                    <label class="mb-1 letraPlanillaExpedienteTexto375">Fecha Stro</label>
                                    <div type="text" class="recuasroTexto375">
                                        {expediente.fechaStro?.split("-").reverse().join("-")}
                                    </div>
                                </div>
                            </div>
                            <div class="row m-0 me-1 ms-1">
                                <div className="col p-0">
                                    <label class="mb-1 letraPlanillaExpedienteTexto375">Información Adicional</label>
                                    <div type="text" class="recuadroGrandeTexto375">
                                        {expediente.infoAdicional}
                                    </div>
                                </div>
                            </div> 
                        </>
                    </> :
                    (isMobile480 ?
                        <>
                            <>
                                <div class="row m-0 ms-1 me-1">
                                    <div className="col p-0 me-1">
                                        <label class="mb-1 letraPlanillaExpedienteTexto375">Estado</label>
                                        <div type="text" class="recuasroTexto375">
                                            {expediente.estado}
                                        </div>
                                    </div>
                                    <div className="col p-0">
                                        <label class="mb-1 letraPlanillaExpedienteTexto375">Capital</label>
                                        <div type="text" class="recuasroTexto375">
                                            {expediente.capital}
                                        </div>
                                    </div>
                                </div>
                                <div class="row m-0 ms-1 me-1">
                                    <div className="col p-0 me-1">
                                        <label class="mb-1 letraPlanillaExpedienteTexto375">MtoReclamado</label>
                                        <div type="text" class="recuasroTexto375">
                                            {expediente.monto}
                                        </div>
                                    </div>
                                    <div class="col p-0">
                                        <label class="mb-1 letraPlanillaExpedienteTexto375">Fecha Stro</label>
                                        <div type="text" class="recuasroTexto375">
                                            {expediente.fechaStro?.split("-").reverse().join("-")}
                                        </div>
                                    </div>
                                </div>
                                 <div class="row m-0 me-1 ms-1">
                                    <div className="col p-0">
                                        <label class="mb-1 letraPlanillaExpedienteTexto375">Información Adicional</label>
                                        <div type="text" class="recuadroGrandeTexto375">
                                            {expediente.infoAdicional}
                                        </div>
                                    </div>
                                </div> 
                            </>
                        </> :
                        <div>
                            <div className="mt-1 pb-3 pe-3 ps-3">
                                <div class="mb-2 row">
                                    <div className="col">
                                        <label class="mb-1 letraPlanillaExpediente">Estado</label>
                                        <div type="text" class="form-control" style={divLeer}>
                                            {expediente?.estado}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <label class="mb-1 letraPlanillaExpediente">Capital</label>
                                        <div type="text" class="form-control" style={divLeer}>
                                            {expediente?.capital}
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-2 row">
                                    <div className="col">
                                        <label class="mb-1 letraPlanillaExpediente">Monto Reclamado</label>
                                        <div type="text" class="form-control" style={divLeer}>
                                            {expediente?.monto}
                                        </div>
                                    </div>
                                    <div class="col">
                                        <label class="mb-1 letraPlanillaExpediente">Fecha Stro</label>
                                        <div type="text" class="form-control" style={divLeer}>
                                            {expediente?.fechaStro?.split("-").reverse().join("-")}
                                        </div>
                                    </div>
                                </div>
                                 <div class="mb-2 row">
                                    <div className="col">
                                        <label class="mb-1 letraPlanillaExpediente">Información Adicional</label>
                                        <div type="text" class="form-control overflow-auto" style={divInfoLeer}>
                                            {expediente?.infoAdicional}
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>)
                )
            }
        </>

    );
}
