import Estado from './Estado';
import Nivel from './Nivel';
import { Link } from "react-router-dom"
import fresh from '../../images/fresh.png'
import callbell from '../../images/callbell.jpeg'
import { useCookies } from 'react-cookie';
import sharepoint from '../../images/sharepoint.jpeg'
import sharepointRojo from '../../images/shareRojo.png'
import './expedientesTablas.css'
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { color } from 'chart.js/helpers';
export default function Expediente({ expediente }) {
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const logo = { height: '27px', width: '27px', background: 'transparent 0% 0% no-repeat padding-box', opacity: 1 }
    const logo1366 = { height: '20px', width: '20px', background: 'transparent 0% 0% no-repeat padding-box', opacity: 1 }
    const link = { textDecoration: 'none' }
    const btn = { border: 'none', background: 'none' }
    const pasCargo = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(`https://api.tuveunchoque.com.ar/api/mail/ampliacionCargaPas`, {
            id: expediente.id,
            carga: 0
        }).then((response) => {
            if (response) {
                setTimeout(async () => {
                    window.location.reload()
                }, 1000);
            }
        })
    }, [expediente])
    const hora = localStorage.getItem('hora')
    const [time, setTime] = useState(new Date());
    useEffect(() => {
        const timerId = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => clearInterval(timerId);
    }, []);
    const formatTime = (date) => {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };
    const getTimeDifferenceInSeconds = (timeString1, timeString2) => {
        const [hours1, minutes1, seconds1] = timeString1.split(':').map(Number);
        const [hours2, minutes2, seconds2] = timeString2.split(':').map(Number);
        const date1 = new Date();
        date1.setHours(hours1, minutes1, seconds1, 0);
        const date2 = new Date();
        date2.setHours(hours2, minutes2, seconds2, 0);
        const differenceInMilliseconds = Math.abs(date1 - date2);
        return Math.floor(differenceInMilliseconds / 1000);
    };
    const registro = async (e) => {
        await axios.post('https://api.tuveunchoque.com.ar/api/registroDeUso/update', { user: cookies.user.nombre, segundos: getTimeDifferenceInSeconds(formatTime(time), hora) })
        setCookie('expActual', expediente.nro)
        localStorage.setItem('hora', formatTime(time))
    }
    return (
        <>
            {window.innerWidth > 1920 ?
                <div className='containerTablaExpedientes row mb-1 ps-3 pe-2'>
                    {expediente.prioridad === 'ALTA' ?
                        <div class="tituloExpedienteTabla1 text-danger col p-0">
                            <Link onClick={(e) => registro(e)} to={`/expediente/${expediente.nro}`} style={link} className='text-danger'>{expediente.nro}</Link>
                            {expediente.iconoMediacion === 1 ? <i class="bi bi-shield-fill-exclamation text-warning ms-1"></i> : <></>}
                        </div> :
                        <div class="tituloExpedienteTabla1 col p-0">
                            <Link onClick={(e) => registro(e)} to={`/expediente/${expediente.nro}`} style={link}>{expediente.nro}</Link>
                            {expediente.iconoMediacion === 1 ? <i class="bi bi-shield-fill-exclamation text-warning ms-1"></i> : <></>}
                        </div>}
                    <div class="tituloExpedienteTabla2 col p-0">{expediente.dominioCliente}</div>
                    <div class="tituloExpedienteTabla3 col p-0">{expediente.productorAsociado}</div>
                    <div class="tituloExpedienteTabla4 col p-0">{expediente.fechaCambioEstado?.split("-").reverse().join("-")}</div>
                    <div class="tituloExpedienteTabla5 col p-0">{expediente.compania}</div>
                    <div class="tituloExpedienteTabla6 col-3 p-0">{expediente.nombre}</div>
                    <div class="tituloExpedienteTabla7 col p-0">{expediente.fechaVencimiento?.split("-").reverse().join("-")}</div>
                    <div className='col-2 p-0 tituloExpedienteTabla8'>
                        <div className='d-flex align-items-center'>
                            {expediente.ticket ? <img src={fresh} style={logo} className='me-1'></img> : <div style={logo} className='me-1'></div>}
                            {expediente.callbell ? <img src={callbell} style={logo} className='me-1'></img> : <div style={logo} className='me-1'></div>}
                            {expediente.sharepoint ? <Link to={`${expediente.sharepoint}`} target="_blank" rel="noopener noreferrer"><img src={sharepoint} style={logo} className='me-1'></img></Link> : <div style={logo} className='me-1'></div>}
                            {expediente.pasCargoDocumentacion ? <button style={btn} onClick={pasCargo}><img src={sharepointRojo} style={logo} className='me-1'></img> </button> : <div style={logo} className='me-1'></div>}
                            <Nivel nivel={expediente.nivel} />
                            <Estado estado={expediente.estado} />
                        </div>
                    </div>
                </div>
                :
                <div className='containerTablaExpedientes row mb-1 ps-3 pe-2'>
                    {expediente.prioridad === 'ALTA' ?
                        <div class="tituloExpedienteTabla11366 text-danger col p-0">
                            <Link onClick={(e) => registro(e)} to={`/expediente/${expediente.nro}`} style={link} className='text-danger'>{expediente.nro}</Link>
                            {expediente.iconoMediacion === 1 ? <i class="bi bi-shield-fill-exclamation text-warning ms-1"></i> : <></>}
                        </div> :
                        <div class="tituloExpedienteTabla11366 col p-0">
                            <Link onClick={(e) => registro(e)} to={`/expediente/${expediente.nro}`} style={link}>{expediente.nro}</Link>
                            {expediente.iconoMediacion === 1 ? <i class="bi bi-shield-fill-exclamation text-warning ms-1"></i> : <></>}
                        </div>}
                    <div class="tituloExpedienteTabla21366 col p-0">{expediente.dominioCliente}</div>
                    <div class="tituloExpedienteTabla31366 col p-0">{expediente.productorAsociado}</div>
                    <div class="tituloExpedienteTabla41366 col p-0">{expediente.fechaCambioEstado?.split("-").reverse().join("-")}</div>
                    <div class="tituloExpedienteTabla51366 col p-0">{expediente.compania}</div>
                    <div class="tituloExpedienteTabla61366 col-3 p-0">{expediente.nombre}</div>
                    <div class="tituloExpedienteTabla71366 col p-0">{expediente.fechaVencimiento?.split("-").reverse().join("-")}</div>
                    <div className='col-2 p-0 tituloExpedienteTabla8'>
                        <div className='d-flex align-items-center'>
                            {expediente.ticket ? <img src={fresh} style={logo1366} className='me-1'></img> : <div style={logo1366} className='me-1'></div>}
                            {expediente.callbell ? <img src={callbell} style={logo1366} className='me-1'></img> : <div style={logo1366} className='me-1'></div>}
                            {expediente.sharepoint ? <Link to={`${expediente.sharepoint}`} target="_blank" rel="noopener noreferrer"><img src={sharepoint} style={logo1366} className='me-1'></img></Link> : <div style={logo1366} className='me-1'></div>}
                            {expediente.pasCargoDocumentacion ? <button style={btn} onClick={pasCargo}><img src={sharepointRojo} style={logo1366} className='me-1'></img> </button> : <div style={logo1366} className='me-1'></div>}
                            <Nivel nivel={expediente.nivel} />
                            <Estado estado={expediente.estado} />
                        </div>
                    </div>
                </div>}
        </>
    )
}