import { useCallback, useContext, useEffect } from "react";
import { editarContext } from "../../../context/Editar";
import { useCookies } from 'react-cookie';
import Select from 'react-select';
import { useState } from "react";
import axios from "axios";
import '../index.css'
import { useMediaQuery } from 'react-responsive'
export default function SeccionExpedienteLeer({ expediente }) {
    const divLeer = { height: "35px", width: "100%", backgroundColor: "white", overflow: 'hidden' };
    const divRed = { height: "35px", width: "100%", background: 'transparent linear-gradient(254deg, #FC8787 0%, #FC4153E6 100%) 0% 0% no-repeat padding-box' }
    const divInfoLeer = { height: "100px", width: "100%", backgroundColor: "white" };
    const bot = { background: '#F99F41 0% 0% no-repeat padding-box', borderRadius: '22px', height: '41px', width: '140px', }
    const bot3 = { background: '#F99F41 0% 0% no-repeat padding-box', borderRadius: '22px', height: '41px', width: '180px', }
    const bot2 = { background: '#9EFAD9 0% 0% no-repeat padding-box', borderRadius: '22px', height: '41px', width: '140px' }
    const a = { textDecoration: 'none', color: 'white', fontSize: '14px', fontWeight: 'bold' }
    const a2 = { textDecoration: 'none', fontSize: '14px', fontWeight: 'bold', color: '#06A169' }
    const editarBtn = { background: '#0731FF 0% 0% no-repeat padding-box', borderRadius: '22px', width: '135px', height: '39px', fontSize: '14px', fontWeight: 'bold' }
    const honorariosInput = [{ label: 'SI', value: 'SI' }, { label: 'NO', value: 'NO' }]
    const [honorarios, setHonorarios] = useState({ honorarios: null })
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const actualizarHonorarios = useCallback(async (e) => {
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/gerencia/expedientes/update/pasCobro`,
            {
                id: expediente.id,
                cobro: honorarios.honorarios
            }
        ).then((response) => {
            if (response) {
                setTimeout(async () => {
                    window.location.reload()
                }, 1000);
            }
        })
    }, [expediente, honorarios]);
    const { setEditar } = useContext(editarContext)
    const hora = localStorage.getItem('hora');
    const [time, setTime] = useState(new Date());
    useEffect(() => {
        const timerId = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => clearInterval(timerId);
    }, []);
    const formatTime = (date) => {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };
    const getTimeDifferenceInSeconds = (timeString1, timeString2) => {
        const [hours1, minutes1, seconds1] = timeString1.split(':').map(Number);
        const [hours2, minutes2, seconds2] = timeString2.split(':').map(Number);
        const date1 = new Date();
        date1.setHours(hours1, minutes1, seconds1, 0);
        const date2 = new Date();
        date2.setHours(hours2, minutes2, seconds2, 0);
        const differenceInMilliseconds = Math.abs(date1 - date2);
        return Math.floor(differenceInMilliseconds / 1000);
    };
    const editarFunction = async () => {
        await axios.post('https://api.tuveunchoque.com.ar/api/registroDeUso/update', { user: cookies.user.nombre, segundos: getTimeDifferenceInSeconds(formatTime(time), hora) })
        localStorage.setItem('hora', formatTime(time))
        setEditar(true)
    }
    const isMobile375 = useMediaQuery({ query: '(max-width: 375px)' });
    const isMobile412 = useMediaQuery({ query: '(min-width: 376px) and (max-width: 412px)' });
    const isMobile480 = useMediaQuery({ query: '(min-width: 413px) and (max-width: 480px)' });
    const isTablet768 = useMediaQuery({ query: '(min-width: 480px) and (max-width: 768px)' });
    return (
        <>
            {isMobile375 ?
                <>
                    <div class="row m-0 ms-1 me-1">
                        <div className="col p-0 me-1">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">Estado</label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.estado}
                            </div>
                        </div>
                        <div class="col p-0 me-1">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">Asignado a</label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.asignadoA}
                            </div>
                        </div>
                        <div className="col p-0">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">Capital</label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.capital}
                            </div>
                        </div>
                    </div>
                    <div class="row m-0 ms-1 me-1">
                        <div className="col p-0 me-1">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">MtoReclamado</label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.monto}
                            </div>
                        </div>
                        <div class="col p-0 me-1">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">Nivel</label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.nivel}
                            </div>
                        </div>
                        <div class="col p-0">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">Fecha Stro</label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.fechaStro?.split("-").reverse().join("-")}
                            </div>
                        </div>
                    </div>
                    <div class="row m-0 me-1 ms-1">
                        <div className="col p-0 me-1">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">Fecha Exp</label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.fechaExp?.split("-").reverse().join("-")}
                            </div>
                        </div>
                        <div class="col p-0 me-1">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">Fecha Cambio Estado</label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.fechaCambioEstado?.split("-").reverse().join("-")}
                            </div>
                        </div>
                        <div class="col p-0">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">Ultima Modificación</label>
                            <input type="text" class="recuasroTexto375" placeholder={expediente.ultimaMod?.split("-").reverse().join("-")} />
                        </div>
                    </div>
                    {cookies.user.cargo !== 'PAS' ? <div class="row m-0 me-1 ms-1">
                        <div className="col p-0">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">Información Adicional</label>
                            <div type="text" class="recuadroGrandeTexto375">
                                {expediente.infoAdicional}
                            </div>
                        </div>
                    </div> : <div></div>}
                    <div className="mb-3"></div>
                </>
                :
                (isMobile412 ?
                    <>
                        <div class="row m-0 ms-1 me-1">
                            <div className="col p-0 me-1">
                                <label class="mb-1 letraPlanillaExpedienteTexto375">Estado</label>
                                <div type="text" class="recuasroTexto375">
                                    {expediente.estado}
                                </div>
                            </div>
                            <div class="col p-0 me-1">
                                <label class="mb-1 letraPlanillaExpedienteTexto375">Asignado a</label>
                                <div type="text" class="recuasroTexto375">
                                    {expediente.asignadoA}
                                </div>
                            </div>
                            <div className="col p-0">
                                <label class="mb-1 letraPlanillaExpedienteTexto375">Capital</label>
                                <div type="text" class="recuasroTexto375">
                                    {expediente.capital}
                                </div>
                            </div>
                        </div>
                        <div class="row m-0 ms-1 me-1">
                            <div className="col p-0 me-1">
                                <label class="mb-1 letraPlanillaExpedienteTexto375">MtoReclamado</label>
                                <div type="text" class="recuasroTexto375">
                                    {expediente.monto}
                                </div>
                            </div>
                            <div class="col p-0 me-1">
                                <label class="mb-1 letraPlanillaExpedienteTexto375">Nivel</label>
                                <div type="text" class="recuasroTexto375">
                                    {expediente.nivel}
                                </div>
                            </div>
                            <div class="col p-0">
                                <label class="mb-1 letraPlanillaExpedienteTexto375">Fecha Stro</label>
                                <div type="text" class="recuasroTexto375">
                                    {expediente.fechaStro?.split("-").reverse().join("-")}
                                </div>
                            </div>
                        </div>
                        <div class="row m-0 me-1 ms-1">
                            <div className="col p-0 me-1">
                                <label class="mb-1 letraPlanillaExpedienteTexto375">Fecha Exp</label>
                                <div type="text" class="recuasroTexto375">
                                    {expediente.fechaExp?.split("-").reverse().join("-")}
                                </div>
                            </div>
                            <div class="col p-0 me-1">
                                <label class="mb-1 letraPlanillaExpedienteTexto375">Fecha Cambio Estado</label>
                                <div type="text" class="recuasroTexto375">
                                    {expediente.fechaCambioEstado?.split("-").reverse().join("-")}
                                </div>
                            </div>
                            <div class="col p-0">
                                <label class="mb-1 letraPlanillaExpedienteTexto375">Ultima Modificación</label>
                                <input type="text" class="recuasroTexto375" placeholder={expediente.ultimaMod?.split("-").reverse().join("-")} />
                            </div>
                        </div>
                        {cookies.user.cargo !== 'PAS' ? <div class="row m-0 me-1 ms-1">
                            <div className="col p-0">
                                <label class="mb-1 letraPlanillaExpedienteTexto375">Información Adicional</label>
                                <div type="text" class="recuadroGrandeTexto375">
                                    {expediente.infoAdicional}
                                </div>
                            </div>
                        </div> : <div></div>}
                        <div className="mb-3"></div>
                    </>
                    :
                    (isMobile480 ?
                        <>
                            <div class="row m-0 ms-1 me-1">
                                <div className="col p-0 me-1">
                                    <label class="mb-1 letraPlanillaExpedienteTexto375">Estado</label>
                                    <div type="text" class="recuasroTexto375">
                                        {expediente.estado}
                                    </div>
                                </div>
                                <div class="col p-0 me-1">
                                    <label class="mb-1 letraPlanillaExpedienteTexto375">Asignado a</label>
                                    <div type="text" class="recuasroTexto375">
                                        {expediente.asignadoA}
                                    </div>
                                </div>
                                <div className="col p-0">
                                    <label class="mb-1 letraPlanillaExpedienteTexto375">Capital</label>
                                    <div type="text" class="recuasroTexto375">
                                        {expediente.capital}
                                    </div>
                                </div>
                            </div>
                            <div class="row m-0 ms-1 me-1">
                                <div className="col p-0 me-1">
                                    <label class="mb-1 letraPlanillaExpedienteTexto375">MtoReclamado</label>
                                    <div type="text" class="recuasroTexto375">
                                        {expediente.monto}
                                    </div>
                                </div>
                                <div class="col p-0 me-1">
                                    <label class="mb-1 letraPlanillaExpedienteTexto375">Nivel</label>
                                    <div type="text" class="recuasroTexto375">
                                        {expediente.nivel}
                                    </div>
                                </div>
                                <div class="col p-0">
                                    <label class="mb-1 letraPlanillaExpedienteTexto375">Fecha Stro</label>
                                    <div type="text" class="recuasroTexto375">
                                        {expediente.fechaStro?.split("-").reverse().join("-")}
                                    </div>
                                </div>
                            </div>
                            <div class="row m-0 me-1 ms-1">
                                <div className="col p-0 me-1">
                                    <label class="mb-1 letraPlanillaExpedienteTexto375">Fecha Exp</label>
                                    <div type="text" class="recuasroTexto375">
                                        {expediente.fechaExp?.split("-").reverse().join("-")}
                                    </div>
                                </div>
                                <div class="col p-0 me-1">
                                    <label class="mb-1 letraPlanillaExpedienteTexto375">Fecha Cambio Estado</label>
                                    <div type="text" class="recuasroTexto375">
                                        {expediente.fechaCambioEstado?.split("-").reverse().join("-")}
                                    </div>
                                </div>
                                <div class="col p-0">
                                    <label class="mb-1 letraPlanillaExpedienteTexto375">Ultima Modificación</label>
                                    <input type="text" class="recuasroTexto375" placeholder={expediente.ultimaMod?.split("-").reverse().join("-")} />
                                </div>
                            </div>
                            {cookies.user.cargo !== 'PAS' ? <div class="row m-0 me-1 ms-1">
                                <div className="col p-0">
                                    <label class="mb-1 letraPlanillaExpedienteTexto375">Información Adicional</label>
                                    <div type="text" class="recuadroGrandeTexto375">
                                        {expediente.infoAdicional}
                                    </div>
                                </div>
                            </div> : <div></div>}
                            <div className="mb-3"></div>
                        </>
                        :
                        (isTablet768 ?
                            <>
                                <div class="row m-0 ms-1 me-1">
                                    <div className="col p-0 me-1">
                                        <label class="mb-1 letraPlanillaExpedienteTexto768">Estado</label>
                                        <div type="text" class="recuasroTexto768">
                                            {expediente.estado}
                                        </div>
                                    </div>
                                    <div class="col p-0 me-1">
                                        <label class="mb-1 letraPlanillaExpedienteTexto768">Asignado a</label>
                                        <div type="text" class="recuasroTexto768">
                                            {expediente.asignadoA}
                                        </div>
                                    </div>
                                    <div className="col p-0">
                                        <label class="mb-1 letraPlanillaExpedienteTexto768">Capital</label>
                                        <div type="text" class="recuasroTexto768">
                                            {expediente.capital}
                                        </div>
                                    </div>
                                </div>
                                <div class="row m-0 ms-1 me-1 mt-2">
                                    <div className="col p-0 me-1">
                                        <label class="mb-1 letraPlanillaExpedienteTexto768">MtoReclamado</label>
                                        <div type="text" class="recuasroTexto768">
                                            {expediente.monto}
                                        </div>
                                    </div>
                                    <div class="col p-0 me-1">
                                        <label class="mb-1 letraPlanillaExpedienteTexto768">Nivel</label>
                                        <div type="text" class="recuasroTexto768">
                                            {expediente.nivel}
                                        </div>
                                    </div>
                                    <div class="col p-0">
                                        <label class="mb-1 letraPlanillaExpedienteTexto768">Fecha Stro</label>
                                        <div type="text" class="recuasroTexto768">
                                            {expediente.fechaStro?.split("-").reverse().join("-")}
                                        </div>
                                    </div>
                                </div>
                                <div class="row m-0 me-1 ms-1 mt-2">
                                    <div className="col p-0 me-1">
                                        <label class="mb-1 letraPlanillaExpedienteTexto768">Fecha Exp</label>
                                        <div type="text" class="recuasroTexto768">
                                            {expediente.fechaExp?.split("-").reverse().join("-")}
                                        </div>
                                    </div>
                                    <div class="col p-0 me-1">
                                        <label class="mb-1 letraPlanillaExpedienteTexto768">Fecha Cambio Estado</label>
                                        <div type="text" class="recuasroTexto768">
                                            {expediente.fechaCambioEstado?.split("-").reverse().join("-")}
                                        </div>
                                    </div>
                                    <div class="col p-0">
                                        <label class="mb-1 letraPlanillaExpedienteTexto768">Ultima Modificación</label>
                                        <div type="text" class="recuasroTexto768">
                                            {expediente.ultimaMod?.split("-").reverse().join("-")}
                                        </div>
                                    </div>
                                </div>
                                {cookies.user.cargo !== 'PAS' ? 
                                <div class="row m-0 me-1 ms-1 mt-2">
                                    <div className="col p-0">
                                        <label class="mb-1 letraPlanillaExpedienteTexto768">Información Adicional</label>
                                        <div type="text" class="recuadroGrandeTexto768">
                                            {expediente.infoAdicional}
                                        </div>
                                    </div>
                                </div> : <div></div>}
                                <div className="mb-3"></div>
                            </> :
                            <div>
                                <div className="mt-1 pb-3 pe-3 ps-3">
                                    <div class="mb-2 row">
                                        <div className="col">
                                            <label class="mb-1 letraPlanillaExpediente">Estado</label>
                                            <div type="text" class="form-control" style={divLeer}>
                                                {expediente.estado}
                                            </div>
                                        </div>
                                        <div class="col">
                                            <label class="mb-1 letraPlanillaExpediente">Asignado a</label>
                                            <div type="text" class="form-control" style={divLeer}>
                                                {expediente.asignadoA}
                                            </div>
                                        </div>
                                        <div className="col">
                                            <label class="mb-1 letraPlanillaExpediente">Capital</label>
                                            <div type="text" class="form-control" style={divLeer}>
                                                {expediente.capital}
                                            </div>
                                        </div>
                                        {cookies.user.cargo !== "PAS" ? <div className="col">
                                            <label class="mb-1 letraPlanillaExpediente">Prioridad</label>
                                            <div type="text" class="form-control" style={divLeer}>
                                                {expediente.prioridad}
                                            </div>
                                        </div> : <div></div>}
                                    </div>
                                    <div class="mb-2 row">
                                        <div className="col">
                                            <label class="mb-1 letraPlanillaExpediente">Monto Reclamado</label>
                                            <div type="text" class="form-control" style={divLeer}>
                                                {expediente.monto}
                                            </div>
                                        </div>
                                        {cookies.user.cargo !== 'PAS' ? <div className="col-3">
                                            <label class="mb-1 letraPlanillaExpediente">SharePoint</label>
                                            <div type="text" class="form-control" style={expediente.sharepoint ? divLeer : divRed}>
                                                {expediente.sharepoint}
                                            </div>
                                        </div> : <div></div>}
                                        {cookies.user.cargo !== 'PAS' ? <div className="col">
                                            <label class="mb-1 letraPlanillaExpediente">Ticket</label>
                                            <div type="text" class='form-control' style={expediente.ticket ? divLeer : divRed}>
                                                {expediente.ticket}
                                            </div>
                                        </div> : <div></div>}
                                        {cookies.user.cargo !== "PAS" ? <div className="col">
                                            <label class="mb-1 letraPlanillaExpediente">Chat Callbell</label>
                                            <div type="text" class='form-control' style={expediente.callbell ? divLeer : divRed}>
                                                {expediente.callbell}
                                            </div>
                                        </div> : <div></div>}
                                    </div>
                                    {cookies.user.cargo !== 'PAS' ? <div class="mb-2 row">
                                        <div className="col">
                                            <label class="mb-1 letraPlanillaExpediente">Información Adicional</label>
                                            <div type="text" class="form-control overflow-auto" style={divInfoLeer}>
                                                {expediente.infoAdicional}
                                            </div>
                                        </div>
                                    </div> : <div></div>}
                                    <div class="mb-2 row">
                                        <div class="col">
                                            <label class="mb-1 letraPlanillaExpediente">Fecha Stro</label>
                                            <div type="text" class="form-control" style={divLeer}>
                                                {expediente.fechaStro?.split("-").reverse().join("-")}
                                            </div>
                                        </div>
                                        <div className="col">
                                            <label class="mb-1 letraPlanillaExpediente">Fecha Exp</label>
                                            <div type="text" class="form-control" style={divLeer}>
                                                {expediente.fechaExp?.split("-").reverse().join("-")}
                                            </div>
                                        </div>
                                        <div class="col">
                                            <label class="mb-1 letraPlanillaExpediente">Fecha Cambio de Estado</label>
                                            <div type="text" class="form-control" style={divLeer}>
                                                {expediente.fechaCambioEstado?.split("-").reverse().join("-")}
                                            </div>
                                        </div>
                                        <div class="col">
                                            <label class="mb-1 letraPlanillaExpediente">Ultima Modificación</label>
                                            <input type="text" class="form-control" placeholder={expediente.ultimaMod?.split("-").reverse().join("-")} />
                                        </div>
                                    </div>
                                    {cookies.user.cargo === 'PAS' ? <div className="col mt-5">
                                        <label class="mb-1 letraPlanillaExpediente">¿Cobró sus Honorarios?</label>
                                        <form className="d-flex">
                                            <Select
                                                placeholder={expediente.pasCobroHonorarios}
                                                required
                                                className="w-50 "
                                                options={honorariosInput}
                                                onChange={(newValue) => setHonorarios((prevState) => ({
                                                    ...prevState,
                                                    honorarios: newValue.value,
                                                }))}
                                            />
                                            <button className="btn btn-primary ms-5" type="submit" onClick={actualizarHonorarios}>Actualizar</button>
                                        </form>
                                    </div> : <div></div>}
                                </div>
                                {cookies.user.cargo !== 'PAS' ? <div className="row mt-1 mb-3 pe-3 ps-3">
                                    <div className="col d-flex">
                                        <div className="me-3 center" style={bot}>
                                            <a
                                                style={a}
                                                href={`https://estudio-pyp.com.ar/formulario-presentacion/?ID=${expediente.id}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Presentacion
                                            </a>
                                        </div>
                                        <div className="me-3 center" style={bot3}>
                                            <a
                                                style={a}
                                                href={`https://estudio-pyp.com.ar/mensajes-automaticos/?NRO=${expediente.nro}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Mensajes Automáticos
                                            </a>
                                        </div>
                                        <div className="center" style={bot2}>
                                            <a
                                                style={a2}
                                                href={`https://estudio-pyp.com.ar/formularios-empleados/?NRO=${expediente.nro}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Whatsapp
                                            </a>
                                        </div>
                                        <div className="col">
                                            <div className="ms-5">
                                                <button className="btn btn-primary btn-sm" onClick={async (e) => editarFunction()} style={editarBtn}>EDITAR</button>
                                            </div>
                                        </div>
                                    </div>
                                </div> : <div></div>}
                                {cookies.user.cargo === 'PAS' ? <div className="row mt-1 mb-3 pe-3 ps-3">
                                    <div className="col">
                                        <div className="me-3 center" style={bot}>
                                            <a
                                                style={a}
                                                href={`https://estudio-pyp.com.ar/convenio/?ID=${expediente.nro}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Convenio
                                            </a>
                                        </div>
                                    </div>
                                </div> : <div></div>}
                            </div>))
                )
            }
        </>

    );
}
