import { useState } from "react";
import { useCookies } from 'react-cookie';
import { useMediaQuery } from 'react-responsive'
import SeccionExpedienteCliente from "./SeccionExpedienteCliente";
import SeccionClienteCliente from "./SeccionClienteCliente";
import SeccionRequeridoCliente from "./SeccionRequeridoCliente";
import './cliente.css'
export default function PlanillasCliente({ expediente }) {
    // VALORES GLOBALES 
    const [active, setActive] = useState(2);
    const SetView = (active) => { setActive(active) };
    // FUNCION CONDICIONAL PESTANIAS
    const ActiveView = () => {
        switch (active) {
            case 1:
                return <SeccionExpedienteCliente expediente={expediente} />;
            case 2:
                return <SeccionClienteCliente expediente={expediente} />;
            case 3:
                return <SeccionRequeridoCliente expediente={expediente} />;
            default:
                return <SeccionClienteCliente expediente={expediente} />
        }
    };
    const isMobile375 = useMediaQuery({ query: '(max-width: 375px)' });
    const isMobile412 = useMediaQuery({ query: '(min-width: 376px) and (max-width: 412px)' });
    const isMobile480 = useMediaQuery({ query: '(min-width: 413px) and (max-width: 480px)' });

    const getContainerClass = (index) => {
        if (active === index) {
            return isMobile375 ? 'botonActivoPlanillaExpCliente375' : (isMobile412 ? 'botonActivoPlanillaExpCliente412' : (isMobile480 ? 'botonActivoPlanillaExpCliente480' : 'botonActivoPlanillaExpCliente'));
        } else {
            return isMobile375 ? 'botonInactivoPlanillaExpCliente375' : (isMobile412 ? 'botonInactivoPlanillaExpCliente412' : (isMobile480 ? 'botonInactivoPlanillaExpCliente480' : 'botonInactivoPlanillaExpCliente'));
        }
    };
    const getButtonClass = (index) => {
        if (active === index) {
            return isMobile375 ? 'btnCambioPlanillaCliente375' : (isMobile412 ? 'btnCambioPlanillaCliente412' : (isMobile480 ? 'btnCambioPlanillaCliente480' : 'btnCambioPlanillaCliente'));
        } else {
            return isMobile375 ? 'btnCambioPlanillaInactivoCliente375' : (isMobile412 ? 'btnCambioPlanillaInactivoCliente412' : (isMobile480 ? 'btnCambioPlanillaInactivoCliente480' : 'btnCambioPlanillaInactivoCliente'));
        }
    };
    return (
        <div className={isMobile375 ? "infoExpContainerCliente375" : (isMobile412 ? 'infoExpContainerCliente412' : (isMobile480 ? 'infoExpContainerCliente480' : "infoExpContainerCliente"))}>
            <>
                <div className="d-flex m-0 p-0">
                    {[2, 3].map((index) => (
                        <div key={index} className={getContainerClass(index)}>
                            <button className={getButtonClass(index)} onClick={() => SetView(index)}>
                                {index === 2 ? "CLIENTE" : index === 3 ? "REQUERIDO" : ''}
                            </button>
                        </div>
                    ))}
                </div>
                {ActiveView()}
            </>
        </div>
    )
}