import { useCallback, useState, useEffect } from "react";
import axios from 'axios'
import { useCookies } from 'react-cookie';
import Select from 'react-select';
export default function Comp(comp) {
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const asignadoInput = [
        { label: "AGOSTINA", value: "AGOSTINA" },
        { label: "CATALINA", value: "CATALINA" },
        { label: "DANIELA", value: "DANIELA" },
        { label: "FRANCO", value: "FRANCO" },
        { label: "JUAN", value: "JUAN" },
        { label: "SOFIA", value: "SOFIA" },
        { label: "MICAELA", value: "MICAELA" },
        { label: 'SANDRA', value: 'SANDRA' },
        { label: "FEDERICO", value: "FEDERICO" },
        { label: "FACUNDO", value: 'FACUNDO' },
        { label: "LUCAS", value: 'LUCAS' },
        { label: "GONZALO", value: 'GONZALO' },
        { label: "", value: null },
    ];
    const btn = { border: 'none', background: 'none' }
    const [edit, setEdit] = useState(false)
    const [com, setCom] = useState({ cob: comp.comp['PLAZO DE COBRO'], res: comp.comp['PLAZO DE RESOLUCION'], recepcionado: comp.comp['RECEPCIONADO'], sinDenuncia: comp.comp['SIN DENUNCIA'], envCotizar: comp.comp['ARMADO PRELIMINAR'], presentado: comp.comp['PRESENTADO'], aceptado: comp.comp['ACEPTACION'], procPago: comp.comp['EN PROC PAGO'] })
    const compUpdate = useCallback(async () => {
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/ajustes/compania/update`,
            {
                plazoCob: com.cob,
                plazoRes: com.res,
                id: comp.comp['ID'],
                recepcionado: com.recepcionado,
                sinDenuncia: com.sinDenuncia,
                armadoPreliminar: com.envCotizar,
                presentado: com.presentado,
                aceptado: com.aceptado,
                procPago: com.procPago
            }
        ).then((response) => {
            if (response) {
                setTimeout(() => {
                    window.location.reload()
                }, 10);
            }
        })
    }, [com])
    return (
        <tr className='letra'>
            <td class="align-middle">
                <h6 className="mb-0  center">{comp.comp.compania}</h6>
            </td>
            <td class="align-middle">
                {!edit ? <h6 class="">{comp.comp['PLAZO DE RESOLUCION']}</h6> : <input type="number" class="form-control form-control-sm w-50 " placeholder={comp.comp['PLAZO DE RESOLUCION']} onChange={(e) =>
                    setCom((prevState) => ({
                        ...prevState,
                        res: e.target.value,
                    }))}></input>}
            </td>
            <td class="align-middle=">
                {!edit ? <h6 class="">{comp.comp['PLAZO DE COBRO']}</h6> : <input type="number" class="form-control form-control-sm w-50" onChange={(e) =>
                    setCom((prevState) => ({
                        ...prevState,
                        cob: e.target.value,
                    }))} placeholder={comp.comp['PLAZO DE COBRO']}></input>}
            </td>
            <td class="align-middle=">
                {!edit ? <h6 class="">{comp.comp['RECEPCIONADO']}</h6> : <Select
                    options={asignadoInput}
                    placeholder={com.recepcionado}
                    onChange={(newValue) => setCom((prevState) => ({
                        ...prevState,
                        recepcionado: newValue.value,
                    }))}
                />}
            </td>
            <td class="align-middle=">
                {!edit ? <h6 class="">{comp.comp['SIN DENUNCIA']}</h6> : <Select
                    options={asignadoInput}
                    placeholder={com.sinDenuncia}
                    onChange={(newValue) => setCom((prevState) => ({
                        ...prevState,
                        sinDenuncia: newValue.value,
                    }))}
                />}
            </td>
            <td class="align-middle=">
                {!edit ? <h6 class="">{comp.comp['ARMADO PRELIMINAR']}</h6> : <Select
                    options={asignadoInput}
                    placeholder={com.envCotizar}
                    onChange={(newValue) => setCom((prevState) => ({
                        ...prevState,
                        envCotizar: newValue.value,
                    }))}
                />}
            </td>
            <td class="align-middle=">
                {!edit ? <h6 class="">{comp.comp['PRESENTADO']}</h6> : <Select
                    options={asignadoInput}
                    placeholder={com.presentado}
                    onChange={(newValue) => setCom((prevState) => ({
                        ...prevState,
                        presentado: newValue.value,
                    }))}
                />}
            </td>
            <td class="align-middle=">
                {!edit ? <h6 class="">{comp.comp['ACEPTACION']}</h6> : <Select
                    options={asignadoInput}
                    placeholder={com.aceptado}
                    onChange={(newValue) => setCom((prevState) => ({
                        ...prevState,
                        aceptado: newValue.value,
                    }))}
                />}
            </td>
            <td class="align-middle=">
                {!edit ? <h6 class="">{comp.comp['EN PROC PAGO']}</h6> : <Select
                    options={asignadoInput}
                    placeholder={com.presentado}
                    onChange={(newValue) => setCom((prevState) => ({
                        ...prevState,
                        presentado: newValue.value,
                    }))}
                />}
            </td>
            <td>{!edit ? <div></div> :
                <div className="d-flex">
                    <button className="btn btn-primary btn-sm me-2" onClick={(e) => compUpdate(e)}>Actualizar</button>
                </div>}</td>
            <td>
                {!edit ? <button style={btn} onClick={((e) => setEdit(true))} className={cookies.user.cargo === 'GERENCIA' ? '' : 'd-none'}><i class="bi bi-pencil-square"></i></button> :
                    <div className="d-flex">
                        <button style={btn} onClick={((e) => setEdit(false))}><i class="bi bi-x-circle-fill"></i></button>
                    </div>}
            </td>
        </tr>
    )
}