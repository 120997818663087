import './mediacion.css'
export default function EstadoMediacion({ estado, estadoMediacion, iconoMediacion }) {
    let clas = ''
    if (estado === 'MEDIACION') {
        if (estadoMediacion === 1) {
            clas = 'estadoExpA center estadoExp375 m-0 ms-1';
        } else if (estadoMediacion === 2) {
            clas = 'estadoExpB center estadoExp375 m-0 ms-1';
        } else if (estadoMediacion === 3) {
            clas = 'estadoExpC center estadoExp375 m-0 ms-1';
        } else if (estadoMediacion === 4) {
            clas = 'estadoExpD center estadoExp375 m-0 ms-1';
        } else if (estadoMediacion === 5) {
            clas = 'estadoExpE center estadoExp375 m-0 ms-1';
        } else if (estadoMediacion === 6) {
            clas = 'estadoExpF center estadoExp375 m-0 ms-1';
        } else if (estadoMediacion === 7) {
            clas = 'estadoExpG center estadoExp375 m-0 ms-1';
        } else if (estadoMediacion === 8) {
            clas = 'estadoExpH center estadoExp375 m-0 ms-1';
        }
    } else {
        if (estado === 1 || estado === 'RECEPCIONADO') {
            clas = 'estadoExpA center estadoExp375 m-0 ms-1';
        } else if (estado === 2 || estado === 'SIN DENUNCIA') {
            clas = 'estadoExpB center estadoExp375 m-0 ms-1';
        } else if (estado === 3 || estado === 'ARMADO PRELIMINAR') {
            clas = 'estadoExpC center estadoExp375 m-0 ms-1';
        } else if (estado === 4 || estado === 'RECLAMO PRESENTADO') {
            clas = 'estadoExpD center estadoExp375 m-0 ms-1';
        } else if (estado === 5 || estado === 'ACEPTACION') {
            clas = 'estadoExpE center estadoExp375 m-0 ms-1';
        } else if (estado === 6 || estado === 'EN PROCESO DE PAGO') {
            clas = 'estadoExpF center estadoExp375 m-0 ms-1';
        } else if (estado === 7 || estado === 'COBRADO') {
            clas = 'estadoExpG center estadoExp375 m-0 ms-1';
        } else if (estado === 'CERRADO') {
            clas = 'estadoExpI center estadoExp375 m-0 ms-1';
        }
    }
    return (
        <h6 className={clas}>{estadoMediacion === 1 && estado === 'MEDIACION' ? 'Solicitud de Fecha' : (estadoMediacion === 2 && estado === 'MEDIACION' ? 'Fecha Asignada' : (estadoMediacion === 3 && estado === 'MEDIACION' ? 'Negociacion' : (estadoMediacion === 4 && estado === 'MEDIACION' ? 'Revisiones Legales' : (estadoMediacion === 5 && estado === 'MEDIACION' ? 'Armado Derivacion' : (estadoMediacion === 6 && estado === 'MEDIACION' ? 'Espera Confirmacion' : (estadoMediacion === 7 && estado === 'MEDIACION' ? 'Aceptacion' : (estadoMediacion === 8 && estado === 'MEDIACION' ? 'Legales' : estado)))))))}</h6>
    )
}