import NivelMediacion from './NivelMediacion';
import EstadoMediacion from './EstadoMediacion';
import '../../App.css'
import { useMediaQuery } from 'react-responsive'
import './miniExp.css'
export default function MiniTablaMediacion({ expediente }) {
    const isMobile375 = useMediaQuery({ query: '(max-width: 375px)' });
    const isMobile412 = useMediaQuery({ query: '(min-width: 376px) and (max-width: 412px)' });
    const isMobile480 = useMediaQuery({ query: '(min-width: 413px) and (max-width: 480px)' });
    const isTablet768 = useMediaQuery({ query: '(min-width: 480px) and (max-width: 768px)' });
    return (
        <>
            {isMobile375 && (

                <div className="d-flex align-items-center" style={{ marginTop: '1vh', height: '2vh' }}>
                    <NivelMediacion nivel={expediente.nivel} />
                    <p className='m-0 textMiniTablaMediacion375'>{expediente.asignadoA}</p>
                    <p className='m-0 textMiniTablaMediacion375'>Ult Mod {expediente.ultimaMod.split(" ")[0]}</p>
                    <p className='m-0 textMiniTablaMediacion375'>{`Stro: ${expediente.nroSeguimientoCompaniaRequerido}`}</p>
                </div>

            )}
            {isMobile412 && (
                <div className="d-flex align-items-center" style={{ marginTop: '1vh', height: '2vh' }}>
                    <NivelMediacion nivel={expediente.nivel} />
                    <p className='m-0 textMiniTablaMediacion375'>{expediente.asignadoA}</p>
                    <p className='m-0 textMiniTablaMediacion375'>Ult Mod {expediente.ultimaMod.split(" ")[0]}</p>
                    <p className='m-0 textMiniTablaMediacion375'>{`Stro: ${expediente.nroSeguimientoCompaniaRequerido}`}</p>
                </div>

            )}
            {isMobile480 && (
                <div className="d-flex align-items-center" style={{ marginTop: '1vh', height: '2vh' }}>
                    <NivelMediacion nivel={expediente.nivel} />
                    <p className='m-0 textMiniTablaMediacion375'>{expediente.asignadoA}</p>
                    <p className='m-0 textMiniTablaMediacion375'>Ult Mod {expediente.ultimaMod.split(" ")[0]}</p>
                    <p className='m-0 textMiniTablaMediacion375'>{`Stro: ${expediente.nroSeguimientoCompaniaRequerido}`}</p>
                </div>
            )}
             {isTablet768 && (
                <div className="d-flex align-items-center" style={{ marginTop: '1vh', height: '2vh' }}>
                    <NivelMediacion nivel={expediente.nivel} />
                    <p className='m-0 textMiniTablaMediacion768'>{expediente.asignadoA}</p>
                    <p className='m-0 textMiniTablaMediacion768'>Ult Mod {expediente.ultimaMod.split(" ")[0]}</p>
                    <p className='m-0 textMiniTablaMediacion768'>{`Stro: ${expediente.nroSeguimientoCompaniaRequerido}`}</p>
                </div>
            )}
            {!isMobile375 && !isMobile412 && !isMobile480 && !isTablet768 && (
                <div>
                    <div className='miniExpContainerMediacion'>{`EXP ${expediente.nro} - ${expediente.nombre} C/ ${expediente.compania} - ${expediente.productorAsociado}`}</div>
                    <div class="containerTituloExp ps-3">
                        <NivelMediacion nivel={expediente.nivel} />
                        <EstadoMediacion estado={expediente.estado} estadoMediacion={expediente.etapaMediacion} />
                        <div class="elementosTituloExp"><p className='textMiniTablaMediacion m-0'>{expediente.asignadoA}</p></div>
                        <div class="elementosTituloExp"><p className='textMiniTablaMediacion m-0'>Ult Mod {expediente.ultimaMod}</p></div>
                        <div class="elementosTituloExp">
                            <div className='d-flex'>
                                <p className='textMiniTablaMediacion m-0'>{`Stro:\xa0`}</p>
                                <p className='textMiniTablaMediacion m-0'>{expediente.nroSeguimientoCompaniaRequerido}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )

}