import axios from "axios";
import { useState, useEffect, useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import LineaProgreso from "../components/Expediente/LineaProgreso/LineaProgreso";
import MiniTablaExpediente from '../components/Expediente/MiniTablaExpediente/MiniTablaExpediente'
import Detalles from '../components/Expediente/Detalles/Detalles'
import fresh from '../images/fresh.png'
import callbell from '../images/callbell.jpeg'
import callbellReq from '../images/calbellReq.jpeg'
import sharepoint from '../images/sharepoint.jpeg'
import sharepointRojo from '../images/shareRojo.png'
import { Link } from "react-router-dom";
import './index.css'
import ComentarioAnclado from "../components/Expediente/ComentarioAnclado/ComentarioAnclado";
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import DetallesPas from "../components/Expediente/Detalles/DetallesPas";
import DatePicker, { registerLocale } from "react-datepicker";
import { vencimientoContext } from "../context/Vencimiento";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import Ampliaciones from "../components/Expediente/Ampliaciones/Ampliaciones";
import PlanillaExpedienteMediacion from "../components/ExpedienteMediacion/PlanillaExpedienteMediacion";
import MiniTablaMediacion from "../components/ExpedienteMediacion/MiniTablaMediacion";
import InformacionExpediente from "../components/Expediente/InformacionExpediente/InformacionExpediente";
import AmpliacionesPas from "../components/Expediente/Ampliaciones/AmpliacionesPas";
import LineaEstadoAsignadoIzq from "../components/ExpedienteMediacion/LineaEstadoAsignadoIzq";
import RegistroSiniestralidad from '../components/Expediente/RegistroSiniestralidad/RegistroSiniestralidad'
import { useMediaQuery } from 'react-responsive'
import DisplayMobileChat from "../components/Expediente/Detalles/DisplayMobileChat";
import ListadoDocumentacion from "../components/Expediente/Documentos/ListadoDocumentacion";
registerLocale('es', es)
export default function ExpedienteMediacion({ expediente, otrosExp, otrosExpReq, otrosExpReqCliente, otrosExpReqReq }) {
    const [vistaMed, setVistaMed] = useState(expediente.estado === 'MEDIACION' ? true : false)
    const mensajes = expediente["COMENTARIOS"]
    const navigate = useNavigate()
    const [cookies] = useCookies(['user']);
    const cook = () => {
        if (!cookies.user) {
            navigate('/login')
        }
    }
    cook()
    const { vencimiento, setVencimiento } = useContext(vencimientoContext)
    const expedienteDataUpdate = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/gerencia/expedientes/updateVencimiento`,
            {
                id: expediente.id,
                vencimiento: new Date(vencimiento).toLocaleDateString('fr-FR'),
            }
        ).then((response) => {
            if (response) {
                setTimeout(async () => {
                    await axios.post('https://api.tuveunchoque.com.ar/api/registroDeUso/update', { user: cookies.user.nombre, segundos: getTimeDifferenceInSeconds(formatTime(time), hora) })
                    localStorage.setItem('hora', formatTime(time))
                    window.location.reload()
                }, 1000);
            }
        })
    }, [vencimiento, expediente]);
    const [nro, setNro] = useState({ nro: '' })
    const [cont, setCont] = useCookies(['contadorExp']);
    const [cont1, setCont1] = useCookies(['contadorExpMediacion']);
    const [exp] = useCookies(['exp']);
    const [expMediacion] = useCookies(['expMediacion']);
    const index = exp.exp.indexOf(expediente.nro)
    const atras = index - 1
    const adelante = index + 1
    const hora = localStorage.getItem('hora');
    const [time, setTime] = useState(new Date());
    useEffect(() => {
        const timerId = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => clearInterval(timerId);
    }, []);
    const formatTime = (date) => {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };
    const getTimeDifferenceInSeconds = (timeString1, timeString2) => {
        const [hours1, minutes1, seconds1] = timeString1.split(':').map(Number);
        const [hours2, minutes2, seconds2] = timeString2.split(':').map(Number);
        const date1 = new Date();
        date1.setHours(hours1, minutes1, seconds1, 0);
        const date2 = new Date();
        date2.setHours(hours2, minutes2, seconds2, 0);
        const differenceInMilliseconds = Math.abs(date1 - date2);
        return Math.floor(differenceInMilliseconds / 1000);
    };
    const prev = async () => {
        await axios.post('https://api.tuveunchoque.com.ar/api/registroDeUso/update', { user: cookies.user.nombre, segundos: getTimeDifferenceInSeconds(formatTime(time), hora) })
        localStorage.setItem('hora', formatTime(time))
        setCont('contadorExp', (Number(cont.contadorExp)))
        navigate(`/expediente/${exp.exp[atras]}`)
        window.location.reload()
    }
    const next = async () => {
        await axios.post('https://api.tuveunchoque.com.ar/api/registroDeUso/update', { user: cookies.user.nombre, segundos: getTimeDifferenceInSeconds(formatTime(time), hora) })
        localStorage.setItem('hora', formatTime(time))
        setCont('contadorExp', (Number(cont.contadorExp)))
        navigate(`/expediente/${exp.exp[adelante]}`)
        window.location.reload()
    }
    const index1 = expMediacion.expMediacion.indexOf(expediente.nro)
    const atras1 = index1 - 1
    const adelante1 = index1 + 1
    const prev1 = async () => {
        await axios.post('https://api.tuveunchoque.com.ar/api/registroDeUso/update', { user: cookies.user.nombre, segundos: getTimeDifferenceInSeconds(formatTime(time), hora) })
        localStorage.setItem('hora', formatTime(time))
        setCont1('contadorExpMediacion', (Number(cont1.contadorExpMediacion)))
        navigate(`/expediente/${expMediacion.expMediacion[atras1]}`)
        window.location.reload()
    }
    const next1 = async () => {
        await axios.post('https://api.tuveunchoque.com.ar/api/registroDeUso/update', { user: cookies.user.nombre, segundos: getTimeDifferenceInSeconds(formatTime(time), hora) })
        localStorage.setItem('hora', formatTime(time))
        setCont1('contadorExpMediacion', (Number(cont1.contadorExpMediacion)))
        navigate(`/expediente/${expMediacion.expMediacion[adelante1]}`)
        window.location.reload()
    }
    const trash = { fontSize: "22px" };
    const pasCargo = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(`https://api.tuveunchoque.com.ar/api/mail/ampliacionCargaPas`, {
            id: expediente.id,
            carga: 0
        }).then((response) => {
            if (response) {
                setTimeout(async () => {
                    window.location.reload()
                }, 200);
            }
        })
    }, [expediente])
    if (expediente?.contadorRevisionLegales > 1) { alert(`El expediente ha vuelto a Revisiones Legales ${expediente.contadorRevisionLegales} veces`); }
    const div1 = { border: 'none', background: 'none' }
    const formatFecha = (fechaStr) => {
        const mesesAbreviados = [
            "Ene", "Feb", "Mar", "Abr", "May", "Jun",
            "Jul", "Ago", "Sep", "Oct", "Nov", "Dic",
        ];

        let dia, mes, anio;

        // Verificar formato `DD/MM/YYYY`
        if (fechaStr.includes("/")) {
            [dia, mes, anio] = fechaStr.split("/");
        }
        // Verificar formato `YYYY-MM-DD`
        else if (fechaStr.includes("-")) {
            [anio, mes, dia] = fechaStr.split("-");
        } else {
            // Si no cumple ninguno, devolver un valor vacío o un mensaje
            return "Formato de fecha no válido";
        }

        const mesAbreviado = mesesAbreviados[parseInt(mes, 10) - 1];
        if (!mesAbreviado) {
            return "Mes no válido";
        }

        return `${dia} ${mesAbreviado} ${anio}`;
    };
    const normalizeFecha = (fechaStr) => {
        if (fechaStr.includes("-")) {
            const [anio, mes, dia] = fechaStr.split("-");
            return `${dia}/${mes}/${anio}`;
        }
        if (fechaStr.includes("/")) {
            const [dia, mes, anio] = fechaStr.split("/");
            if (dia.length === 2 && mes.length === 2 && anio.length === 4) {
                return fechaStr;
            }
        }
        return "Formato de fecha no válido";
    };
    const toggleSwitch = () => {
        setVistaMed(!vistaMed);
    };

    const isMobile375 = useMediaQuery({ query: '(max-width: 375px)' });
    const isMobile412 = useMediaQuery({ query: '(min-width: 376px) and (max-width: 412px)' });
    const isMobile480 = useMediaQuery({ query: '(min-width: 413px) and (max-width: 480px)' });
    const isTablet768 = useMediaQuery({ query: '(min-width: 480px) and (max-width: 768px)' });

    const logo375 = { height: '22px', width: '22px', background: 'transparent 0% 0% no-repeat padding-box', opacity: 1 }
    const logoPc = { height: '22px', width: '22px', background: 'transparent 0% 0% no-repeat padding-box', opacity: 1 }

    const [chatOn, setChatOn] = useState(0)
    
    return (
        <>
            {vistaMed === true && (cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') ?
                <main className="mainExpedientePrueba">
                    {cookies.user ?
                        <>
                            <section className="gridConDosColumnas">
                                <MiniTablaMediacion expediente={expediente} />
                                {expediente.anclado ? <ComentarioAnclado expediente={expediente} /> : <div className="col-6"></div>}
                            </section>
                            <section className="gridConDosColumnas mt-3">
                                <div className="containerTituloExp">
                                    <div className="d-flex align-items-center">
                                        <div class="elementosTituloExp2">
                                            <p className="m-0 subtituloTituloExp">Fecha Expediente</p>
                                            <div className="d-flex">
                                                <i class="bi bi-calendar3"></i>
                                                <p className="ps-1 textoSubtituloExp">{formatFecha(expediente.fechaExp)}</p>
                                            </div>
                                        </div>
                                        <div class="elementosTituloExp2">
                                            <p className="m-0 subtituloTituloExp">Patente</p>
                                            <p className="textoSubtituloExp">{expediente.dominioCliente}</p>
                                        </div>
                                        <div class="elementosTituloExp2">
                                            <p className="m-0 subtituloTituloExp">Fecha Siniestro</p>
                                            <p className="textoSubtituloExp">{expediente.fechaStro}</p>
                                        </div>
                                        <div className="d-flex">
                                            {cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE' ? <div className="espacioHeaderExpediente">
                                                <Link to={`https://estudiopyp.freshdesk.com/a/tickets/${expediente.ticket}`} target="_blank" rel="noopener noreferrer">
                                                    <img src={fresh} alt="" style={logoPc} />
                                                </Link>
                                            </div> : null}
                                            {cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE' ? <div className="espacioHeaderExpediente">
                                                <Link to={`https://dash.callbell.eu/chat/${expediente.callbell}?filter=all`} target="_blank" rel="noopener noreferrer">
                                                    <img src={callbell} alt="" style={logoPc} />
                                                </Link>
                                            </div> : null}
                                            {cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE' ? <div className="espacioHeaderExpediente">
                                                <Link to={`https://dash.callbell.eu/chat/${expediente.callbellRequerido}?filter=all`} target="_blank" rel="noopener noreferrer">
                                                    <img src={callbellReq} alt="" style={logoPc} />
                                                </Link>
                                            </div> : null}
                                            <div className="espacioHeaderExpediente">
                                                <Link to={`${expediente.sharepoint}`} target="_blank" rel="noopener noreferrer">
                                                    <img src={sharepoint} alt="" style={logoPc} />
                                                </Link>
                                            </div>
                                            {cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE' ? <div className="espacioHeaderExpediente">
                                                <button onClick={pasCargo} style={div1}><img src={sharepointRojo} alt="" style={logoPc} /></button>
                                            </div> : null}
                                            {cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE' ? <div className="custom-switch d-flex align-items-center espacioHeaderExpediente">
                                                <input
                                                    type="checkbox"
                                                    id="switch"
                                                    checked={vistaMed}
                                                    onChange={toggleSwitch}
                                                />
                                                <label htmlFor="switch" className="switch-label">
                                                    <span className="switch-background"></span>
                                                    <span className="switch-circle">
                                                        {vistaMed ? "Med" : "Exp"}
                                                    </span>
                                                </label>
                                            </div> : null}
                                        </div>
                                        {cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE' ? <div class="elementosTituloExp">
                                            <p className="m-0 subtituloTituloExp">Vencimiento</p>
                                            <p className="textoSubtituloExp">{expediente.fechaVencimiento?.split("-").reverse().join("-")}</p>
                                        </div> : null}
                                        {cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE' ? <div className="espacioHeaderExpediente d-flex">
                                            <DatePicker locale='es' value={vencimiento} selected={vencimiento} className="form-control form-control-sm"
                                                onChange={(e) => {
                                                    setVencimiento(e)
                                                }
                                                }
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        expedienteDataUpdate(e)
                                                    }
                                                }
                                                }
                                            />
                                        </div> : null}
                                    </div>
                                </div>
                                <LineaProgreso expediente={expediente} />
                            </section>
                            <section className="gridConDosColumnas mt-3">
                                <LineaEstadoAsignadoIzq expediente={expediente} />
                            </section>
                            <section className="gridConDosColumnas mt-2">
                                <PlanillaExpedienteMediacion expediente={expediente} />
                                {<Detalles expediente={expediente} mensajes={mensajes} mediacion={expediente.estado === 'MEDIACION' ? 'MEDIACION' : ''} />}
                            </section>
                        </> : <h4 className="p-5">Se necesita iniciar sesion</h4>}
                </main>
                :
                (isMobile375 ?
                    <main className="mainExp375px">
                        {chatOn === 1 ?
                            <>
                                <div className="d-flex justify-content-between" style={{ marginTop: '2vh', height: '5vh' }}>
                                    <div className='miniExpContainerMediacion375'>{`EXP NRO ${expediente.nro}`}</div>
                                    <button className='botonVerChat75 center' onClick={(e) => setChatOn(0)}><i class="bi bi-pencil-fill"></i>_</button>
                                </div>
                                <DisplayMobileChat expediente={expediente} />
                            </>
                            :
                            <>
                                <div className="d-flex justify-content-between" style={{ marginTop: '3vh', height: '5vh' }}>
                                    <div className='miniExpContainerMediacion375'>{`EXP NRO ${expediente.nro}`}</div>
                                    <button className='botonVerChat75 center' onClick={(e) => setChatOn(1)}><i class="bi bi-pencil-fill"></i>_</button>
                                </div>
                                <MiniTablaMediacion expediente={expediente} />
                                <div className="d-flex align-items-center" style={{ marginTop: '3vh', height: '5vh' }}>
                                    <div class="elementosTituloExp2">
                                        <p className="m-0 subtituloTituloExp375">Vencimiento</p>
                                        <p className="textoSubtituloExp375">{expediente.fechaVencimiento?.split("-").reverse().join("-")}</p>
                                    </div>
                                    <div className="espacioHeaderExpediente375" style={{ width: '100px' }}>
                                        <DatePicker locale='es' value={vencimiento} selected={vencimiento} className="form-control form-control-sm"
                                            onChange={(e) => {
                                                setVencimiento(e)
                                            }
                                            }
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    expedienteDataUpdate(e)
                                                }
                                            }
                                            }
                                        />
                                    </div>
                                    <div className="d-flex">
                                        <div className="espacioHeaderExpediente375">
                                            <Link to={`${expediente.sharepoint}`} target="_blank" rel="noopener noreferrer">
                                                <img src={sharepoint} alt="" style={logo375} />
                                            </Link>
                                        </div>
                                        {cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE' ? <div className="custom-switch375 d-flex align-items-center espacioHeaderExpediente375">
                                            <input
                                                type="checkbox"
                                                id="switch375"
                                                checked={vistaMed}
                                                onChange={toggleSwitch}
                                            />
                                            <label htmlFor="switch" className="switch-label375">
                                                <span className="switch-background375"></span>
                                                <span className="switch-circle375">
                                                    {vistaMed ? "Med" : "Exp"}
                                                </span>
                                            </label>
                                        </div> : null}
                                    </div>
                                </div>
                                <div className="d-flex align-items-center" style={{ marginTop: '3vh', height: '5vh' }}>
                                    <div class="elementosTituloExp2">
                                        <p className="m-0 subtituloTituloExp375">F. Expediente</p>
                                        <p className="textoSubtituloExp375">{formatFecha(expediente.fechaExp)}</p>
                                    </div>
                                    <div class="elementosTituloExp2">
                                        <p className="m-0 subtituloTituloExp375">Patente</p>
                                        <p className="textoSubtituloExp375">{expediente.dominioCliente}</p>
                                    </div>
                                    <div class="elementosTituloExp2">
                                        <p className="m-0 subtituloTituloExp375">F. Siniestro</p>
                                        <p className="textoSubtituloExp375">{normalizeFecha(expediente.fechaStro)}</p>
                                    </div>
                                </div>
                                <div className="d-flex" style={{ marginTop: '3vh', height: '2vh' }}>
                                    <h4 className="valueEstadoTitulo375">Estado: </h4>
                                    <h5 className="valueEstadoTexto375">{expediente.estado}</h5>
                                </div>
                                <ComentarioAnclado expediente={expediente} />
                                <InformacionExpediente expediente={expediente} />
                                <div style={{ marginTop: '20vh' }}></div>
                                <Ampliaciones expediente={expediente} />
                                <div style={{ marginTop: '20vh' }}></div>
                                <RegistroSiniestralidad expediente={expediente} otrosExp={otrosExp} otrosExpReq={otrosExpReq} otrosExpReqCliente={otrosExpReqCliente} otrosExpReqReq={otrosExpReqReq}/>
                            </>}
                    </main>
                    : isMobile412 ?
                        <main className="mainExp375px">
                            {chatOn === 1 ?
                                <>
                                    <div className="d-flex justify-content-between" style={{ marginTop: '2vh', height: '5vh' }}>
                                        <div className='miniExpContainerMediacion375'>{`EXP NRO ${expediente.nro}`}</div>
                                        <button className='botonVerChat75 center' onClick={(e) => setChatOn(0)}><i class="bi bi-pencil-fill"></i>_</button>
                                    </div>
                                    <DisplayMobileChat expediente={expediente} />
                                </>
                                :
                                <>
                                    <div className="d-flex justify-content-between" style={{ marginTop: '3vh', height: '5vh' }}>
                                        <div className='miniExpContainerMediacion375'>{`EXP NRO ${expediente.nro}`}</div>
                                        <button className='botonVerChat75 center' onClick={(e) => setChatOn(1)}><i class="bi bi-pencil-fill"></i>_</button>
                                    </div>
                                    <MiniTablaMediacion expediente={expediente} />
                                    <div className="d-flex align-items-center" style={{ marginTop: '3vh', height: '5vh' }}>
                                        <div class="elementosTituloExp2">
                                            <p className="m-0 subtituloTituloExp375">Vencimiento</p>
                                            <p className="textoSubtituloExp375">{expediente.fechaVencimiento?.split("-").reverse().join("-")}</p>
                                        </div>
                                        <div className="espacioHeaderExpediente375" style={{ width: '100px' }}>
                                            <DatePicker locale='es' value={vencimiento} selected={vencimiento} className="form-control form-control-sm"
                                                onChange={(e) => {
                                                    setVencimiento(e)
                                                }
                                                }
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        expedienteDataUpdate(e)
                                                    }
                                                }
                                                }
                                            />
                                        </div>
                                        <div className="d-flex">
                                            <div className="espacioHeaderExpediente375">
                                                <Link to={`${expediente.sharepoint}`} target="_blank" rel="noopener noreferrer">
                                                    <img src={sharepoint} alt="" style={logo375} />
                                                </Link>
                                            </div>
                                            {cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE' ? <div className="custom-switch375 d-flex align-items-center espacioHeaderExpediente375">
                                                <input
                                                    type="checkbox"
                                                    id="switch375"
                                                    checked={vistaMed}
                                                    onChange={toggleSwitch}
                                                />
                                                <label htmlFor="switch" className="switch-label375">
                                                    <span className="switch-background375"></span>
                                                    <span className="switch-circle375">
                                                        {vistaMed ? "Med" : "Exp"}
                                                    </span>
                                                </label>
                                            </div> : null}
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center" style={{ marginTop: '3vh', height: '5vh' }}>
                                        <div class="elementosTituloExp2">
                                            <p className="m-0 subtituloTituloExp375">F. Expediente</p>
                                            <p className="textoSubtituloExp375">{formatFecha(expediente.fechaExp)}</p>
                                        </div>
                                        <div class="elementosTituloExp2">
                                            <p className="m-0 subtituloTituloExp375">Patente</p>
                                            <p className="textoSubtituloExp375">{expediente.dominioCliente}</p>
                                        </div>
                                        <div class="elementosTituloExp2">
                                            <p className="m-0 subtituloTituloExp375">F. Siniestro</p>
                                            <p className="textoSubtituloExp375">{normalizeFecha(expediente.fechaStro)}</p>
                                        </div>
                                    </div>
                                    <div className="d-flex" style={{ marginTop: '3vh', height: '2vh' }}>
                                        <h4 className="valueEstadoTitulo375">Estado: </h4>
                                        <h5 className="valueEstadoTexto375">{expediente.estado}</h5>
                                    </div>
                                    <ComentarioAnclado expediente={expediente} />
                                    <InformacionExpediente expediente={expediente} otrosExp={otrosExp} otrosExpReq={otrosExpReq} />
                                    <div style={{ marginTop: '20vh' }}></div>
                                    <Ampliaciones expediente={expediente} />
                                    <div style={{ marginTop: '20vh' }}></div>
                                    <RegistroSiniestralidad expediente={expediente} otrosExp={otrosExp} otrosExpReq={otrosExpReq} otrosExpReqCliente={otrosExpReqCliente} otrosExpReqReq={otrosExpReqReq}/>
                                </>}
                        </main>
                        : isMobile480 ?
                            <main className="mainExp375px">
                                {chatOn === 1 ?
                                    <>
                                        <div className="d-flex justify-content-between" style={{ marginTop: '2vh', height: '5vh' }}>
                                            <div className='miniExpContainerMediacion375'>{`EXP NRO ${expediente.nro}`}</div>
                                            <button className='botonVerChat75 center' onClick={(e) => setChatOn(0)}><i class="bi bi-pencil-fill"></i>_</button>
                                        </div>
                                        <DisplayMobileChat expediente={expediente} />
                                    </>
                                    :
                                    <>
                                        <div className="d-flex justify-content-between" style={{ marginTop: '3vh', height: '5vh' }}>
                                            <div className='miniExpContainerMediacion375'>{`EXP NRO ${expediente.nro}`}</div>
                                            <button className='botonVerChat75 center' onClick={(e) => setChatOn(1)}><i class="bi bi-pencil-fill"></i>_</button>
                                        </div>
                                        <MiniTablaMediacion expediente={expediente} />
                                        <div className="d-flex align-items-center" style={{ marginTop: '3vh', height: '5vh' }}>
                                            <div class="elementosTituloExp2">
                                                <p className="m-0 subtituloTituloExp375">Vencimiento</p>
                                                <p className="textoSubtituloExp375">{expediente.fechaVencimiento?.split("-").reverse().join("-")}</p>
                                            </div>
                                            <div className="espacioHeaderExpediente375" style={{ width: '100px' }}>
                                                <DatePicker locale='es' value={vencimiento} selected={vencimiento} className="form-control form-control-sm"
                                                    onChange={(e) => {
                                                        setVencimiento(e)
                                                    }
                                                    }
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            expedienteDataUpdate(e)
                                                        }
                                                    }
                                                    }
                                                />
                                            </div>
                                            <div className="d-flex">
                                                <div className="espacioHeaderExpediente375">
                                                    <Link to={`${expediente.sharepoint}`} target="_blank" rel="noopener noreferrer">
                                                        <img src={sharepoint} alt="" style={logo375} />
                                                    </Link>
                                                </div>
                                                {cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE' ? <div className="custom-switch375 d-flex align-items-center espacioHeaderExpediente375">
                                                    <input
                                                        type="checkbox"
                                                        id="switch375"
                                                        checked={vistaMed}
                                                        onChange={toggleSwitch}
                                                    />
                                                    <label htmlFor="switch" className="switch-label375">
                                                        <span className="switch-background375"></span>
                                                        <span className="switch-circle375">
                                                            {vistaMed ? "Med" : "Exp"}
                                                        </span>
                                                    </label>
                                                </div> : null}
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center" style={{ marginTop: '3vh', height: '5vh' }}>
                                            <div class="elementosTituloExp2">
                                                <p className="m-0 subtituloTituloExp375">F. Expediente</p>
                                                <p className="textoSubtituloExp375">{formatFecha(expediente.fechaExp)}</p>
                                            </div>
                                            <div class="elementosTituloExp2">
                                                <p className="m-0 subtituloTituloExp375">Patente</p>
                                                <p className="textoSubtituloExp375">{expediente.dominioCliente}</p>
                                            </div>
                                            <div class="elementosTituloExp2">
                                                <p className="m-0 subtituloTituloExp375">F. Siniestro</p>
                                                <p className="textoSubtituloExp375">{normalizeFecha(expediente.fechaStro)}</p>
                                            </div>
                                        </div>
                                        <div className="d-flex" style={{ marginTop: '3vh', height: '2vh' }}>
                                            <h4 className="valueEstadoTitulo375">Estado: </h4>
                                            <h5 className="valueEstadoTexto375">{expediente.estado}</h5>
                                        </div>
                                        <ComentarioAnclado expediente={expediente} />
                                        <InformacionExpediente expediente={expediente} />
                                        <div style={{ marginTop: '20vh' }}></div>
                                        <Ampliaciones expediente={expediente} />
                                        <div style={{ marginTop: '20vh' }}></div>
                                        <RegistroSiniestralidad expediente={expediente} otrosExp={otrosExp} otrosExpReq={otrosExpReq} otrosExpReqCliente={otrosExpReqCliente} otrosExpReqReq={otrosExpReqReq}/>
                                    </>}
                            </main>
                            :
                            isTablet768 ?
                                <main className="mainExp375px">
                                    {chatOn === 1 ?
                                        <>
                                            <div className="d-flex justify-content-between" style={{ marginTop: '2vh', height: '5vh' }}>
                                                <div className='miniExpContainerMediacion375'>{`EXP NRO ${expediente.nro}`}</div>
                                                <button className='botonVerChat75 center' onClick={(e) => setChatOn(0)}><i class="bi bi-pencil-fill"></i>_</button>
                                            </div>
                                            <DisplayMobileChat expediente={expediente} />
                                        </>
                                        :
                                        <>
                                            <div className="d-flex justify-content-between" style={{ marginTop: '3vh', height: '5vh' }}>
                                                <div className='miniExpContainerMediacion768'>{`EXP NRO ${expediente.nro}`}</div>
                                                <button className='botonVerChat768 center' onClick={(e) => setChatOn(1)}><i class="bi bi-pencil-fill"></i>_</button>
                                            </div>
                                            <MiniTablaMediacion expediente={expediente} />
                                            <div className="containerTituloExp" style={{ marginTop: '3vh', height: '5vh' }}>
                                                <div className="d-flex align-items-center">
                                                    <div class="elementosTituloExp2">
                                                        <p className="m-0 subtituloTituloExp768">Fecha Expediente</p>
                                                        <div className="d-flex">
                                                            <i class="bi bi-calendar3"></i>
                                                            <p className="ps-1 textoSubtituloExp768">{formatFecha(expediente.fechaExp)}</p>
                                                        </div>
                                                    </div>
                                                    <div class="elementosTituloExp2">
                                                        <p className="m-0 subtituloTituloExp768">Patente</p>
                                                        <p className="textoSubtituloExp768">{expediente.dominioCliente}</p>
                                                    </div>
                                                    <div class="elementosTituloExp2">
                                                        <p className="m-0 subtituloTituloExp768">Fecha Siniestro</p>
                                                        <p className="textoSubtituloExp768">{expediente.fechaStro}</p>
                                                    </div>
                                                    <div className="d-flex">
                                                        {cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE' ? <div className="espacioHeaderExpediente">
                                                            <Link to={`https://estudiopyp.freshdesk.com/a/tickets/${expediente.ticket}`} target="_blank" rel="noopener noreferrer">
                                                                <img src={fresh} alt="" style={logoPc} />
                                                            </Link>
                                                        </div> : null}
                                                        {cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE' ? <div className="espacioHeaderExpediente">
                                                            <Link to={`https://dash.callbell.eu/chat/${expediente.callbell}?filter=all`} target="_blank" rel="noopener noreferrer">
                                                                <img src={callbell} alt="" style={logoPc} />
                                                            </Link>
                                                        </div> : null}
                                                        {cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE' ? <div className="espacioHeaderExpediente">
                                                            <Link to={`https://dash.callbell.eu/chat/${expediente.callbellRequerido}?filter=all`} target="_blank" rel="noopener noreferrer">
                                                                <img src={callbellReq} alt="" style={logoPc} />
                                                            </Link>
                                                        </div> : null}
                                                        <div className="espacioHeaderExpediente">
                                                            <Link to={`${expediente.sharepoint}`} target="_blank" rel="noopener noreferrer">
                                                                <img src={sharepoint} alt="" style={logoPc} />
                                                            </Link>
                                                        </div>
                                                        {cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE' ? <div className="espacioHeaderExpediente">
                                                            <button onClick={pasCargo} style={div1}><img src={sharepointRojo} alt="" style={logoPc} /></button>
                                                        </div> : null}
                                                        {cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE' ? <div className="custom-switch375 d-flex align-items-center espacioHeaderExpediente375">
                                                            <input
                                                                type="checkbox"
                                                                id="switch375"
                                                                checked={vistaMed}
                                                                onChange={toggleSwitch}
                                                            />
                                                            <label htmlFor="switch" className="switch-label375">
                                                                <span className="switch-background375"></span>
                                                                <span className="switch-circle375">
                                                                    {vistaMed ? "Med" : "Exp"}
                                                                </span>
                                                            </label>
                                                        </div> : null}
                                                    </div>
                                                    {cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE' ? <div class="elementosTituloExp">
                                                        <p className="m-0 subtituloTituloExp768">Vencimiento</p>
                                                        <p className="textoSubtituloExp768">{expediente.fechaVencimiento?.split("-").reverse().join("-")}</p>
                                                    </div> : null}
                                                    {cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE' ? <div className="espacioHeaderExpediente d-flex">
                                                        <DatePicker locale='es' value={vencimiento} selected={vencimiento} className="form-control form-control-sm"
                                                            onChange={(e) => {
                                                                setVencimiento(e)
                                                            }
                                                            }
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    expedienteDataUpdate(e)
                                                                }
                                                            }
                                                            }
                                                        />
                                                    </div> : null}
                                                </div>
                                            </div>
                                            <div className="d-flex" style={{ marginTop: '3vh', height: '2vh' }}>
                                                <h4 className="valueEstadoTitulo768">Estado: </h4>
                                                <h5 className="valueEstadoTexto768">{expediente.estado}</h5>
                                            </div>
                                            <ComentarioAnclado expediente={expediente} />
                                            <InformacionExpediente expediente={expediente} />
                                            <div style={{ marginTop: '20vh' }}></div>
                                            <Ampliaciones expediente={expediente} />
                                            <div style={{ marginTop: '20vh' }}></div>
                                            <RegistroSiniestralidad expediente={expediente} otrosExp={otrosExp} otrosExpReq={otrosExpReq} otrosExpReqCliente={otrosExpReqCliente} otrosExpReqReq={otrosExpReqReq}/>
                                        </>}
                                </main>
                                :
                                <main className="mainExpedientePrueba">
                                    <section className="gridConDosColumnas">
                                        <MiniTablaMediacion expediente={expediente} />
                                        {expediente.anclado ? <ComentarioAnclado expediente={expediente} /> : <div className=""></div>}
                                    </section>
                                    <section className="gridConDosColumnas">
                                        <div className="containerTituloExp">
                                            <div className="d-flex align-items-center">
                                                <div class="elementosTituloExp2">
                                                    <p className="m-0 subtituloTituloExp">Fecha Expediente</p>
                                                    <div className="d-flex">
                                                        <i class="bi bi-calendar3"></i>
                                                        <p className="ps-1 textoSubtituloExp">{formatFecha(expediente.fechaExp)}</p>
                                                    </div>
                                                </div>
                                                <div class="elementosTituloExp2">
                                                    <p className="m-0 subtituloTituloExp">Patente</p>
                                                    <p className="textoSubtituloExp">{expediente.dominioCliente}</p>
                                                </div>
                                                <div class="elementosTituloExp2">
                                                    <p className="m-0 subtituloTituloExp">Fecha Siniestro</p>
                                                    <p className="textoSubtituloExp">{expediente.fechaStro}</p>
                                                </div>
                                                {(
                                                    cookies.user.cargo === 'PAS' ||
                                                    cookies.user.cargo === 'GRUPO' ||
                                                    cookies.user.cargo === 'ORGANIZACION'
                                                ) && expediente.mesEstimado !== null && (
                                                        <div className="elementosTituloExp2">
                                                            <p className="m-0 subtituloTituloExp">Mes Estimado de Pago</p>
                                                            <p className="textoSubtituloExp">{expediente.mesEstimado}</p>
                                                        </div>
                                                    )}
                                                <div className="d-flex">
                                                    {(cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') && expediente.ticket  ? <div className="espacioHeaderExpediente">
                                                        <Link to={`https://estudiopyp.freshdesk.com/a/tickets/${expediente.ticket}`} target="_blank" rel="noopener noreferrer">
                                                            <img src={fresh} alt="" style={logoPc} />
                                                        </Link>
                                                    </div> : null}
                                                    {(cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') && expediente.callbell ? <div className="espacioHeaderExpediente">
                                                        <Link to={`https://dash.callbell.eu/chat/${expediente.callbell}?filter=all`} target="_blank" rel="noopener noreferrer">
                                                            <img src={callbell} alt="" style={logoPc} />
                                                        </Link>
                                                    </div> : null}
                                                    {(cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') && expediente.callbellRequerido ? <div className="espacioHeaderExpediente">
                                                        <Link to={`https://dash.callbell.eu/chat/${expediente.callbellRequerido}?filter=all`} target="_blank" rel="noopener noreferrer">
                                                            <img src={callbellReq} alt="" style={logoPc} />
                                                        </Link>
                                                    </div> : null}
                                                    {expediente.sharepoint ? <div className="espacioHeaderExpediente">
                                                        <Link to={`${expediente.sharepoint}`} target="_blank" rel="noopener noreferrer">
                                                            <img src={sharepoint} alt="" style={logoPc} />
                                                        </Link>
                                                    </div> : null}
                                                    {cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE' ? <div className="espacioHeaderExpediente">
                                                        <button onClick={pasCargo} style={div1}><img src={sharepointRojo} alt="" style={logoPc} /></button>
                                                    </div> : null}
                                                    {cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE' ? <div className="custom-switch d-flex align-items-center espacioHeaderExpediente">
                                                        <input
                                                            type="checkbox"
                                                            id="switch"
                                                            checked={vistaMed}
                                                            onChange={toggleSwitch}
                                                        />
                                                        <label htmlFor="switch" className="switch-label">
                                                            <span className="switch-background"></span>
                                                            <span className="switch-circle">
                                                                {vistaMed ? "Med" : "Exp"}
                                                            </span>
                                                        </label>
                                                    </div> : null}
                                                </div>
                                                {cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE' ? <div class="elementosTituloExp">
                                                    <p className="m-0 subtituloTituloExp">Vencimiento</p>
                                                    <p className="textoSubtituloExp">{expediente.fechaVencimiento?.split("-").reverse().join("-")}</p>
                                                </div> : null}
                                                {cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE' ? <div className="espacioHeaderExpediente d-flex">
                                                    <DatePicker locale='es' value={vencimiento} selected={vencimiento} className="form-control form-control-sm"
                                                        onChange={(e) => {
                                                            setVencimiento(e)
                                                        }
                                                        }
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                expedienteDataUpdate(e)
                                                            }
                                                        }
                                                        }
                                                    />
                                                </div> : null}
                                            </div>
                                        </div>
                                        <LineaProgreso expediente={expediente} />
                                    </section>
                                    <section className="gridConDosColumnasError mt-3">
                                        <InformacionExpediente expediente={expediente} otrosExp={otrosExp} otrosExpReq={otrosExpReq} />
                                        <Detalles expediente={expediente} mensajes={mensajes} mediacion={expediente.estado === 'MEDIACION' ? 'MEDIACION' : ''} />
                                    </section>
                                    {cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE' ?
                                        <div className="gridConDosColumnas mb-5" style={{ marginTop: '100px' }}>
                                            <Ampliaciones expediente={expediente} />
                                            <RegistroSiniestralidad expediente={expediente} otrosExp={otrosExp} otrosExpReq={otrosExpReq} otrosExpReqCliente={otrosExpReqCliente} otrosExpReqReq={otrosExpReqReq}/>
                                        </div>
                                        : <AmpliacionesPas expediente={expediente} />}
                                    <ListadoDocumentacion expediente={expediente} />
                                </main>
                )
            }
        </>
    )
}

